import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { OrgCredentialType } from '@/types/credentials'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateDrChronoAuthTokens,
  useCreateOrUpdateEntrataAuthTokens,
  useUpdateDrChronoAuthTokens,
} from '@/service-library/hooks/org-credentials'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'

type FormValues = {
  client_id: string
  client_secret: string
  bearer_token: string
}

type ManageCredentialAuthDialogProps = {
  isEdit: boolean
  overlay: OverlayState
  orgCredentialId: string
  type: OrgCredentialType
}

const defaultValues = {
  client_id: '',
  client_secret: '',
  token: '',
}

export default function ManageCredentialAuthDialog({
  isEdit,
  overlay,
  orgCredentialId,
  type,
}: ManageCredentialAuthDialogProps) {
  const methods = useForm<FormValues>({
    defaultValues,
  })

  const { formState, reset } = methods

  const { createDrChronoAuthTokens } = useCreateDrChronoAuthTokens({
    onSuccess: (data) => {
      showSuccessSnackbar('Tokens Added')
      window.open(data.auth_url, '_blank')
    },
    onError: () => {
      showErrorSnackbar('Failed to add tokens. Please contact support.')
    },
  })

  const { updateDrChronoAuthTokens } = useUpdateDrChronoAuthTokens({
    onSuccess: (data) => {
      showSuccessSnackbar('Updated Tokens')
      window.open(data.auth_url, '_blank')
    },
    onError: () => {
      showErrorSnackbar('Failed to update credential. Please contact support.')
    },
  })

  const { createOrUpdateEntrataAuthTokens } =
    useCreateOrUpdateEntrataAuthTokens({
      onSuccess: () => {
        showSuccessSnackbar(isEdit ? 'Updated Token' : 'Token Added')
      },
      onError: () => {
        showErrorSnackbar(
          `Failed to ${
            isEdit ? 'update' : 'add'
          } token. Please contact support`,
        )
      },
    })

  function handleCreateCredential(values: FormValues) {
    if (type.code === 'dr_chrono') {
      if (isEdit) {
        updateDrChronoAuthTokens({
          id: orgCredentialId,
          client_id: values.client_id,
          client_secret: values.client_secret,
        })
      } else {
        createDrChronoAuthTokens({
          id: orgCredentialId,
          client_id: values.client_id,
          client_secret: values.client_secret,
        })
      }
    } else if (type.code === 'entrata_api_token') {
      createOrUpdateEntrataAuthTokens({
        org_credential_id: orgCredentialId,
        bearer_token: values.bearer_token,
      })
    }

    overlay.close()
  }

  const { isSubmitSuccessful, isSubmitting, isValid } = formState

  useEffect(() => {
    if (isSubmitSuccessful || (!isSubmitting && !overlay.isOpen)) {
      reset()
    }
  }, [isSubmitSuccessful, isSubmitting, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${isEdit ? 'Edit' : 'Set'} ${
        type?.code === 'dr_chrono' ? 'Client ID and Secret' : 'API Token'
      }`}
      {...overlay}
      maxWidth="xs"
    >
      <PixyDocsForm methods={methods} onSubmit={handleCreateCredential}>
        <DialogContent>
          <Stack spacing={2}>
            {type.code === 'dr_chrono' && (
              <>
                <FormTextField name="client_id" label="Client ID" required />
                <FormTextField
                  name="client_secret"
                  label="Client Secret"
                  required
                />
              </>
            )}
            {type.code === 'entrata_api_token' && (
              <FormTextField name="bearer_token" label="Token" required />
            )}
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button disabled={!isValid} type="submit">
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
