import { useCallback, useState, useMemo } from 'react'
import { useDocumentRowContext } from './providers/DocumentRowProvider'
import { DocumentRowValue } from '@/types/documents'
import generateUuid from '@/utils/generate-uuid'
import debounce from 'lodash.debounce'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useFieldErrorContext } from './providers/FieldErrorProvider'
import {
  useCreateDocumentRowValueWithCleanup,
  useUpdateDocumentRowValueWithCleanup,
} from '@/service-library/hooks/document-row-values'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'

export type DataLineageValues = {
  original_value: string | null
  rule_value: string | null
  manual_value: string | null
  final_value: string | null
}

type UseFieldValueProps = {
  fieldId: string
  documentRowId?: string
}

type UseFieldValueReturn = {
  value: string
  dataLineageValues: DataLineageValues
  hasManualValue: boolean
  refId: string | null
  onManualChange: (inputField: string | null, refId?: string | null) => void
  onCategoryChange: (newCategoryId: string | null) => void
  clearValue: () => void
  documentRowValue?: DocumentRowValue
  wasAutoFilled: boolean
}

export default function useFieldValue({
  fieldId,
  documentRowId,
}: UseFieldValueProps): UseFieldValueReturn {
  const documentRow = useDocumentRowContext()
  // documentRow from context is second because there might be one for the base grid,
  // so we don't want to override the value passed for fields in table
  const rowId = (documentRowId as string) || documentRow?.id
  const { setFieldError } = useFieldErrorContext()
  const { queryKey, getFieldDocumentRowValue } = useDocumentRowValuesContext()

  const documentRowValue = getFieldDocumentRowValue(fieldId, rowId)
  const {
    manual_value = null,
    rule_value = null,
    original_value = null,
    final_value = null,
    rule_ref_id,
    original_ref_id,
    final_ref_id,
    project_content_category_item_id,
  } = documentRowValue ? documentRowValue : ({} as DocumentRowValue)

  const [internalValue, setInternalValue] = useState<string | null>(
    manual_value ?? null,
  )
  const [internalRefId, setInternalRefId] = useState<string | null>(
    final_ref_id ?? null,
  )

  const { selectedWorkflow } = useSelectedWorkflowContext()

  // Keeping create for backwards compatibility since a document row value should always exist now
  const { createDocumentRowValue } = useCreateDocumentRowValueWithCleanup({
    workflowId: selectedWorkflow.id,
    listQueryKey: queryKey,
    onError: () => {
      setInternalValue(manual_value ?? final_value)
      setFieldError(fieldId, rowId, 'Unable to update field.')
    },
  })
  const { updateDocumentRowValue } = useUpdateDocumentRowValueWithCleanup({
    workflowId: selectedWorkflow.id,
    listQueryKey: queryKey,
    onError: () => {
      setInternalValue(manual_value ?? final_value)
      setFieldError(fieldId, rowId, 'Unable to update field.')
    },
  })

  const updateValue = useCallback(
    function (
      newValues: {
        manual_value?: string | null
        project_content_category_item_id?: string | null
      },
      newRefId?: string | null,
    ) {
      const updatedValues = {
        manual_value: newValues.manual_value ?? null,
        project_content_category_item_id:
          newValues.project_content_category_item_id || null,
        manual_ref_id: newRefId || null,
      }

      const valueId = documentRowValue?.id || generateUuid()
      const updatedDocumentRowValue = {
        ...documentRowValue,
        id: valueId,
        project_grid_field_id: fieldId,
        document_row_id: rowId,
        ...updatedValues,
      } as DocumentRowValue
      if (!documentRowValue) {
        return createDocumentRowValue(updatedDocumentRowValue)
      }

      if (
        !original_value &&
        !rule_value &&
        updatedValues.manual_value === null &&
        !updatedValues.project_content_category_item_id
      ) {
        setInternalValue(null)
      }
      return updateDocumentRowValue(updatedDocumentRowValue)
    },
    [
      createDocumentRowValue,
      rowId,
      documentRowValue,
      fieldId,
      original_value,
      rule_value,
      updateDocumentRowValue,
    ],
  )

  const debouncedHandleChange = useMemo(
    () =>
      debounce((newValue, newRefId) => {
        setFieldError(fieldId, rowId, '')

        // We don't want to clear out the manual value if the final value is set. If nothing is set
        // after the user leaves the field, the onBlur function will clear it.
        if (newValue === '' && final_value) return

        updateValue({ manual_value: newValue }, newRefId)
      }, 500),
    [fieldId, final_value, rowId, setFieldError, updateValue],
  )

  const onCategoryChange = useCallback(
    (newCategoryId: string | null) => {
      setInternalValue(null)
      setFieldError(fieldId, rowId, '')
      updateValue({
        project_content_category_item_id: newCategoryId,
        manual_value: null, // retroactively clean out manual values, since they shouldn't be set for category pickers
      })
    },
    [rowId, fieldId, setFieldError, updateValue],
  )

  const onManualChange = useCallback(
    (newValue: string | null, newManualRefId?: string | null) => {
      const isNoneOption = newValue === 'None' && newManualRefId === 'None'
      setInternalValue(isNoneOption ? '' : newValue)
      newManualRefId !== undefined &&
        setInternalRefId(isNoneOption ? null : newManualRefId)

      if (isNoneOption) {
        setFieldError(fieldId, rowId, '')
        updateValue({ manual_value: '' }, null)
      } else if (newValue !== null) {
        debouncedHandleChange(newValue as string, newManualRefId)
      } else {
        // When deleting or clearing the manual value, we want it to happen instantly
        setFieldError(fieldId, rowId, '')
        updateValue({}, newManualRefId)
      }
    },
    [debouncedHandleChange, rowId, fieldId, setFieldError, updateValue],
  )

  const clearValue = useCallback(() => {
    setInternalValue(null)
    setInternalRefId(null)
    setFieldError(fieldId, rowId, '')
    manual_value !== null && updateValue({})
  }, [rowId, fieldId, manual_value, setFieldError, updateValue])

  return {
    value:
      internalValue ??
      final_value ??
      rule_value ??
      original_value ??
      project_content_category_item_id ??
      '',
    dataLineageValues: {
      original_value,
      rule_value,
      manual_value,
      final_value,
    },
    hasManualValue: manual_value !== null,
    refId:
      internalRefId ?? final_ref_id ?? rule_ref_id ?? original_ref_id ?? null,
    onManualChange,
    onCategoryChange,
    clearValue,
    documentRowValue,
    wasAutoFilled: !!rule_value && !original_value && !manual_value,
  }
}
