import { useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Document } from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'
import { useGetDocuments } from '@/service-library/hooks/documents'
import useDocumentSet from '@/services/hooks/useDocumentSet'

type UseValidationNavListProps = {
  projectId: string
  documentId?: string
  documentIds?: string[]
  ordering?: string[]
  orgIds?: string[] | false
  teamIds?: string[]
  workflowStateIds?: string[]
}

export default function useValidationNavList({
  projectId,
  documentId,
  documentIds,
  ordering,
  orgIds,
  teamIds,
  workflowStateIds = [],
}: UseValidationNavListProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const navCheckRef = useRef(false)
  const queryClient = useQueryClient()

  const isSetList = !!documentIds
  const enabled =
    !!projectId && workflowStateIds.length > 0 && (!orgIds || orgIds.length > 0)

  const infiniteListQuery = useGetDocuments({
    paginator: 'page',
    filters: {
      limit: '40',
      fields__only: 'id,name,project_id,image_urls,workflow_states_ids',
      project_id: projectId,
      ordering: ordering?.join(),
      owner_org_id__in: orgIds ? orgIds.join() : undefined,
      team_id__in: teamIds?.join(),
      document_workflow_states__workflow_state_id__in: workflowStateIds.join(),
    },
    enabled: !isSetList && enabled,
  })

  const { data } = useGetDocuments({
    paginator: 'page',
    filters: {
      limit: '1', // We only care about the total documentsCount, so we don't need to fetch more than one
      fields__only: 'id',
      project_id: projectId,
      owner_org_id__in: orgIds ? orgIds.join() : undefined,
      team_id__in: teamIds?.join(),
      document_workflow_states__workflow_state_id__in: workflowStateIds.join(),
    },
    enabled: !isSetList && enabled,
  })

  const setListQuery = useDocumentSet({
    enabled: isSetList && enabled,
    documentIds: documentIds as string[],
    includeWorkflowStates: true,
    fieldsOnly: 'id,name,project_id,image_urls',
    ordering,
    orgIds,
    teamIds,
    workflowStateIds,
    select: (documents) => {
      // TODO: Revisit this when Josh is back to know if backend can return documents sorted
      if (documentIds && !ordering?.length) {
        const sortedDocuments = documentIds.reduce<Document[]>(
          (acc, documentId) => {
            const document = documents.find(({ id }) => documentId === id)
            if (document) acc.push(document)
            return acc
          },
          [],
        )
        return sortedDocuments
      }
      return documents
    },
  })

  const query = useMemo(
    () =>
      isSetList
        ? {
            ...setListQuery,
            // Added these properties to make typescript happy
            fetchPreviousPage: () => {},
            fetchNextPage: () => {},
            hasPreviousPage: false,
            hasNextPage: false,
            isFetchingNextPage: false,
            isFetchingPreviousPage: false,
          }
        : infiniteListQuery,
    [infiniteListQuery, isSetList, setListQuery],
  )

  const {
    documents = [],
    hasNextPage,
    isFetching,
    isLoading,
    fetchNextPage,
  } = query

  const { search } = useLocation()

  const selectedIndex = documents.findIndex(
    (document) => document.id === documentId,
  )
  const selectedDocument =
    selectedIndex > -1 ? documents[selectedIndex] : undefined

  const hasNextDocument = selectedIndex !== documents.length - 1
  const hasPreviousDocument = selectedIndex > 0

  function goToPreviousDocument(): string | undefined {
    if (hasPreviousDocument) {
      const nextDocId = documents[selectedIndex - 1].id
      navigate(`/projects/${projectId}/validation/${nextDocId}${search}`)
      return nextDocId
    }
  }

  function goToNextDocument(): string | undefined {
    if (hasNextDocument) {
      const nextDocId = documents[selectedIndex + 1].id
      navigate(`/projects/${projectId}/validation/${nextDocId}${search}`)
      return nextDocId
    } else if (hasPreviousDocument) {
      return goToPreviousDocument()
    } else {
      navigate(`/projects/${projectId}/validation${search}`)
    }
  }

  async function updateListDocumentCache(updatedDocument: Document) {
    if (!query.queryKey) return
    await queryClient.cancelQueries({ queryKey: query.queryKey })

    if (isSetList) {
      queryClient.setQueryData(query.queryKey, (oldData: Document[] = []) =>
        oldData.map((document) =>
          document.id === updatedDocument.id ? updatedDocument : document,
        ),
      )
    } else {
      queryClient.setQueryData(
        query.queryKey,
        (oldData: { pages?: PaginatedResponse<Document>[] } = {}) => {
          return {
            ...oldData,
            pages: oldData?.pages?.map((page) => {
              return {
                next: page.next,
                previous: page.previous,
                results: page.results.map((document) => {
                  if (document.id === updatedDocument.id) {
                    return updatedDocument
                  }
                  return document
                }),
              }
            }),
          }
        },
      )
    }
  }

  useEffect(() => {
    if (isSetList) return
    if (
      (selectedIndex + 1) % 40 === 0 &&
      !hasNextDocument &&
      hasNextPage &&
      !isFetching
    ) {
      fetchNextPage()
    }
  }, [
    fetchNextPage,
    hasNextDocument,
    hasNextPage,
    infiniteListQuery,
    isFetching,
    isSetList,
    query,
    selectedIndex,
  ])

  useEffect(() => {
    if (!isSetList && !isLoading && documents.length && !navCheckRef.current) {
      if (!documentId) {
        navigate(
          `/projects/${projectId}/validation/${
            documents[0].id
          }?${searchParams.toString()}`,
          {
            replace: true,
          },
        )
      }
      // We do this so we only navigate to the first document, the first time we
      // get into the validation screen with no document in the url
      navCheckRef.current = true
    }
  }, [
    documentId,
    documents,
    isLoading,
    isSetList,
    navigate,
    projectId,
    searchParams,
  ])

  return {
    ...query,
    isSetList,
    documents,
    documentsCount: isSetList ? documentIds.length : data?.pages[0].count || 0,
    selectedDocument,
    selectedIndex,
    goToNextDocument,
    goToPreviousDocument,
    updateListDocumentCache,
    hasNextDocument,
    hasPreviousDocument,
  }
}
