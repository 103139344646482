import { useMemo } from 'react'
import { Document, DocumentFilteringOption } from '@/types/documents'
import { useGetDocumentRows } from '@/service-library/hooks/document-rows'
import { useGetDocuments } from '@/service-library/hooks/documents'
import { getMetadataServiceId } from '@/services/documents'

export type UseDocumentGridOptions = {
  projectId: string
  projectGridIds: string[]
  queryBy: 'rows' | 'documents'
  workflowStateId: string
  flagCodes?: string[]
  filtering?: DocumentFilteringOption[]
  limit?: string
  ordering?: string[]
  orgIds?: string[] | false
  refetchInterval?: number
  teamIds?: string[]
}

const mungeDocumentsToRows = (documents: Document[]) => {
  return documents.map((document) => ({
    document,
    document_id: document.id,
    id: 'filler-row-id',
    project_grid_id: 'filler-grid-id',
  }))
}

export const constructFieldFilters = (
  endpoint: 'row' | 'document',
  fieldFilters?: DocumentFilteringOption[],
) => {
  if (!fieldFilters) return {}

  const filters: Record<string, string> = {}
  fieldFilters.forEach(({ filterId, value }) => {
    const metadataColumn = filterId.match(/.*ColId/)
    let filter = filterId
    if (metadataColumn) {
      const endpointId = getMetadataServiceId(endpoint, metadataColumn[0])
      // filterIds include filter mode suffixes, so we append them after getting the endpointId
      filter = `${endpointId}${filterId.substring(metadataColumn[0].length)}`
    }
    filters[filter] = value
  })
  return filters
}

export const constructFieldOrdering = (
  endpoint: 'row' | 'document',
  ordering?: string[],
) => {
  if (!ordering) return undefined

  const updatedOrdering = ordering.map((colId) => {
    const isMetadataColumn = colId.endsWith('ColId')
    if (isMetadataColumn) {
      const metadataColId = colId[0] === '-' ? colId.substring(1) : colId
      const endpointId = getMetadataServiceId(endpoint, metadataColId)
      return colId[0] === '-' ? `-${endpointId}` : endpointId
    }
    return colId
  })

  return updatedOrdering.join()
}

export default function useDocumentGrid({
  projectId,
  projectGridIds,
  queryBy,
  refetchInterval,
  workflowStateId,
  flagCodes,
  filtering,
  limit = '40',
  ordering,
  orgIds,
  teamIds,
}: UseDocumentGridOptions) {
  const documentsFieldsOnly =
    'id,name,page_count,owner_org_id,created_at,created_by_user,pre_process_completed_at,document_flags__count,document_rows__document_row_values__row_value_flags__count'
  const commonFilters = {
    any_flag__flag_type__code__in: flagCodes?.join(),
    limit,
  }
  const { documentRows, ...documentRowsQuery } = useGetDocumentRows({
    filters: {
      project_grid_id__in: projectGridIds.join(),
      fields__include: 'document_row_values,document',
      document__fields__only: documentsFieldsOnly,
      document_row_values__fields__include: 'project_content_category_item',
      document__workflow_states__id: workflowStateId,
      ...constructFieldFilters('row', filtering),
      ordering: constructFieldOrdering('row', ordering),
      document__owner_org_id__in: orgIds ? orgIds.join() : undefined,
      document__team_id__in: teamIds?.join(),
      ...commonFilters,
    },
    refetchInterval,
    enabled: queryBy === 'rows',
  })

  const documentFilters = {
    project_id: projectId,
    document_workflow_states__workflow_state_id: workflowStateId,
    ...constructFieldFilters('document', filtering),
    ordering: constructFieldOrdering('document', ordering),
    owner_org_id__in: orgIds ? orgIds.join() : undefined,
    team_id__in: teamIds?.join(),
    ...commonFilters,
  }
  const { documents, ...documentsQuery } = useGetDocuments({
    filters: { ...documentFilters, fields__only: documentsFieldsOnly },
    refetchInterval,
    enabled: queryBy === 'documents',
  })

  // To get the count of documents
  const { data } = useGetDocuments({
    paginator: 'page',
    filters: {
      ...documentFilters,
      fields__only: 'id',
      limit: '1',
    },
    refetchInterval,
    enabled: queryBy === 'documents',
  })

  const rows = useMemo(() => {
    return queryBy === 'rows' ? documentRows : mungeDocumentsToRows(documents)
  }, [documentRows, documents, queryBy])

  const documentGridQuery =
    queryBy === 'rows' ? documentRowsQuery : documentsQuery

  return {
    rows,
    documentsCount: data?.pages[0].count || 0,
    ...documentGridQuery,
  }
}
