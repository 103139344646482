import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Search } from '@mui/icons-material'
import { Alert, DialogContent, LinearProgress, TextField } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetDocument } from '@/service-library/hooks/documents'
import { Dialog } from '@/components/dialog'

type CommandPaletteDialogProps = {
  overlay: OverlayState
}

export default function CommandPaletteDialog({
  overlay,
}: CommandPaletteDialogProps) {
  const [docId, setDocId] = useState('')
  const [enabled, setEnabled] = useState(false)
  const { document, isLoading, isError } = useGetDocument({
    id: docId,
    enabled,
    retry: 2,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (enabled && document) {
      navigate(`/projects/${document.project_id}/validation/${document.id}`)
      overlay.close()
    }
  }, [document, enabled, navigate, overlay])

  return (
    <Dialog
      {...overlay}
      title=""
      maxWidth="sm"
      TransitionProps={{
        onExited: () => {
          if (enabled) {
            setEnabled(false)
            setDocId('')
          }
        },
      }}
    >
      <DialogContent>
        {enabled ? (
          <>
            {isLoading && <LinearProgress />}
            {isError && (
              <Alert severity="error" variant="outlined">
                We were unable to load this document. It may have been removed
                or there may have been an issue while retrieving the data.
                Please try again later.
              </Alert>
            )}
          </>
        ) : (
          <form
            // Wrapping with a form allows submitting by pressing enter
            style={{ flexGrow: 1 }}
            onSubmit={(e) => {
              e.preventDefault()
              if (docId.trim()) {
                setEnabled(true)
              }
            }}
          >
            <TextField
              value={docId}
              size="small"
              variant="outlined"
              fullWidth
              onChange={(e) => setDocId(e.target.value)}
              autoFocus
              InputProps={{
                startAdornment: <Search />,
                sx: { columnGap: 0.5 },
              }}
              placeholder="Go to Document ID"
            />
          </form>
        )}
      </DialogContent>
    </Dialog>
  )
}
