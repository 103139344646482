import { MutableRefObject, useCallback, useEffect } from 'react'
import {
  Position,
  Size,
  revertPointScaling,
} from '@/components/image-zoom-pan/image-zoom-pan-helpers'
import { useDocumentImagePageContext } from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import { useFieldROIContext } from './FieldROIProvider'

type UseDrawUpdateROIProps = {
  imageSize: Size
  rotationDegree: number
  temporalROIValuesRef: MutableRefObject<{
    origin: Position
    end: Position
    drawBox: boolean
    page: number
  } | null>
}

export default function useDrawUpdateROI({
  imageSize,
  rotationDegree,
  temporalROIValuesRef,
}: UseDrawUpdateROIProps) {
  const { field, setEnableSave, newROIRef } = useFieldROIContext()
  const { imagePage } = useDocumentImagePageContext()

  const onROICreationStart = useCallback(() => {
    temporalROIValuesRef.current = {
      origin: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
      drawBox: false,
      page: 1,
    }
    setEnableSave(false)
  }, [setEnableSave, temporalROIValuesRef])

  const onROICreationEnd = useCallback(
    ({
      origin,
      end,
      imagePosition,
    }: {
      origin: Position
      end: Position
      imagePosition: Position
    }) => {
      temporalROIValuesRef.current = {
        origin,
        end,
        drawBox: true,
        page: imagePage,
      }
      newROIRef.current = {
        region_of_interest_left: revertPointScaling({
          axis: 'x',
          imagePosition,
          imageSize,
          value: origin.x,
        }),
        region_of_interest_top: revertPointScaling({
          axis: 'y',
          imagePosition,
          imageSize,
          value: origin.y,
        }),
        region_of_interest_right: revertPointScaling({
          axis: 'x',
          imagePosition,
          imageSize,
          value: end.x,
        }),
        region_of_interest_bottom: revertPointScaling({
          axis: 'y',
          imagePosition,
          imageSize,
          value: end.y,
        }),
        region_of_interest_page: imagePage,
      }
      setEnableSave(true)
    },
    [imagePage, imageSize, newROIRef, setEnableSave, temporalROIValuesRef],
  )

  useEffect(() => {
    const scalePosition = (position: Position) => {
      return {
        x: position.x * imageSize.width,
        y: position.y * imageSize.height,
      }
    }

    if (
      field &&
      field.region_of_interest_page !== null &&
      !temporalROIValuesRef.current
    ) {
      temporalROIValuesRef.current = {
        origin: scalePosition({
          x: field.region_of_interest_left,
          y: field.region_of_interest_top,
        } as Position),
        end: scalePosition({
          x: field.region_of_interest_right,
          y: field.region_of_interest_bottom,
        } as Position),
        drawBox: true,
        page: field.region_of_interest_page,
      }
    }
  }, [
    field,
    imageSize,
    imageSize.height,
    imageSize.width,
    rotationDegree,
    temporalROIValuesRef,
  ])

  return {
    temporalROIValuesRef,
    onROICreationEnd,
    onROICreationStart,
  }
}
