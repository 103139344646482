import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import { AxiosResponse } from 'axios'
import PaginatedResponse from '@/types/paginated-response'
import { CreateDetailRequestOptions, RequestOptions } from '../request-types'
import { ListQueryKeyOption } from '../query-types'

export type UseCreateDetailOptions<T> = UseMutationOptions<
  T,
  unknown,
  T,
  unknown
> &
  RequestOptions &
  ListQueryKeyOption

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: CreateDetailRequestOptions<T>,
  ) => Promise<AxiosResponse<T>>
}

/**
 * This hook is used to create a new item and, optionally, update a related list query with the new item.
 */
export default function useCreateDetail<T>({
  serviceFn,
  sideEffectQueryKeys,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  onSettled,
  ...options
}: UseCreateDetailOptions<T> & ServiceFn<T>) {
  const queryClient = useQueryClient()

  return useMutation({
    sideEffectQueryKeys,
    mutationFn: (item) => {
      return serviceFn({
        item,
        filters,
        axiosOptions,
      }).then(({ data }) => data)
    },
    onMutate: async (item) => {
      let previous: InfiniteData<PaginatedResponse<T>> | undefined = undefined
      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page, index) => {
              if (index !== old?.pages.length - 1) return page
              return {
                ...page,
                results: [...page.results, item],
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }
      // Run user supplied onMutate function if available
      onMutate?.(item)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, item, context) => {
      // Run user supplied onError function if available
      onError?.(error, item, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })
}
