import { Button, Stack, Skeleton, Typography } from '@mui/material'
import throttle from 'lodash.throttle'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import ZerapixTable from '../zerapix-table/ZerapixTable'
import { MRT_ColumnDef } from 'material-react-table'
import { Document } from '@/types/documents'
import { useWorkflowContext } from '../workflow-provider/WorkflowProvider'
import useZerapixTable from '../zerapix-table/useZerapixTable'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'

type ExportingDocumentsDialogProps = {
  overlay: OverlayState
}

const columns: MRT_ColumnDef<Document>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
]

export default function ExportingDocumentsDialog({
  overlay,
}: ExportingDocumentsDialogProps) {
  const { getAllWorkflowStates, getWorkflowStateByCode } = useWorkflowContext()
  const exportedWorkflowState = getWorkflowStateByCode('basic', 'exported')

  const {
    documentWorkflowStates = [],
    isLoading,
    refetch,
  } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id__in: getAllWorkflowStates()
        .map((state) => state.id)
        .join(','),
      status__in: 'exporting',
      fields__include: 'document',
      document__fields__only: 'created_at,id,name,created_by_user',
    },
    enabled: overlay.isOpen, // Only fetch the documents once the overlay is opened
  })

  const throttledRefetch = throttle(refetch, 1000)

  const documents = documentWorkflowStates.map(
    ({ document_id, document, status }) => ({
      ...document,
      id: document_id,
      status,
    }),
  ) as Document[]

  useNotifications({
    // Watch for documents being added to exported
    keys: ['document_workflow_state_status_changed', exportedWorkflowState?.id],
    callback: () => {
      if (!overlay.isOpen) return
      throttledRefetch()
    },
  })

  const table = useZerapixTable<Document>({
    data: documents,
    columns,
    enableTopToolbar: false,
    muiTablePaperProps: {
      elevation: 0,
    },
  })

  return (
    <Dialog title="Exporting Documents" {...overlay} maxWidth="md">
      <DialogContent>
        {isLoading && (
          <Stack spacing={1}>
            <Skeleton variant="text" width={400} />
            <Skeleton variant="text" width={250} />
            <Skeleton variant="text" width={350} />
          </Stack>
        )}

        {!isLoading && (
          <>
            {documents.length > 0 ? (
              <Stack spacing={2}>
                <Typography>
                  These documents are currently being exported.
                </Typography>
                <ZerapixTable<Document> table={table} />
              </Stack>
            ) : (
              <Typography>All documents have been exported.</Typography>
            )}
          </>
        )}
      </DialogContent>

      <DialogFooter>
        <Button
          variant="text"
          onClick={() => {
            overlay.close()
          }}
        >
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
