import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import useValidationNavList from './useValidationNavList'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDocumentFiltersContext } from '@/components/document-filters/DocumentFiltersProvider'
import { useSelectedWorkflowStatesContext } from '@/components/workflows/SelectedWorkflowStatesProvider'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import useGetValidationDocument from '@/services/hooks/useGetValidationDocument'

type DocumentListContextValue = ReturnType<typeof useValidationNavList> & {
  ordering: string[]
  setOrdering: Dispatch<SetStateAction<string[]>>
}

const DocumentListContext = createContext<DocumentListContextValue>(
  {} as DocumentListContextValue,
)

export const useDocumentListContext = () => useContext(DocumentListContext)

type DocumentListProviderProps = {
  children: ReactNode
}

export default function DocumentListProvider({
  children,
}: DocumentListProviderProps) {
  const { projectId, documentId } = useParams()
  const [searchParams] = useSearchParams()
  const documentIds = searchParams.get('documentIds')?.split(',')

  const [ordering, setOrdering] = useState(
    documentIds ? [] : ['-pre_process_completed_at'],
  )

  const { documentFilters, includeFilter } = useDocumentFiltersContext()
  const { selectedWorkflowStates } = useSelectedWorkflowStatesContext()
  const workflowStateIds = selectedWorkflowStates.map((state) => state.id)

  const query =
    useValidationNavList({
      projectId: projectId as string,
      documentId,
      documentIds,
      ordering,
      orgIds: includeFilter.orgs && documentFilters?.orgIds,
      teamIds: includeFilter.teams ? documentFilters?.teamIds : undefined,
      workflowStateIds,
    }) || []

  // This pre-fetches the next document and puts it into cache so it'll load immediately when the user switches to it
  const { document: nextDocument } = useGetValidationDocument({
    id: query.documents[query.selectedIndex + 1]?.id || '',
    enabled:
      query.selectedIndex !== -1 && !!query.documents[query.selectedIndex + 1],
  })

  useNotifications({
    keys: ['document_workflow_state_changed'],
    callback: ({ action, updated_entity_ids }) => {
      const stateGotUpdated = updated_entity_ids.some((id) =>
        workflowStateIds.includes(id),
      )
      if (
        // Refetch the list if there are document state changes in any of the currently displayed workflows
        stateGotUpdated ||
        (action === 'document_workflow_state_changed' &&
          documentId &&
          updated_entity_ids.includes(documentId))
      ) {
        query.refetch()
      }
    },
  })

  // This pre-fetches the next document's image so it will load from cache
  useEffect(() => {
    if (!nextDocument) return
    const img = new Image()
    img.src = nextDocument?.document_pages?.[0]?.image_urls.full
  }, [nextDocument])

  return (
    <DocumentListContext.Provider value={{ ordering, setOrdering, ...query }}>
      {children}
    </DocumentListContext.Provider>
  )
}
