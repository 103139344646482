import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import debounce from 'lodash.debounce'
import { ArrowDropDown, Clear } from '@mui/icons-material'
import {
  Autocomplete,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import usePickerFieldData, { getLabel } from '@/hooks/usePickerFieldData'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { AutocompleteOption } from './AutocompleteField'
import { useGetChildDataList } from '@/service-library/hooks/data-lists'
import { useGetDataListColumns } from '@/service-library/hooks/data-list-columns'
import { useGetDataListEntry } from '@/service-library/hooks/data-list-entries'

type PickerFieldProps = {
  onChange: (newValue: string | null, refId: string | null) => void
  field: ProjectGridField
  value: AutocompleteOption | null
  setDisableArrowKeys: Dispatch<SetStateAction<boolean>>
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function PickerField({
  field,
  value,
  setDisableArrowKeys,
  label,
  isInTable,
  onChange,
  ...props
}: PickerFieldProps) {
  const { document } = useDocumentContext()

  const {
    data_list_id: metadataListId,
    data_list_column_id: metadataColumnId,
  } = field.metadata // We are keeping metadata for backwards compatibility

  const {
    data_list_id: paramsListId,
    data_list_column_names: paramsColumnNames,
  } = field.params || {}

  const isListValue = !!value && value.id !== 'None'

  const [enabled, setEnabled] = useState(false)

  const [open, setOpen] = useState(false)

  // This returns either the data list, or the data list this document's organization inherits from,
  // so the listId we pass in here might be different from the one we get back. That's by design.
  const { dataList } = useGetChildDataList({
    orgId: document?.owner_org_id as string,
    id: paramsListId || metadataListId || '',
    enabled: !!document?.owner_org_id && !!(paramsListId || metadataListId),
  })

  const { dataListColumns } = useGetDataListColumns({
    filters: {
      limit: '1000',
      data_list_id: paramsListId || metadataListId || '',
    },
  })

  const { dataListEntry, isLoading: listEntryIsLoading } = useGetDataListEntry({
    id: isListValue ? value.id : '',
    filters: {
      fields__include: 'data_list_entry_cells',
      data_list_entry_cells__fields__include: 'data_list_entry_cell_values',
    },
  })

  const columnsMapping = useMemo(() => {
    if (!dataListColumns.length) return {}

    return dataListColumns.reduce<Record<string, string>>((acc, column) => {
      acc[column.id] = column.name
      return acc
    }, {})
  }, [dataListColumns])

  const dataListColumnNames = useMemo(() => {
    if (paramsColumnNames) return paramsColumnNames

    if (metadataColumnId && columnsMapping[metadataColumnId])
      return [columnsMapping[metadataColumnId]]

    return []
  }, [paramsColumnNames, metadataColumnId, columnsMapping])

  const updatedValue = useMemo(() => {
    if (!isListValue || listEntryIsLoading) return value

    return {
      id: value.id,
      label:
        getLabel(
          dataListEntry?.data_list_entry_cells,
          dataListColumnNames,
          columnsMapping,
        ) ||
        value?.label ||
        '',
    }
  }, [
    columnsMapping,
    dataListColumnNames,
    dataListEntry?.data_list_entry_cells,
    isListValue,
    listEntryIsLoading,
    value,
  ])

  const [inputValue, setInputValue] = useState(updatedValue?.label || '')
  const [searchValue, setSearchValue] = useState(updatedValue?.label || '')

  const { options, isLoading, hasNextPage, fetchNextPage } = usePickerFieldData(
    {
      columnsMapping,
      dataListId: dataList?.id,
      dataListColumnNames,
      filterString: searchValue,
      value: updatedValue,
      useFilter: !!searchValue && searchValue !== updatedValue?.label,
      enabled,
      keepPreviousData: true,
    },
  )

  const handleOpen = (e: React.SyntheticEvent<Element, Event>) => {
    if (
      e.type !== 'mousedown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowDown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowUp'
    ) {
      setOpen(true)
      !enabled && setEnabled(true) // We fetch the first time the dialog opens
      setDisableArrowKeys(true)
    }
  }

  const clearingInInputRef = useRef(false)
  const popperScrollRef = useRef(0)
  const onScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const target = event.target as HTMLDivElement
    const bottom =
      Math.abs(target.clientHeight - (target.scrollHeight - target.scrollTop)) <
      5
    if (bottom && hasNextPage) {
      fetchNextPage()
    }
    popperScrollRef.current = target.scrollTop
  }

  const debouncedUpdateValue = useMemo(
    () => debounce((newValue: string) => setSearchValue(newValue), 200),
    [],
  )

  // Debounce updating the search value
  useEffect(() => {
    if (inputValue !== searchValue) {
      debouncedUpdateValue(inputValue)
    }
  }, [debouncedUpdateValue, inputValue, searchValue])

  const dataListEntryIdRef = useRef(dataListEntry?.id)
  useEffect(() => {
    // We want to reset input and search value if a value exists when we have the dataListEntry
    if (
      updatedValue &&
      dataListEntry &&
      dataListEntryIdRef.current !== dataListEntry?.id
    ) {
      dataListEntryIdRef.current = dataListEntry.id
      setInputValue(updatedValue?.label || '')
      setSearchValue(updatedValue?.label || '')
    }
  }, [dataListEntry, inputValue, updatedValue])

  return (
    <Autocomplete<AutocompleteOption, false, true | false>
      disabled={props.disabled}
      fullWidth
      loading={isLoading || (isListValue && listEntryIsLoading)}
      clearIcon={<Clear fontSize="inherit" />}
      popupIcon={<ArrowDropDown fontSize="inherit" />}
      value={updatedValue}
      options={options}
      disableClearable
      onChange={(_event, newValue, reason) => {
        // If the user clears the input by using the keyboard, we don't want to
        // make a call yet because they might be typing a new value
        if (!newValue && reason === 'clear' && clearingInInputRef.current) {
          clearingInInputRef.current = false
        } else {
          onChange(newValue?.label || null, newValue?.id || null)
        }
      }}
      open={open}
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false)
        setDisableArrowKeys(false)
        setSearchValue('')
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (!newInputValue && reason === 'input') {
          clearingInInputRef.current = true
        }
        setInputValue(newInputValue)
      }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option?.id === value.id}
      renderInput={(params) => {
        return (
          <TextField
            {...props}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
              style: {
                ...params.inputProps.style,
                ...props.inputProps?.style,
                fontStyle: value?.id === 'None' ? 'italic' : undefined,
                color: value?.id === 'None' ? 'gray' : undefined,
              },
            }}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...props.InputLabelProps,
            }}
          />
        )
      }}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        )
      }}
      ListboxComponent={forwardRef<
        HTMLDivElement,
        React.HTMLAttributes<HTMLElement>
      >((props, ref) => {
        // This is awful, but MUI autocomplete completely re-renders the list every time the data changes,
        // so it automatically scrolls to the top. This resets the scroll position each time. For some reason,
        // is needs the set timeout.
        const listRef = useRef<HTMLUListElement>(null)
        useEffect(() => {
          if (popperScrollRef.current) {
            setTimeout(() => {
              listRef.current?.scrollTo({
                left: 0,
                top: popperScrollRef.current,
                behavior: 'instant',
              })
            }, 0)
          }
        }, [])
        return (
          <div ref={ref}>
            <ul {...props} ref={listRef} onScroll={onScroll} />
          </div>
        )
      })}
      slotProps={{
        clearIndicator: {
          size: isInTable ? 'small' : undefined,
        },
        popupIndicator: {
          size: isInTable ? 'small' : undefined,
        },
        popper: {
          sx: {
            minWidth: 'fit-content',
            width: 'max-content',
          },
        },
      }}
    />
  )
}
