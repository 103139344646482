import { useLocation } from 'react-router-dom'
import {
  Description,
  TableRows,
  Settings,
  FactCheck,
  DeveloperBoard,
} from '@mui/icons-material'
import { Stack } from '@mui/material'
import usePermission from '@/hooks/usePermission'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import TopBarNavigation from '@/components/top-bar-portal/TopBarNavigation'
import FileUploadButton from '@/components/upload-files/FileUploadButton'
import { useFileUploadContext } from '@/components/upload-files/FileUploadProvider'
import HorizontalTabs from '@/components/tabs/HorizontalTabs'
import HorizontalTab from '@/components/tabs/HorizontalTab'
import DownloadExportButton from '@/components/workflows/DownloadExportButton'
import ProjectIndicators from './ProjectIndicators'

const routes = [
  {
    label: 'Documents',
    to: 'documents',
    icon: <Description fontSize="small" />,
  },
  {
    label: 'Data',
    to: 'data',
    icon: <TableRows fontSize="small" />,
  },
  {
    label: 'Validation',
    to: 'validation',
    icon: <FactCheck fontSize="small" />,
  },
  {
    label: 'Models',
    to: 'models',
    icon: <DeveloperBoard fontSize="small" />,
    needPermissions: true,
  },
  {
    label: 'Settings',
    to: 'settings',
    icon: <Settings fontSize="small" />,
    needPermissions: true,
  },
]

export default function ProjectNavigation() {
  const { project } = useProjectContext()

  const { hasEditingPermission } = usePermission()
  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  const { handleFilesSelectedToUpload } = useFileUploadContext()

  const { pathname } = useLocation()
  const selectedRouteIndex = routes.findIndex((route) =>
    pathname.includes(`${project.id}/${route.to}`),
  )

  return (
    <TopBarNavigation>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 0.5, pr: 1, py: 0.5, position: 'relative' }}
        spacing={2}
      >
        <HorizontalTabs selectedTabIndex={selectedRouteIndex}>
          {routes.map((route, index) => {
            if (!canEditProject && route.needPermissions) return null
            return (
              <HorizontalTab
                key={route.to}
                selected={selectedRouteIndex === index}
                {...route}
              />
            )
          })}
        </HorizontalTabs>

        <div style={{ flexGrow: 1 }} />

        <ProjectIndicators />
        <DownloadExportButton projectId={project.id} />
        <FileUploadButton multiple onFileUpload={handleFilesSelectedToUpload} />
      </Stack>
    </TopBarNavigation>
  )
}
