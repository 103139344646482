import useGetThemeColor from '@/hooks/useGetThemeColor'
import { Workflow, WorkflowState } from '@/types/workflows'
import { Add, DragIndicator } from '@mui/icons-material'
import {
  Box,
  Button,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import AddEditWorkflowStateDialog from './AddEditWorkflowStateDialog'
import useOverlay from '@/hooks/useOverlay'
import { DraggableList } from '../lists/DraggableList'
import { Draggable } from 'react-smooth-dnd'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  useGetWorkflowStates,
  useUpdateWorkflowStates,
} from '@/service-library/hooks/workflow-states'
import queryKeys from '@/service-library/query-keys'

type WorkflowDetailsProps = {
  workflow: Workflow
}

export default function WorkflowDetails({ workflow }: WorkflowDetailsProps) {
  const getThemeColor = useGetThemeColor()
  const newWorkflowStateOverlay = useOverlay()

  const { workflowStates: savedWorkflowStates, queryKey } =
    useGetWorkflowStates({
      filters: {
        limit: '1000',
        workflow_id: workflow.id,
      },
      staleTime: 0, // Prevent firing off this request since we have initialData
      initialListData: workflow.workflow_states,
    })

  const displayableWorkflowStates = useMemo(() => {
    return (
      savedWorkflowStates
        ?.filter(({ code }) => code !== 'processing')
        .toSorted((a, b) => a.sort_order - b.sort_order) || []
    )
  }, [savedWorkflowStates])

  // Normally we wouldn't use a state for this, since it's statefully coming from the workflow prop,
  // but this prevents weird flickering when drag-and-dropping to change sort order.
  const [workflowStates, setWorkflowStates] = useState<WorkflowState[]>(
    displayableWorkflowStates,
  )

  useEffect(() => {
    setWorkflowStates(displayableWorkflowStates)
  }, [displayableWorkflowStates])

  const { updateWorkflowStates } = useUpdateWorkflowStates({
    listQueryKey: queryKey,
    sideEffectQueryKeys: [queryKeys.workflows.all],
  })

  function onChange(updatedOrderWorkflowStates: WorkflowState[]) {
    const updatedWorkflowStates = updatedOrderWorkflowStates.map(
      (state, index) => ({
        ...state,
        sort_order: index,
      }),
    )
    setWorkflowStates(updatedWorkflowStates)
    updateWorkflowStates(updatedWorkflowStates)
  }

  return (
    <Box>
      <Stack spacing={1}>
        <Typography variant="h5">{workflow.name}</Typography>

        <Stack spacing={0}>
          <DraggableList data={workflowStates || []} onChange={onChange}>
            {workflowStates?.map((state) => {
              return (
                // @ts-expect-error -- Draggable can have children
                <Draggable key={state.id}>
                  <Stack
                    spacing={0.5}
                    key={state.id}
                    direction="row"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        background: getThemeColor(state.color),
                        width: 4,
                        height: 24,
                        borderRadius: 4,
                      }}
                    />
                    <ListItemButton
                      component={Link}
                      to={state.id}
                      sx={{
                        borderRadius: 2,
                        py: 0.25,
                        px: 1,
                      }}
                    >
                      <ListItemText sx={{ flexGrow: 1 }} primary={state.name} />
                      {workflow.initial_workflow_state_id === state.id && (
                        <Typography variant="caption" color="text.secondary">
                          Initial State
                        </Typography>
                      )}
                    </ListItemButton>

                    <DragIndicator
                      className="drag-handle"
                      sx={{
                        cursor: 'grab',
                        color: 'text.secondary',
                        ':active': { cursor: 'grabbing' },
                      }}
                    />
                  </Stack>
                </Draggable>
              )
            })}
          </DraggableList>
        </Stack>

        <Box sx={{ transform: 'translateX(-6px)' }}>
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={newWorkflowStateOverlay.open}
          >
            Add Workflow State
          </Button>
        </Box>
        <AddEditWorkflowStateDialog
          overlay={newWorkflowStateOverlay}
          workflowId={workflow.id}
          listQueryKey={queryKey}
        />
      </Stack>
    </Box>
  )
}
