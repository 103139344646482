import { DocumentRow } from '@/types/documents'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { deleteCall, updateList } from '../request-wrappers'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import PaginatedResponse from '@/types/paginated-response'

const service = buildService<DocumentRow>({
  pathCategory: 'document_rows',
})

export function useGetDocumentRow({
  id,
  ...options
}: UseGetDetailOptions<DocumentRow>) {
  const query = useGetDetail<DocumentRow>({
    id,
    queryKey: queryKeys.documentRows.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    documentRow: query.data,
    ...query,
  }
}

export function useCreateDocumentRow(
  options: UseCreateDetailOptions<DocumentRow> = {},
) {
  const mutation = useCreateDetail<DocumentRow>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocumentRow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocumentRow(
  options: UseUpdateDetailOptions<DocumentRow> = {},
) {
  const mutation = useUpdateDetail<DocumentRow>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocumentRow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentRow(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<DocumentRow>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocumentRow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocumentRows({
  filters,
  ...options
}: UseGetListOptions<DocumentRow> = {}) {
  const query = useGetList<DocumentRow>({
    filters,
    queryKey: queryKeys.documentRows.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    documentRows: query.allData,
    ...query,
  }
}

export function useUpdateDocumentRows(
  options: UseUpdateListOptions<DocumentRow> = {},
) {
  const mutation = useUpdateList<DocumentRow>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocumentRows: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocumentRows({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<DocumentRow>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocumentRows: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Delete Document Row With Cleanup
 * Used to delete a document row with additional cleanup on backend.
 */
export function useDeleteDocumentRowWithCleanup({
  workflowId,
  ...options
}: UseDeleteDetailOptions & { workflowId: string }) {
  const mutation = useDeleteDetail<DocumentRow>({
    serviceFn: ({ id, axiosOptions }) => {
      return deleteCall({
        url: `/v2/pd/document_rows/${id}/apply_with_cleanup`,
        axiosOptions,
        filters: { workflow_id: workflowId },
      })
    },
    ...options,
  })

  return {
    deleteDocumentRow: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Delete Document Rows With Cleanup
 * Used to delete a list of document rows with additional cleanup on backend.
 */
export function useDeleteDocumentRowsWithCleanup({
  workflowId,
  ...options
}: UseDeleteListOptions & { workflowId: string }) {
  const mutation = useDeleteList<DocumentRow>({
    serviceFn: ({ ids, axiosOptions }) => {
      return deleteCall({
        url: '/v2/pd/document_rows/apply_with_cleanup',
        axiosOptions,
        filters: { workflow_id: workflowId, id__in: ids.join(',') },
      })
    },
    ...options,
  })

  return {
    deleteDocumentRows: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create Document Rows With Cleanup
 * Used to create document rows with additional cleanup on backend.
 */
export function useCreateUpdateDocumentRowsWithCleanup({
  workflowId,
  ...options
}: UseUpdateListOptions<DocumentRow> & { workflowId: string }) {
  const mutation = useUpdateList<DocumentRow>({
    serviceFn: ({ items, ...serviceFnOptions }) => {
      const initialRowNumber = items[0].row_number
      const newItems = items.map((item, index) => ({
        ...item,
        row_number: initialRowNumber + index,
      }))
      return updateList<DocumentRow>({
        url: '/v2/pd/document_rows/apply_with_cleanup',
        items: newItems,
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    createOrUpdateDocumentRows: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Insert a Document Row
 * Used to insert a document row with additional cleanup on backend.
 */
function updateRowNumbers(items: DocumentRow[], initialRowNumber: number) {
  return items.map((item, index) => ({
    ...item,
    row_number: initialRowNumber + index,
  }))
}
export function useInsertDocumentRow({
  listQueryKey,
  workflowId,
  ...options
}: UseUpdateListOptions<DocumentRow> & { workflowId: string }) {
  const queryClient = useQueryClient()

  function getCachedRows() {
    // @ts-expect-error -- shush
    const { pages } = queryClient.getQueryData(listQueryKey)
    // We will only ever have one page in this case
    return pages[0].results as DocumentRow[]
  }

  function getUpdatedTrailingRows(
    newItems: DocumentRow[],
    originalRowNumber: number,
  ) {
    if (!listQueryKey) return []

    const cachedRows = getCachedRows()
    const highestNewRowNumber = newItems[newItems.length - 1].row_number

    const rowsToUpdate = cachedRows.filter(
      (row) =>
        row.row_number >= originalRowNumber &&
        !newItems.find(({ id }) => id === row.id),
    )

    const updatedRows = rowsToUpdate.map((row, index) => {
      return {
        ...row,
        row_number: highestNewRowNumber + index + 1,
      }
    })

    return updatedRows
  }

  const mutation = useUpdateList<DocumentRow>({
    // This fires only after the batched request fires
    serviceFn: ({ items, ...serviceFnOptions }) => {
      const cachedRows = getCachedRows()
      const indexOfFirstNewItem = cachedRows.findIndex((row) => {
        return items.find((item) => item.id === row.id)
      })
      const updatedItems = cachedRows.slice(indexOfFirstNewItem)
      return updateList<DocumentRow>({
        url: '/v2/pd/document_rows/apply_with_cleanup',
        items: updatedItems,
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },

    // This fires every time, so we want to update the cache here
    // This "items" is likely just going to be one item, since this isn't the batched group of items
    onMutate: async (items) => {
      if (!listQueryKey) return
      await queryClient.cancelQueries({ queryKey: listQueryKey })

      const cached =
        queryClient.getQueryData<InfiniteData<PaginatedResponse<DocumentRow>>>(
          listQueryKey,
        )

      if (!cached) return

      const cachedRows = getCachedRows()
      const highestRowNumber = cachedRows[cachedRows.length - 1].row_number + 1
      const newItems = updateRowNumbers(items, highestRowNumber)
      const trailingItems = getUpdatedTrailingRows(
        newItems,
        items[0].row_number,
      )

      const updatedCacheValues = [
        ...(trailingItems.length
          ? cachedRows.slice(0, -trailingItems.length)
          : cachedRows), // If there are no trailing items, we want to keep all the original items
        ...newItems,
        ...trailingItems,
      ]

      queryClient.setQueryData(listQueryKey, {
        ...cached,
        pages: [{ ...cached.pages[0], results: updatedCacheValues }],
      })
    },
    ...options,
  })

  return {
    insertDocumentRow: mutation.mutateAsync,
    ...mutation,
  }
}
