import { ReactNode, useEffect, useRef } from 'react'
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { Chip, Popover, Stack } from '@mui/material'
import { DocumentChip } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'

type ImageZoomPanPopoverProps = {
  chips: DocumentChip[]
  overlay: OverlayState
  anchorPosition: { left: number; top: number }
  onChipClick: (chip: DocumentChip) => void
}

export default function ImageZoomPanPopover({
  chips,
  overlay,
  anchorPosition,
  onChipClick,
}: ImageZoomPanPopoverProps) {
  const { selectedField, isSelectedField } = useSelectedFieldContext()
  const selectedFieldRef = useRef({ old: selectedField })

  useEffect(() => {
    if (!selectedFieldRef.current.old)
      selectedFieldRef.current.old = selectedField
  }, [selectedField])

  useEffect(() => {
    if (
      selectedFieldRef.current.old &&
      selectedFieldRef.current.old !== selectedField
    ) {
      overlay.close()
      selectedFieldRef.current.old = selectedField
    }
  }, [overlay, selectedField])

  return (
    <Popover
      open={overlay.isOpen}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={overlay.close}
      disableAutoFocus={!!selectedField}
      disableRestoreFocus={!selectedField}
      slotProps={{
        backdrop: {
          onMouseDown: (e) => {
            // Prevent clicking backdrop from also causing selected field to lose focus [sc-7297]
            e.preventDefault()
          },
        },
      }}
      PaperProps={{
        elevation: 4,
        onMouseDown: (e) => e.preventDefault(),
        // zIndex used to match canvas zIndex
        sx: { p: 1, zIndex: 4 },
      }}
    >
      <Stack spacing={0.5} sx={{ width: '100%' }}>
        {chips?.map((chip) => {
          const isSelected = isSelectedField(
            chip.project_grid_field_id,
            chip.document_row_id,
          )
          let label: ReactNode = chip.text
          if (label === 'TrueBox') {
            label = <CheckBoxOutlined fontSize="small" />
          } else if (label === 'FalseBox') {
            label = <CheckBoxOutlineBlankOutlined fontSize="small" />
          }
          return (
            <Chip
              key={chip.id}
              id={chip.id}
              sx={{
                minWidth: '50px',
                width: 'fit-content',
                // Aligns checkbox and delete icons
                '.MuiChip-label': { display: 'flex' },
              }}
              label={label}
              clickable
              onClick={() => onChipClick(chip)}
              onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                e.preventDefault()
              }
              color={isSelected ? 'primary' : 'neutral'}
              variant={chip.project_grid_field_id ? 'filled' : 'outlined'}
            />
          )
        })}
      </Stack>
    </Popover>
  )
}
