import { memo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import FailedDocumentInfo from './FailedDocumentInfo'
import LayeredLoadingWheel from './LayeredLoadingWheel'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'

type ProcessingOverlayProps = {
  documentId: string
  processingWorkflowState?: WorkflowState
}

const statusMapping: Record<string, string> = {
  created: 'Preparing',
  uploaded: 'Running OCR',
  ocr_complete: 'Processing',
  processing: 'Processing',
  processed: 'Processing',
}

const generateRandomNumber = (lessThan: number) =>
  Math.floor(Math.random() * lessThan)

// Generate a random number between -25 and 25
const generateRandomNumberForOrigin = () => generateRandomNumber(51) - 25

const createBubbles = (length: number) => {
  return Array.from({ length }).map((node, i) => {
    const evenNumber = i % 2 === 0
    return (
      <Box
        key={`bubble-${i}`}
        component="span"
        sx={{
          '@keyframes move': {
            from: {
              transform: `scale(${
                evenNumber ? 0 : 2
              }) translate3d(0, 0, 1px) rotate(360deg)`,
              opacity: evenNumber ? 1 : 0,
            },
            to: {
              transform: `scale(${
                evenNumber ? 2 : 0
              }) translate3d(0, 0, 1px) rotate(360deg)`,
              opacity: evenNumber ? 0 : 1,
            },
          },
          width: '3vmin',
          height: '3vmin',
          borderRadius: '3vmin',
          backfaceVisibility: 'hidden',
          position: 'absolute',
          animation: 'move 35s linear infinite',
          backgroundColor: (theme) => `${theme.palette.primary.main}80`,
          top: `${generateRandomNumber(100)}%`,
          left: `${generateRandomNumber(100)}%`,
          animationDuration: '25s',
          animationDelay: `-${generateRandomNumber(100)}s`,
          transformOrigin: `${generateRandomNumberForOrigin()}vw ${generateRandomNumberForOrigin()}vh`,
        }}
      />
    )
  })
}

function ProcessingStatus({
  documentId,
  processingWorkflowState,
}: ProcessingOverlayProps) {
  const { documentWorkflowStates = [], refetch } = useGetDocumentWorkflowStates(
    {
      filters: {
        limit: '1000',
        document_id: documentId,
        workflow_state_id__in: processingWorkflowState?.id,
      },
      enabled: !!processingWorkflowState,
    },
  )

  const processingStatus = documentWorkflowStates[0]?.status || 'created'

  useNotifications({
    keys: ['document_workflow_state_status_changed'],
    callback: ({ updated_entity_ids }) => {
      if (updated_entity_ids.includes(documentId)) refetch()
    },
  })

  return processingStatus === 'error' ? (
    <FailedDocumentInfo />
  ) : (
    <>
      <Stack sx={{ marginTop: '100px', textAlign: 'center' }}>
        <Typography variant="h5" color="primary">
          Processing
        </Typography>
        <Typography variant="caption" color="primary">
          {statusMapping[processingStatus] || 'Processing'}
        </Typography>
      </Stack>
      <LayeredLoadingWheel />
    </>
  )
}

function ProcessingOverlay(props: ProcessingOverlayProps) {
  return (
    <Stack
      id="overlay"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        cursor: 'default',
        height: '100%',
        width: '100%',
        position: 'relative',
        overflow: 'clip',
      }}
    >
      <ProcessingStatus {...props} />
      {createBubbles(30)}
    </Stack>
  )
}

export default memo(ProcessingOverlay)
