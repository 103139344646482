import { Stack, useTheme } from '@mui/material'
import { ReactNode, useRef } from 'react'

type HorizontalTabsProps = {
  children: ReactNode
  selectedTabIndex: number
}

export default function HorizontalTabs({
  children,
  selectedTabIndex,
}: HorizontalTabsProps) {
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement>(null)
  const childrenElements = Array.from(containerRef?.current?.children || [])

  const markerLeftPosition =
    childrenElements
      ?.slice(0, selectedTabIndex)
      .reduce<number>((acc, child) => {
        return acc + child.clientWidth
      }, 8) || 0

  const markerWidth =
    childrenElements?.[selectedTabIndex]?.clientWidth - 12 || 0

  return (
    <Stack
      direction="row"
      sx={{
        position: 'relative',
        '&::before': {
          content: '""',
          height: 3,
          width: markerWidth,
          background: theme.palette.primary.main,
          position: 'absolute',
          bottom: -5,
          left: markerLeftPosition,
          borderRadius: 3,
          transition: 'all 0.2s ease-out',
        },
      }}
      ref={containerRef}
    >
      {children}
    </Stack>
  )
}
