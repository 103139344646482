import { ProjectModel } from '@/types/project-models'
import { Category, DocumentScanner } from '@mui/icons-material'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { ReactNode, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import PixelGridBackground from '../pixel-grid-background/PixelGridBackground'
import {
  CATEGORY_MODEL_TYPE_ID,
  NER_MODEL_TYPE_ID,
  getTrainingVersion,
  getVersionInCancelingState,
} from './helpers'
import { blue } from '@mui/material/colors'

type ModelCardProps = {
  projectModel: ProjectModel
  dense?: boolean
}

export default function ModelCard({ projectModel, dense }: ModelCardProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const trainingVersion = getTrainingVersion(
    projectModel.project_model_versions,
  )
  const versionInCancelingState = getVersionInCancelingState(
    projectModel.project_model_versions,
  )

  const modelTypeMap: Record<
    string,
    {
      description: string
      icon: ReactNode
    }
  > = useMemo(
    () => ({
      [NER_MODEL_TYPE_ID]: {
        description: 'Extracts data from documents and enters it into fields.',
        icon: <DocumentScanner fontSize={dense ? 'medium' : 'large'} />,
      },
      [CATEGORY_MODEL_TYPE_ID]: {
        description:
          'Determines category field value based on the document data.',
        icon: <Category fontSize={dense ? 'medium' : 'large'} />,
      },
    }),
    [dense],
  )

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
      ref={containerRef}
    >
      <PixelGridBackground
        animateBackground={!!trainingVersion}
        containerRef={containerRef}
        zIndex={1}
      />
      <CardActionArea
        sx={{
          p: dense ? 2 : 4,
          background: 'transparent',
          position: 'relative',
          zIndex: 2,
        }}
        component={Link}
        to={projectModel.id}
      >
        <Stack direction="row" spacing={4} alignItems="center">
          {modelTypeMap[projectModel.project_model_type_id]?.icon}
          <Box>
            <Typography component="h2" variant={dense ? 'subtitle1' : 'h6'}>
              {projectModel.name}
            </Typography>

            {!dense && (
              <Typography variant="body2" color="text.secondary">
                {modelTypeMap[projectModel.project_model_type_id]?.description}
              </Typography>
            )}
            {trainingVersion && (
              <Typography variant="caption" sx={{ color: blue['A200'] }}>
                Training
              </Typography>
            )}
            {versionInCancelingState && (
              <Typography variant="caption" color="text.secondary">
                Canceling
              </Typography>
            )}
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
