import { useLocation } from 'react-router-dom'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useViewsContext } from '@/components/workflows/ViewsProvider'

export type DocumentFilters = {
  flagCodes: string[]
  orgIds: string[]
  teamIds: string[]
} | null

type DocumentFiltersContextValue = {
  documentFilters: DocumentFilters
  includeFilter: Record<string, boolean>
  handleOnDocumentFilterChange: (
    documentFilterUpdaterOrValue:
      | DocumentFilters
      | ((val: DocumentFilters) => DocumentFilters),
  ) => void // we use this function when we need to enable the user to save/update view
  resetDocumentFilters: () => void
  setDocumentFilters: Dispatch<SetStateAction<DocumentFilters>>
}

const DocumentFiltersContext = createContext<DocumentFiltersContextValue>(
  {} as DocumentFiltersContextValue,
)

export const useDocumentFiltersContext = () =>
  useContext(DocumentFiltersContext)

type DocumentFiltersProviderProps = {
  children: ReactNode
}

export default function DocumentFiltersProvider({
  children,
}: DocumentFiltersProviderProps) {
  const { pathname } = useLocation()
  const { selectedView, handleChange } = useViewsContext()
  const { organizations, isFetchingAll } = useOrganizationsContext()
  const [documentFilters, setDocumentFilters] = useState<DocumentFilters>(null)

  const handleOnDocumentFilterChange = (
    documentFilterUpdaterOrValue:
      | DocumentFilters
      | ((filters: DocumentFilters) => DocumentFilters),
  ) => {
    handleChange()
    setDocumentFilters(documentFilterUpdaterOrValue)
  }

  const resetDocumentFilters = () => {
    setDocumentFilters({
      flagCodes: selectedView.state.flag_codes || [],
      orgIds: selectedView.state.org_ids?.length
        ? selectedView.state.org_ids
        : organizations.map(({ id }) => id),
      teamIds: selectedView.state.team_ids || [],
    })
  }

  useEffect(() => {
    if (
      !isFetchingAll &&
      // We don't update filter if we are in settings or validation
      !pathname.includes('settings') &&
      !pathname.includes('validation')
    ) {
      setDocumentFilters({
        flagCodes: selectedView.state.flag_codes || [],
        orgIds: selectedView.state.org_ids?.length
          ? selectedView.state.org_ids
          : organizations.map(({ id }) => id),
        teamIds: selectedView.state.team_ids || [],
      })
    }
  }, [
    isFetchingAll,
    organizations,
    pathname,
    selectedView.id, // included to update filter when changing views
    selectedView.state.flag_codes,
    selectedView.state.org_ids,
    selectedView.state.team_ids,
  ])

  return (
    <DocumentFiltersContext.Provider
      value={{
        documentFilters,
        includeFilter: {
          flags: !!documentFilters?.flagCodes?.length,
          orgs: organizations.length !== documentFilters?.orgIds?.length,
          teams: !!documentFilters?.teamIds?.length,
        },
        handleOnDocumentFilterChange,
        resetDocumentFilters,
        setDocumentFilters,
      }}
    >
      {children}
    </DocumentFiltersContext.Provider>
  )
}
