import { useProjectContext } from '../project-dashboard/ProjectProvider'
import { Box, Container, Stack, Typography } from '@mui/material'
import WorkflowDetails from './WorkflowDetails'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { useDemoModeContext } from '../demo-mode-provider/DemoModeProvider'
import { useGetWorkflows } from '@/service-library/hooks/workflows'

const hiddenWorkflowCodes = ['labeling', 'categorization']

export default function WorkflowStatesPage() {
  const { project } = useProjectContext()

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const { workflows } = useGetWorkflows({
    filters: {
      limit: '1000',
      project_id: project.id,
      project_model__parent_model_id__isnull: 'true', // Only main workflows
      fields__include: 'workflow_states',
    },
  })

  const displayedWorkflows =
    isSuperUser && !demoMode
      ? workflows
      : workflows.filter(
          (workflow) => !hiddenWorkflowCodes.includes(workflow.code),
        )

  return (
    <>
      <Breadcrumb label="Workflow States" url="../workflow-states" />
      <Container maxWidth="xs">
        <Stack spacing={4}>
          {displayedWorkflows.map((workflow) => {
            return hiddenWorkflowCodes.includes(workflow.code) ? (
              <Box
                key={workflow.id}
                sx={{
                  p: 2,
                  border: `solid 1px ${SUPER_USER_ONLY_COLOR}`,
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: SUPER_USER_ONLY_COLOR }}
                >
                  Super User Only
                </Typography>
                <WorkflowDetails key={workflow.id} workflow={workflow} />
              </Box>
            ) : (
              <WorkflowDetails key={workflow.id} workflow={workflow} />
            )
          })}
        </Stack>
      </Container>
    </>
  )
}
