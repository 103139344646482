import { useParams } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material'
import {
  ContentCut,
  Delete,
  ListAlt,
  MoreVert,
  PlaylistRemove,
  ReadMore,
  Subject,
} from '@mui/icons-material'
import { useQueryClient } from '@tanstack/react-query'
import { Document } from '@/types/documents'
import { Organization } from '@/types/organizations'
import { WorkflowState } from '@/types/workflows'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import {
  useGetDocument,
  useUpdateDocument,
} from '@/service-library/hooks/documents'
import { useSetDocumentsWorkflowState } from '@/service-library/hooks/document-workflow-states'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import DocumentActionsMenu from '@/components/document-actions-menu/DocumentActionsMenu'
import DocumentDialog from '@/components/document-dialog/DocumentDialog'
import EditableDocumentName from '@/components/editable-document-name/EditableDocumentName'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import OrganizationAssignmentSelect from '@/components/organization-select/OrganizationAssignmentSelect'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import SplitDocumentDialog from '@/components/split-document-dialog/SplitDocumentDialog'
import WorkflowStateSelectMenu from '@/components/workflow-state-select-menu/WorkflowStateSelectMenu'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'
import ClearAllChipsDialog from './ClearAllChipsDialog'
import { useDocumentListContext } from './DocumentListProvider'
import ValidationEventButton from './ValidationEventButton'
import ExportJobsDialog from '../export-jobs/ExportJobsDialog'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { useDemoModeContext } from '../demo-mode-provider/DemoModeProvider'
import {
  useFailEvent,
  useWorkflowStateEventStatuses,
} from '@/service-library/hooks/events'
import VisibleFieldsControl from './VisibleFieldsControl'

const hotKeyConfig = {
  preventDefault: true,
  enableOnFormTags: true,
}

type ValidationActionsCardProps = {
  validationDrawer: OverlayState
  documentQuery: ReturnType<typeof useGetDocument>
}

export default function ValidationActionsCard({
  validationDrawer,
  documentQuery,
}: ValidationActionsCardProps) {
  const { project } = useProjectContext()
  const { documentId } = useParams()
  const actionsMenuOverlay = useOverlay()
  const clearAllChipsOverlay = useOverlay()
  const documentOverlay = useOverlay()
  const splitDocumentOverlay = useOverlay()
  const exportJobsOverlay = useOverlay()

  const {
    queryKey: documentListQueryKey,
    goToNextDocument,
    goToPreviousDocument,
    hasNextDocument,
    hasPreviousDocument,
    updateListDocumentCache,
  } = useDocumentListContext()

  const { document: fullDocument, refetch, queryKey } = documentQuery

  const { updateDocument } = useUpdateDocument({
    detailQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Failed to update document.')
    },
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()

  const workflowStates = selectedWorkflow?.workflow_states || []

  const workflowState = workflowStates.find((state) =>
    fullDocument?.workflow_states_ids?.some((id) => id === state.id),
  )

  const queryClient = useQueryClient()
  const { setWorkflowState } = useSetDocumentsWorkflowState({
    sideEffectQueryKeys: [queryKey, documentListQueryKey],
    onMutate: async ({ patch }) => {
      const { workflow_state__id } = patch
      await queryClient.cancelQueries(queryKey)
      const previous = queryClient.getQueryData<Document>(queryKey)
      const newDocument = {
        ...previous,
        workflow_states_ids: [
          workflow_state__id,
          ...(previous?.workflow_states_ids?.filter(
            (workflowStateId) => workflowStateId !== workflowState?.id,
          ) || []),
        ],
      } as Document
      queryClient.setQueryData<Document>(queryKey, newDocument)
      updateListDocumentCache(newDocument)
    },
    onError: () => {
      showErrorSnackbar('Failed to update document workflow state.')
    },
  })

  const { refetch: refetchRowValues } = useDocumentRowValuesContext()
  const { organizations, isFetchingAll } = useOrganizationsContext()
  const { selectedFieldOverlay } = useSelectedFieldContext()

  function onDelete() {
    goToNextDocument()
  }

  function handleUpdateDocumentWorkflowState(newWorkflowState: WorkflowState) {
    if (
      !fullDocument ||
      !workflowState ||
      !selectedWorkflow ||
      newWorkflowState.code === workflowState.code
    )
      return
    setWorkflowState({
      patch: {
        workflow_state__id: newWorkflowState.id,
        workflow_state__code: newWorkflowState.code,
      },
      filter_criteria: {
        document_id__in: [fullDocument.id],
        workflow_state__workflow_id: selectedWorkflow.id,
      },
      current_workflow_state_id: workflowState.id,
    })
  }

  function handleUpdateOrganization(newOrganization: Organization) {
    if (!fullDocument || newOrganization.id === fullDocument.owner_org_id)
      return

    updateDocument({
      ...fullDocument,
      owner_org_id: newOrganization.id,
    })
  }

  const isInProcessingState = workflowState?.code === 'processing'

  const organization = organizations.find(
    ({ id }) => id === fullDocument?.owner_org_id,
  ) as Organization

  useHotkeys('alt+l', () => validationDrawer.toggle(), hotKeyConfig, [
    validationDrawer.toggle,
  ])

  useHotkeys(
    'alt+1',
    () => {
      if (hasPreviousDocument) goToPreviousDocument()
    },
    hotKeyConfig,
    [goToPreviousDocument],
  )

  useHotkeys(
    'alt+2',
    () => {
      if (hasNextDocument) goToNextDocument()
    },
    hotKeyConfig,
    [goToNextDocument],
  )

  // Refetch the document if there are document state changes that include the current document
  useNotifications({
    keys: [documentId],
    callback: ({ action }) => {
      if (documentId) {
        refetch()
        if (
          action === 'document_workflow_state_status_changed' ||
          action === 'document_workflow_state_changed'
        ) {
          refetchRowValues()
        }
      }
    },
  })

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()
  const { eventStatuses = [] } = useWorkflowStateEventStatuses({
    filters: {
      document_id: fullDocument?.id,
    },
    enabled: isSuperUser && !demoMode && !!fullDocument,
  })
  const { failEvent } = useFailEvent()

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{ minHeight: 40 }}
      >
        {fullDocument && (
          <EditableDocumentName
            document={fullDocument}
            disabled={isInProcessingState}
            updateDocument={updateDocument}
            onChange={updateListDocumentCache}
          />
        )}

        <Stack
          direction="row"
          spacing={0.25}
          alignItems="center"
          sx={{ transform: 'translateX(8px)' }}
        >
          <Tooltip
            title={isInProcessingState ? '' : 'Field Details'}
            arrow
            disableInteractive
            enterDelay={1000}
          >
            <IconButton
              size="small"
              disabled={isInProcessingState}
              onClick={() => selectedFieldOverlay.toggle()}
              onMouseDown={(e) => e.preventDefault()}
            >
              <ReadMore
                sx={{
                  transform: `rotate(${
                    selectedFieldOverlay.isOpen ? 0 : '180deg'
                  })`,
                }}
              />
            </IconButton>
          </Tooltip>

          <IconButton
            size="small"
            onClick={actionsMenuOverlay.open}
            disabled={!fullDocument || isInProcessingState}
          >
            <MoreVert />
          </IconButton>

          <DocumentActionsMenu
            projectId={project.id}
            documentId={fullDocument?.id}
            overlay={actionsMenuOverlay}
            onDelete={onDelete}
            onReprocess={() => {
              if (fullDocument)
                updateListDocumentCache({
                  ...fullDocument,
                  workflow_states_ids: [
                    workflowStates.find((state) => state.code === 'processing')
                      ?.id || '',
                  ],
                })
            }}
            workflowState={workflowState}
          >
            <MenuItem
              onClick={() => {
                documentOverlay.open()
                actionsMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <Subject />
              </ListItemIcon>
              <ListItemText>Details</ListItemText>
            </MenuItem>
            {fullDocument && fullDocument.document_pages.length > 1 && (
              <MenuItem
                onClick={() => {
                  splitDocumentOverlay.open()
                  actionsMenuOverlay.close()
                }}
              >
                <ListItemIcon>
                  <ContentCut />
                </ListItemIcon>
                <ListItemText>Split Document</ListItemText>
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                clearAllChipsOverlay.open()
                actionsMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <PlaylistRemove />
              </ListItemIcon>
              <ListItemText>Clear All Chips</ListItemText>
            </MenuItem>

            {isSuperUser && !demoMode && (
              <MenuItem
                sx={{ color: SUPER_USER_ONLY_COLOR }}
                onClick={() => {
                  exportJobsOverlay.open()
                }}
              >
                <ListItemIcon sx={{ color: SUPER_USER_ONLY_COLOR }}>
                  <ListAlt />
                </ListItemIcon>
                <ListItemText>View Export Jobs</ListItemText>
              </MenuItem>
            )}

            {isSuperUser && !demoMode && (
              <MenuItem
                sx={{ color: SUPER_USER_ONLY_COLOR }}
                onClick={() => {
                  eventStatuses.forEach((eventStatus) => {
                    if (
                      eventStatus.status === 'running' ||
                      eventStatus.status === 'waiting'
                    ) {
                      failEvent({ id: eventStatus.id })
                    }
                  })
                }}
              >
                <ListItemIcon sx={{ color: SUPER_USER_ONLY_COLOR }}>
                  <Delete />
                </ListItemIcon>
                <ListItemText>Clear Events</ListItemText>
              </MenuItem>
            )}
          </DocumentActionsMenu>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ maxWidth: '100%', minWidth: 0 }}
      >
        <Stack direction="row" sx={{ ml: -1, minWidth: 0 }}>
          {workflowState && !isInProcessingState && (
            <WorkflowStateSelectMenu
              dense
              selectedWorkflowState={workflowState}
              workflowStates={workflowStates}
              onChange={handleUpdateDocumentWorkflowState}
            />
          )}

          {organization && (
            <OrganizationAssignmentSelect
              dense
              isFetching={isFetchingAll}
              selectedOrganization={organization}
              organizations={organizations}
              onChange={handleUpdateOrganization}
              disabled={isInProcessingState}
              showParentOrg
            />
          )}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ flexShrink: 0 }}>
          {workflowState && fullDocument && (
            <ValidationEventButton
              document={fullDocument}
              workflowStateId={workflowState.id}
            />
          )}
        </Box>
      </Stack>

      {!isInProcessingState && <VisibleFieldsControl />}

      {fullDocument && (
        <>
          <DocumentDialog
            overlay={documentOverlay}
            documentId={fullDocument.id}
            project={project}
            document={fullDocument}
            detailQueryKey={queryKey}
            onDelete={onDelete}
            onDocumentUpdated={updateListDocumentCache}
          />
          <ClearAllChipsDialog
            document={fullDocument}
            overlay={clearAllChipsOverlay}
          />
          {fullDocument.document_pages.length > 1 && (
            <SplitDocumentDialog
              document={fullDocument}
              overlay={splitDocumentOverlay}
              onDelete={onDelete}
            />
          )}
          <ExportJobsDialog
            overlay={exportJobsOverlay}
            document={fullDocument}
          />
        </>
      )}
    </>
  )
}
