import { Delete, Edit } from '@mui/icons-material'
import {
  Button,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { OrgCredential, OrgCredentialType } from '@/types/credentials'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import { getCredential } from '@/services/credentials'
import { useDeleteOrgCredential } from '@/service-library/hooks/org-credentials'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import ManageCredentialAuthDialog from './ManageCredentialAuthDialog'

type CredentialCardProps = {
  addEditOverlay: OverlayState
  credential: OrgCredential
  listQueryKey: QueryKey
  setSelectCredentialId: (id: string) => void
  type?: OrgCredentialType
}

export default function CredentialCard({
  addEditOverlay,
  credential,
  listQueryKey,
  setSelectCredentialId,
  type,
}: CredentialCardProps) {
  const manageAuthOverlay = useOverlay()

  const { deleteOrgCredential } = useDeleteOrgCredential({
    listQueryKey,
    onSuccess: () => {
      showSuccessSnackbar('Credential Deleted')
    },
    onError: () => {
      showErrorSnackbar('Failed to delete credentials. Please contact support.')
    },
  })

  function handleAuthenticate() {
    if (type?.code === 'dr_chrono') {
      getCredential(credential.id).then(({ data }) => {
        window.open(data.auth_url, '_blank')
      })
    } else {
      // TODO: Implement authentication for other integrations if needed
    }
  }

  return (
    <Card sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">{credential.name}</Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectCredentialId(credential.id)
                addEditOverlay.open()
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                deleteOrgCredential(credential.id)
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Typography variant="body2" color="text.secondary">
        Organization: {credential.org?.name || ''}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Integration Type: {type?.name}
      </Typography>

      <Stack
        spacing={0.5}
        sx={{
          mt: 1,
          ml: -0.5, // So that the button text aligns with the card content
        }}
      >
        {type && (
          <Button
            sx={{ width: 'fit-content' }}
            variant="text"
            onClick={manageAuthOverlay.open}
          >
            {credential.needs_secrets ? 'Set' : 'Edit'}{' '}
            {type?.code === 'dr_chrono' ? 'Client ID and Secret' : 'API Token'}
          </Button>
        )}

        {credential.needs_auth && (
          <Button
            color="secondary"
            variant="text"
            onClick={handleAuthenticate}
            sx={{ width: 'fit-content' }}
          >
            Authenticate
          </Button>
        )}
      </Stack>
      {type && (
        <ManageCredentialAuthDialog
          isEdit={!credential.needs_secrets}
          overlay={manageAuthOverlay}
          orgCredentialId={credential.id}
          type={type}
        />
      )}
    </Card>
  )
}
