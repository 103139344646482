import { DragIndicator, Delete, Edit, MoreVert } from '@mui/icons-material'
import {
  Stack,
  Typography,
  IconButton,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  useTheme,
} from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { useRef } from 'react'
import ActionEditForm from './ActionEditForm'
import useOverlay from '@/hooks/useOverlay'
import ActionParamsDisplay from './ActionParamsDisplay'

type ActionCardProps = {
  onDelete: (action: WorkflowStateAction) => void
  onCancel: (action: WorkflowStateAction) => void
  onSave: (action: WorkflowStateAction) => void
  action: WorkflowStateAction
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
}

export default function ActionCard({
  onDelete,
  onCancel,
  isEditing,
  setIsEditing,
  onSave,
  action,
}: ActionCardProps) {
  const actionMenuOverlay = useOverlay()
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mx: 1,
        width: 325,
        border: 'solid 1px transparent',
        borderColor: isEditing
          ? 'secondary.main'
          : theme.palette.mode === 'light'
          ? theme.palette.divider
          : 'transparent',
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <DragIndicator
            className="drag-handle"
            sx={{
              transform: 'translateX(-4px)',
              cursor: 'grab',
              ':active': { cursor: 'grabbing' },
            }}
          />

          <Typography
            variant="body2"
            sx={{ flexGrow: 1, transform: 'translateX(-4px)' }}
          >
            <b>{action.action_type?.name}</b>
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              onClick={actionMenuOverlay.open}
              size="small"
              ref={menuButtonRef}
            >
              <MoreVert fontSize="inherit" />
            </IconButton>
            <Menu
              open={actionMenuOverlay.isOpen}
              anchorEl={menuButtonRef.current}
              onClose={actionMenuOverlay.close}
            >
              {Object.keys(action.params).length > 0 && (
                <MenuItem
                  onClick={() => {
                    actionMenuOverlay.close()
                    setIsEditing(true)
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  Edit
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  onDelete(action)
                  actionMenuOverlay.close()
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                Delete
              </MenuItem>
            </Menu>
          </Stack>
        </Stack>

        {isEditing ? (
          <ActionEditForm
            action={action}
            onSave={(updatedAction) => {
              onSave(updatedAction)
              setIsEditing(false)
            }}
            onCancel={() => {
              onCancel(action)
              setIsEditing(false)
            }}
          />
        ) : (
          <ActionParamsDisplay action={action} />
        )}
      </Stack>
    </Paper>
  )
}
