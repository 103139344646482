import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import KeyboardShortcutsTooltip from '../keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'
import { altHotKeyStr } from '@/utils/get-os'
import { useDocumentListContext } from './DocumentListProvider'

export default function ValidationMiniNavControls() {
  const {
    hasPreviousDocument,
    goToPreviousDocument,
    hasNextDocument,
    goToNextDocument,
  } = useDocumentListContext()
  return (
    <Stack alignItems="center" sx={{ py: 1 }}>
      <KeyboardShortcutsTooltip
        title="Previous Document"
        keyboardShortcuts={[`${altHotKeyStr} + 1`]}
        placement="right"
        enterDelay={1000}
      >
        <IconButton
          size="small"
          disabled={!hasPreviousDocument}
          onClick={goToPreviousDocument}
        >
          <KeyboardArrowUp />
        </IconButton>
      </KeyboardShortcutsTooltip>

      <KeyboardShortcutsTooltip
        title="Next Document"
        keyboardShortcuts={[`${altHotKeyStr} + 2`]}
        placement="right"
        enterDelay={1000}
      >
        <IconButton
          size="small"
          disabled={!hasNextDocument}
          onClick={goToNextDocument}
        >
          <KeyboardArrowDown />
        </IconButton>
      </KeyboardShortcutsTooltip>
    </Stack>
  )
}
