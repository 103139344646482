import {
  ReactNode,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { Organization } from '@/types/organizations'

export const SelectedOrgContext = createContext({
  selectedOrg: {} as unknown as Organization,
  setSelectedOrg: (() => {}) as Dispatch<SetStateAction<Organization>>,
  organizations: [] as Organization[],
})

export const useSelectedOrgContext = () => useContext(SelectedOrgContext)

type SelectedOrgProviderProps = {
  selectedOrg: Organization
  setSelectedOrg: Dispatch<SetStateAction<Organization>>
  organizations: Organization[]
  children: ReactNode
}

export default function SelectedOrgProvider({
  selectedOrg,
  setSelectedOrg,
  organizations,
  children,
}: SelectedOrgProviderProps) {
  return (
    <SelectedOrgContext.Provider
      value={{
        selectedOrg,
        setSelectedOrg,
        organizations,
      }}
    >
      {children}
    </SelectedOrgContext.Provider>
  )
}
