import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Edit, Delete, ChevronRight, ExpandMore } from '@mui/icons-material'
import {
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Card,
  Button,
} from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { QueryKey } from '@tanstack/react-query'
import { DataList } from '@/types/data-lists'
import { Organization } from '@/types/organizations'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import useOverlay from '@/hooks/useOverlay'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useDeleteDataList } from '@/service-library/hooks/data-lists'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { createOrganizationMap, getNodesToExpand } from '@/utils/organization'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { OrgTreeSkeleton } from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import OrganizationTreeItem from '@/components/organizations-tree/OrganizationTreeItem'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import AddEditDataListDialog from './AddEditDataListDialog'
import DataListData from './DataListData'
import DeleteDataListDialog from './DeleteDataListDialog'
import { customDataListTypeID } from './DataListList'
import ListIsInheritingMessage from './ListIsInheritingMessage'
import ViewEditDataListDescriptionDialog from './ViewEditDataListDescriptionDialog'

type DataListDetailsProps = {
  parentDataList?: DataList
  dataList?: DataList
  selectedOrg: Organization | null
  setSelectedOrg: Dispatch<SetStateAction<Organization | null>>
  detailQueryKey: QueryKey
  rootDetailQueryKey: QueryKey
}

export default function DataListDetails({
  parentDataList,
  dataList,
  selectedOrg,
  setSelectedOrg,
  detailQueryKey,
  rootDetailQueryKey,
}: DataListDetailsProps) {
  const featureFlags = useFeatureFlagContext()
  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()
  const navigate = useNavigate()
  const editOverlay = useOverlay()
  const deleteOverlay = useOverlay()
  const descriptionOverlay = useOverlay()

  const { rootOrganization } = useRootOrganization()
  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
  })

  const isCustomList =
    customDataListTypeID === parentDataList?.data_list_type_id
  const rootOrgIsSelected = selectedOrg?.id === rootOrganization.id

  const isInheritingFromParent =
    selectedOrg &&
    dataList &&
    parentDataList &&
    selectedOrg?.id !== dataList?.org_id &&
    !isFetchingAll

  const organizationMap = useMemo(
    () => (isFetchingAll ? {} : createOrganizationMap(organizations)),
    [isFetchingAll, organizations],
  )
  const [showOrgTree, setShowOrgTree] = useState(true)
  const [expanded, setExpanded] = useState([rootOrganization.id])

  const { deleteDataList } = useDeleteDataList({
    onError: () => {
      showErrorSnackbar('Unable to delete list. Please try again later.')
    },
    onSuccess: () => {
      showSuccessSnackbar('List Deleted')
      navigate('../', { replace: true })
    },
  })

  function handleDeleteDataList() {
    if (!dataList) return
    deleteDataList(dataList.id)
  }

  useEffect(() => {
    if (
      !isFetchingAll &&
      selectedOrg &&
      selectedOrg.id !== rootOrganization.id &&
      !expanded.includes(selectedOrg.parent_org_id || '')
    ) {
      setExpanded(getNodesToExpand(selectedOrg.id, organizationMap))
    }
  }, [
    expanded,
    isFetchingAll,
    organizationMap,
    rootOrganization.id,
    selectedOrg,
  ])

  useEffect(() => {
    if (!selectedOrg && organizations[0]) {
      setSelectedOrg(organizations[0])
    }
  }, [organizations, selectedOrg, setSelectedOrg])

  return (
    <Stack spacing={2}>
      {parentDataList && (
        <div>
          <Typography variant="caption" color="text.secondary">
            {isCustomList ? 'Custom List' : 'System Managed List'}
          </Typography>
          <Stack
            sx={{ flexShrink: 0 }}
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Typography variant="h4">{parentDataList?.name}</Typography>
            <Stack direction="row" spacing={1}>
              {isCustomList && (
                <>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={editOverlay.open}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <AddEditDataListDialog
                    overlay={editOverlay}
                    dataList={parentDataList}
                    rootDetailQueryKey={rootDetailQueryKey}
                  />
                  {rootOrgIsSelected && (
                    <>
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          size="small"
                          onClick={deleteOverlay.open}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <DeleteDataListDialog
                        overlay={deleteOverlay}
                        dataList={parentDataList}
                        onDelete={handleDeleteDataList}
                      />
                    </>
                  )}
                </>
              )}
              <Button onClick={descriptionOverlay.open}>
                View Documentation
              </Button>
              <ViewEditDataListDescriptionDialog
                overlay={descriptionOverlay}
                dataList={parentDataList}
                rootDetailQueryKey={rootDetailQueryKey}
              />
              {!demoMode && isSuperUser && (
                <>
                  <CopyIDButton
                    stringToCopy={parentDataList.id}
                    isSuperUser
                    label="Copy Root Data List ID"
                  />
                  {dataList?.id &&
                    !rootOrgIsSelected &&
                    !isInheritingFromParent && (
                      <CopyIDButton
                        stringToCopy={dataList.id}
                        isSuperUser
                        label="Copy Child Data List ID"
                      />
                    )}
                </>
              )}
            </Stack>
          </Stack>
        </div>
      )}

      <Stack
        spacing={2}
        direction="row"
        sx={{
          height: 'calc(100vh - 176px)',
          position: 'relative',
        }}
      >
        {featureFlags.org_lists && showOrgTree && (
          <Card
            elevation={0}
            sx={{
              width: 350,
              overflow: 'auto',
              p: 2,
              pl: 0,
              flexShrink: 0,
            }}
          >
            <Typography
              variant="body2"
              sx={{ pl: 3, pb: 1 }}
              color="text.secondary"
            >
              Organizations
            </Typography>
            {isFetchingAll ? (
              <OrgTreeSkeleton />
            ) : (
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                expanded={expanded}
                selected={selectedOrg?.id || rootOrganization.id}
                onNodeSelect={(
                  _e: SyntheticEvent<Element, Event>,
                  id: string,
                ) => {
                  setSelectedOrg(
                    organizations.find((org) => org.id === id) || null,
                  )
                }}
                onNodeToggle={(_e, ids) => {
                  setExpanded(ids)
                }}
              >
                <OrganizationTreeItem
                  orgTree={organizationMap[rootOrganization.id]}
                />
              </TreeView>
            )}
          </Card>
        )}

        {parentDataList && dataList && (
          <>
            {isInheritingFromParent ? (
              <ListIsInheritingMessage
                parentOrg={
                  organizations.find(
                    ({ id }) => id === dataList.org_id,
                  ) as Organization
                }
                dataList={dataList}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                detailQueryKey={detailQueryKey}
                isCustomList={isCustomList}
              />
            ) : (
              <DataListData
                parentDataList={parentDataList}
                dataList={dataList}
                showOrgTree={showOrgTree}
                setShowOrgTree={setShowOrgTree}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  )
}
