import { Box, useTheme } from '@mui/material'
import { useEffect, useRef } from 'react'

const GRID_SIZE = 6 // size of each square including space
const WAVE_SIZE = 300
const SQUARE_SIZE = 2
const CANVAS_HEIGHT_MULTIPLIER = 2.5

type PixelGridBackground = {
  animateBackground?: boolean
  containerRef?: React.RefObject<HTMLDivElement>
  zIndex?: number
}

export default function PixelGridBackground({
  animateBackground,
  containerRef,
  zIndex = -1,
}: PixelGridBackground) {
  const theme = useTheme()
  const wavePositionRef = useRef<number>(0)
  const animatingRef = useRef<boolean>(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    // This isn't completely reliable for some reason. Sometimes doesn't work, but better than doing
    // a complex solution for now.
    if (containerRef?.current) return
    window.onresize = () => {
      const canvas = canvasRef.current
      if (!canvas) return
      canvas.width =
        (containerRef?.current?.clientWidth || window.innerWidth) + WAVE_SIZE
      canvas.height =
        (containerRef?.current?.clientHeight || window.innerHeight) *
        CANVAS_HEIGHT_MULTIPLIER
      wavePositionRef.current = 0
    }
    return
  }, [containerRef])

  useEffect(() => {
    // This value gets replicated to a global let so that it can be updated in the animation loop
    // otherwise it would be a stale closure value. Neat. An alternative would be using a ref.
    animatingRef.current = !!animateBackground

    const canvas = canvasRef.current
    if (!canvas) return
    const ctx = canvas.getContext('2d')

    canvas.width =
      (containerRef?.current?.clientWidth || window.innerWidth) + WAVE_SIZE
    canvas.height = Math.max(
      (containerRef?.current?.clientHeight || window.innerHeight) *
        CANVAS_HEIGHT_MULTIPLIER,
      WAVE_SIZE * 3,
    )

    const numCols = Math.floor(canvas.width / GRID_SIZE)
    const numRows = Math.floor(canvas.height / GRID_SIZE)

    // Function to draw the grid of squares with space
    function drawGrid() {
      if (!ctx || !canvas) return
      ctx.clearRect(0, 0, canvas.width, canvas.height) // clear canvas
      for (let column = 0; column < numCols; column++) {
        for (let row = 0; row < numRows; row++) {
          // If Y is within WAVE_SIZE in either direction, draw a blue square
          if (
            row * GRID_SIZE < wavePositionRef.current + WAVE_SIZE + 150 &&
            row * GRID_SIZE > wavePositionRef.current - WAVE_SIZE
          ) {
            const distance = Math.abs(row * GRID_SIZE - wavePositionRef.current)
            const opacity = 1 - distance / WAVE_SIZE
            const x = column * GRID_SIZE
            const y = row * GRID_SIZE - x / 2
            ctx.fillStyle =
              theme.palette.mode === 'dark'
                ? `rgba(41, 98, 255, ${opacity})`
                : `rgba(130, 177, 255, ${opacity / 1.75})`
            ctx.fillRect(x + 1, y + 1, SQUARE_SIZE, SQUARE_SIZE)
          }
        }
      }
    }

    function animate() {
      if (!canvas) return
      // Update Wave Position
      if (wavePositionRef.current > canvas?.height) {
        wavePositionRef.current = 0
        if (!animatingRef.current) return
      } else {
        wavePositionRef.current = wavePositionRef.current + 6
      }

      if (animatingRef.current) {
        drawGrid()
        requestAnimationFrame(animate)
      }
    }

    // Start the animation loop
    if (animatingRef.current) {
      requestAnimationFrame(animate)
    }
    return () => {
      animatingRef.current = false
    }
  }, [animateBackground, containerRef, theme.palette.mode])

  return (
    <Box>
      <canvas
        ref={canvasRef}
        id="canvas"
        style={{
          position: 'absolute',
          top: -WAVE_SIZE,
          left: 0,
          zIndex,
        }}
      ></canvas>
    </Box>
  )
}
