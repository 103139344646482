import { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Link as MUILink,
  Container,
  Button,
  Stack,
  TextField,
  Skeleton,
  Switch,
  FormControlLabel,
  Typography,
} from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import DeleteProjectDialog from './DeleteProjectDialog'
import LargeHeading from '@/components/large-heading/LargeHeading'
import { Delete } from '@mui/icons-material'
import LabeledData from '@/components/labeled-data/LabeledData'
import ProjectCategoryAutocomplete from '@/components/projects/ProjectCategoryAutocomplete'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { ProjectCategory } from '@/types/projects'
import debounce from 'lodash.debounce'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { useGetOrganization } from '@/service-library/hooks/organizations'
import {
  useDeleteProject,
  useGetProjects,
  useUpdateProject,
} from '@/service-library/hooks/projects'
import queryKeys from '@/service-library/query-keys'

export default function ProjectSettings() {
  const { project, queryKey } = useProjectContext()
  const { projects } = useGetProjects({
    refetchOnWindowFocus: false,
    filters: {
      org_id: project.org_id,
      fields__only: 'id,name',
      limit: '1000',
    },
  })

  const { organization, isLoading: isLoadingOrg } = useGetOrganization({
    id: project.org_id,
  })

  const deleteOverlay = useOverlay()

  const navigate = useNavigate()

  const { updateProject } = useUpdateProject({
    detailQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to update project. Please try again later.')
    },
  })

  const { deleteProject } = useDeleteProject({
    sideEffectQueryKeys: [queryKeys.projects.lists()],
    onSuccess: () => {
      showSuccessSnackbar('Project Deleted')
      navigate('/projects')
    },
    onError: () => {
      showErrorSnackbar('Unable to delete project. Please try again later.')
    },
  })

  const onDeleteProject = () => {
    deleteOverlay.close()
    deleteProject(project.id)
  }

  const debouncedUpdateProject = useMemo(
    () => debounce(updateProject, 250),
    [updateProject],
  )

  const [internalName, setInternalName] = useState<string>(project.name)
  const [internalCategory, setInternalCategory] = useState(
    project.project_category as ProjectCategory,
  )

  const [nameError, setNameError] = useState<string | null>(null)

  function validate(nameValue: string) {
    if (!nameValue.trim()) {
      return 'Project name cannot be empty.'
    } else if (
      projects?.some(
        ({ id, name }) =>
          name.toLowerCase() === nameValue.toLowerCase().trim() &&
          id !== project.id,
      )
    ) {
      return 'This name is already taken.'
    }
    return null
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    const nameError = validate(value)

    setInternalName(value)
    setNameError(nameError)
    if (nameError) debouncedUpdateProject.cancel()
    if (value && !nameError) {
      debouncedUpdateProject({ ...project, name: value })
    }
  }

  function handleNameBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { value } = event.target
    const nameError = validate(value)
    if (nameError) {
      setInternalName(project.name)
      setNameError(null)
    }
  }

  function handleProcessingActiveChange(checked: boolean) {
    debouncedUpdateProject({ ...project, processing_active: checked })
  }

  function handleCategoryChange(category: ProjectCategory) {
    setInternalCategory(category)
    updateProject(
      {
        ...project,
        project_category_id: category.id,
      },
      {
        onError: () =>
          setInternalCategory(project.project_category as ProjectCategory),
      },
    )
  }

  return (
    <Container maxWidth="md" sx={{ height: '100%', py: 1 }}>
      <Breadcrumb label="General" url="../general" />

      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack spacing={4}>
          <LargeHeading
            heading={project.name}
            subHeading="Project Settings"
            actions={
              <>
                <Button
                  variant="text"
                  startIcon={<Delete />}
                  color="error"
                  onClick={() => deleteOverlay.open()}
                >
                  Delete
                </Button>
                <CopyIDButton stringToCopy={project.id} />
                <DeleteProjectDialog
                  name={project.name}
                  onDelete={onDeleteProject}
                  overlay={deleteOverlay}
                />
              </>
            }
          />
          <TextField
            sx={{ maxWidth: 350 }}
            label="Project Name"
            value={internalName}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            error={!!nameError}
            helperText={nameError}
          />
          <ProjectCategoryAutocomplete
            selected={internalCategory}
            onCategoryChange={handleCategoryChange}
            sx={{ maxWidth: 350 }}
          />
          {/* Wrapping div to keep this from expanding to full width of page */}
          <div>
            <FormControlLabel
              control={
                <Switch checked={project.processing_active} sx={{ mr: 1 }} />
              }
              label={
                <>
                  <Typography>Processing Active</Typography>
                  <Typography variant="caption">
                    Process documents as soon as they are uploaded to the
                    project.
                  </Typography>
                </>
              }
              onChange={(event, checked) =>
                handleProcessingActiveChange(checked)
              }
            />
          </div>
          {/* Wrapping div to keep this from expanding to full width of page */}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={project.flag_duplicate_documents}
                  sx={{ mr: 1 }}
                />
              }
              label={
                <>
                  <Typography>Flag Duplicate Documents</Typography>
                  <Typography variant="caption">
                    Flag the document if the exact same file is uploaded more
                    than once.
                  </Typography>
                </>
              }
              onChange={(event, checked) =>
                debouncedUpdateProject({
                  ...project,
                  flag_duplicate_documents: checked,
                })
              }
            />
          </div>
          {/* Wrapping div to keep this from expanding to full width of page */}
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={project.documents_contain_pii}
                  sx={{ mr: 1 }}
                />
              }
              label={
                <>
                  <Typography>
                    Documents Contain Personally Identifiable Information (PII)
                  </Typography>
                  <Typography variant="caption">
                    Documents uploaded to this project may contain information
                    that can be used to identify an individual.
                  </Typography>
                </>
              }
              onChange={(event, checked) =>
                debouncedUpdateProject({
                  ...project,
                  documents_contain_pii: checked,
                })
              }
            />
          </div>
          {project.org_id && (
            <LabeledData
              label="Parent Organization"
              data={
                <>
                  {isLoadingOrg ? (
                    <Skeleton width={125} />
                  ) : (
                    <MUILink
                      component={Link}
                      underline="hover"
                      to={`/settings/organizations/${organization?.id}`}
                    >
                      {organization?.name}
                    </MUILink>
                  )}
                </>
              }
            />
          )}
        </Stack>
      </Stack>
    </Container>
  )
}
