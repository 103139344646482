import useGetAllDocuments from '@/services/hooks/useGetAllDocuments'
import {
  constructFieldFilters,
  constructFieldOrdering,
  UseDocumentGridOptions,
} from './useDocumentGrid'

type UseDocumentDashboardIdsOptions = Omit<
  UseDocumentGridOptions,
  'projectGridIds' | 'limit' | 'queryBy'
> & {
  enabled: boolean
}

export default function useDocumentDashboardIds({
  projectId,
  refetchInterval,
  workflowStateId,
  flagCodes,
  filtering,
  ordering,
  orgIds,
  teamIds,
  enabled,
}: UseDocumentDashboardIdsOptions) {
  const query = useGetAllDocuments({
    filters: {
      project_id: projectId,
      document_workflow_states__workflow_state_id: workflowStateId,
      ...constructFieldFilters('document', filtering),
      ordering: constructFieldOrdering('document', ordering),
      owner_org_id__in: orgIds ? orgIds.join() : undefined,
      team_id__in: teamIds?.join(),
      fields__only: 'id',
      any_flag__flag_type__code__in: flagCodes?.join(),
      limit: '200',
    },
    refetchInterval,
    enabled,
  })

  return query
}
