import { Paper, Popover, Stack } from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import OrganizationsFilter from '@/components/filters/OrganizationsFilter'
import StateFilter from '@/components/filters/StateFilter'
import TeamsFilter from '@/components/filters/TeamsFilter'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useSelectedWorkflowStatesContext } from '@/components/workflows/SelectedWorkflowStatesProvider'
import ValidationDrawerSortBy from './ValidationDrawerSortBy'

type ValidationDrawerFilterPopoverProps = {
  overlay: OverlayState
  anchorEl: HTMLButtonElement | null
}

export default function ValidationDrawerFilterPopover({
  overlay,
  anchorEl,
}: ValidationDrawerFilterPopoverProps) {
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { selectedWorkflowStates, toggleWorkflowState } =
    useSelectedWorkflowStatesContext()

  const workflowStates = selectedWorkflow.workflow_states ?? []

  return (
    <Popover
      open={overlay.isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={overlay.close}
    >
      <Paper elevation={4} sx={{ p: 2, width: 300 }}>
        <Stack spacing={2}>
          <StateFilter
            workflowStates={workflowStates}
            visibleStateIds={selectedWorkflowStates.map(({ id }) => id)}
            toggleState={toggleWorkflowState}
            showCount
          />
          <TeamsFilter />
          <OrganizationsFilter />
          <ValidationDrawerSortBy />
        </Stack>
      </Paper>
    </Popover>
  )
}
