import { ExpandMore, ChevronRight } from '@mui/icons-material'
import { TreeView } from '@mui/x-tree-view'
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useMemo,
  useState,
} from 'react'
import OrganizationTreeItem from '../organizations-tree/OrganizationTreeItem'
import { createOrganizationMap } from '@/utils/organization'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { Organization } from '@/types/organizations'
import { Box } from '@mui/material'
import { useOrganizationsContext } from '../organizations/OrganizationsProvider'

type ModelTreeViewProps = {
  selectedOrg: Organization
  setSelectedOrg: Dispatch<SetStateAction<Organization>>
}

export default function ModelTreeView({
  selectedOrg,
  setSelectedOrg,
}: ModelTreeViewProps) {
  const { rootOrganization } = useRootOrganization()
  const { organizations } = useOrganizationsContext()
  const [expanded, setExpanded] = useState([rootOrganization.id])

  const organizationMap = useMemo(
    () => createOrganizationMap(organizations),
    [organizations],
  )

  return (
    <Box
      sx={{ ml: -1, mt: 0.5, width: 250, height: '100%', overflowY: 'auto' }}
    >
      {selectedOrg && (
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          selected={selectedOrg?.id}
          onNodeSelect={(_e: SyntheticEvent<Element, Event>, id: string) => {
            setSelectedOrg(
              organizations.find((org) => org.id === id) as Organization,
            )
          }}
          onNodeToggle={(_e, ids) => {
            setExpanded(ids)
          }}
        >
          <OrganizationTreeItem
            orgTree={organizationMap[rootOrganization.id]}
          />
        </TreeView>
      )}
    </Box>
  )
}
