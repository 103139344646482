import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import DocumentActionsMenu from '@/components/document-actions-menu/DocumentActionsMenu'
import { OverlayState } from '@/hooks/useOverlay'
import { SyntheticEvent } from 'react'
import { Subject as SubjectIcon } from '@mui/icons-material'

type DocumentGridRowMenuProps = {
  overlay: OverlayState
  documentId: string
  projectId: string
  onOpenDocumentDialog: () => void
  position: { mouseX: number; mouseY: number } | null
  copyQuery?: string
  onDelete?: () => void
  onReprocess?: () => void
}

export default function DocumentGridRowMenu({
  overlay,
  documentId,
  projectId,
  position,
  onOpenDocumentDialog,
  copyQuery,
  onDelete,
}: DocumentGridRowMenuProps) {
  return position ? (
    <DocumentActionsMenu
      overlay={overlay}
      documentId={documentId}
      projectId={projectId}
      copyQuery={copyQuery}
      onClick={(event: SyntheticEvent) => {
        // This prevent the event from bubbling up to the table row click handler
        event.stopPropagation()
      }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: position.mouseY, left: position.mouseX }}
      onDelete={onDelete}
    >
      <MenuItem
        onClick={() => {
          onOpenDocumentDialog()
          overlay.close()
        }}
      >
        <ListItemIcon>
          <SubjectIcon />
        </ListItemIcon>
        <ListItemText>Details</ListItemText>
      </MenuItem>
    </DocumentActionsMenu>
  ) : null
}
