import { useRef } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ButtonProps,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import useOverlay from '@/hooks/useOverlay'
import { altHotKeyStr } from '@/utils/get-os'
import KeyboardShortcutsTooltip from '@/components/keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'

type EventButtonProps = {
  documentsIds: string[]
  documentsHaveErrors: boolean
  workflowStateEvents: WorkflowStateEvent[]
  onTriggerEvent: (event: WorkflowStateEvent) => void
  disabled?: boolean
  isLoading?: boolean
  inDashboard?: boolean
  tooltip?: string
  variant?: ButtonProps['variant']
}

export default function EventButton({
  documentsIds,
  documentsHaveErrors,
  workflowStateEvents,
  disabled,
  isLoading,
  inDashboard,
  variant = 'contained',
  onTriggerEvent,
}: EventButtonProps) {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLDivElement>(null)

  const primaryEvent = workflowStateEvents[0]
  const primaryEventIsDisabled =
    documentsHaveErrors &&
    primaryEvent.when_document_has_errors === 'do_not_trigger'
  const documentsWithErrorsTooltip = inDashboard
    ? 'This cannot be done while any of the selected documents have errors.'
    : 'This cannot be done while this document has errors.'
  return (
    <>
      <KeyboardShortcutsTooltip
        title={
          primaryEventIsDisabled
            ? documentsWithErrorsTooltip
            : primaryEvent.name
        }
        enterDelay={1000}
        disableInteractive
        keyboardShortcuts={
          primaryEventIsDisabled || inDashboard
            ? undefined
            : [`${altHotKeyStr} + D`]
        }
        arrow
      >
        <ButtonGroup ref={anchorRef} variant={variant}>
          <Button
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
            disabled={disabled || primaryEventIsDisabled || isLoading}
            onClick={() => {
              onTriggerEvent(primaryEvent)
            }}
          >
            {primaryEvent.name}
            {inDashboard ? ` (${documentsIds.length})` : ''}
          </Button>

          {workflowStateEvents.length > 1 && (
            <Button
              onClick={overlay.toggle}
              size="small"
              disabled={disabled || isLoading}
            >
              <ArrowDropDown />
            </Button>
          )}
        </ButtonGroup>
      </KeyboardShortcutsTooltip>
      <Menu
        anchorEl={anchorRef.current}
        open={overlay.isOpen}
        onClose={overlay.close}
      >
        {workflowStateEvents.map((event) => {
          const isDisabled =
            documentsHaveErrors &&
            event.when_document_has_errors === 'do_not_trigger'
          return (
            <Tooltip
              key={event.id}
              disableInteractive
              enterDelay={1000}
              title={isDisabled ? documentsWithErrorsTooltip : ''}
            >
              <span>
                <MenuItem
                  disabled={isDisabled}
                  onClick={() => {
                    onTriggerEvent(event)
                    overlay.close()
                  }}
                >
                  {event.name}
                </MenuItem>
              </span>
            </Tooltip>
          )
        })}
      </Menu>
    </>
  )
}
