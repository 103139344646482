import { ReactNode } from 'react'
import { Box, SxProps } from '@mui/material'

type LeftBorderedBoxProps = {
  children: ReactNode
  color: string
  sx?: SxProps
}

export default function LeftBorderedBox({
  children,
  color,
  sx,
}: LeftBorderedBoxProps) {
  return (
    <Box
      sx={{
        width: '100%',
        '&::before': {
          content: '""',
          transition: 'all 0.1s',
          width: 4,
          background: color,
          display: 'inline-block',
          height: '90%',
          position: 'absolute',
          top: '5%',
          left: 0,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
