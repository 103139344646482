import axios from './auth-v2-axios'
import { Document, DocumentFilteringOption } from '@/types/documents'
import PaginatedResponse from '@/types/paginated-response'

export const getMetadataServiceId = (
  endpoint: 'document' | 'row',
  columnId: string,
) => {
  const prefix = endpoint === 'document' ? '' : 'document__'

  const mapping = {
    fileColId: `${prefix}name`,
    organizationColId: `${prefix}owner_org__name`,
    submitterColId: `${prefix}created_by_user__name`,
    pageCountColId: `${prefix}page_count`,
    uploadColId: `${prefix}created_at`,
    processedColId: `${prefix}pre_process_completed_at`,
  }

  return mapping[columnId as keyof typeof mapping]
}

type GetDocumentsOptions = {
  paginator?: 'cursor' | 'page'
  projectId?: string
  includeDocumentRows?: boolean
  includeWorkflowStates?: boolean
  workflowStateIds?: string[]
  orgIds?: string[] | false
  searchValue?: string
  limit?: string
  cursor?: string
  page?: string
  flagCodes?: string[]
  fieldsOnly?: string
  documentIds?: string[]
  includeFlags?: boolean
  filtering?: DocumentFilteringOption[]
  ordering?: string[]
  teamIds?: string[]
}

export function getDocuments({
  projectId,
  includeDocumentRows,
  includeWorkflowStates,
  workflowStateIds = [],
  orgIds,
  searchValue,
  paginator = 'cursor',
  limit = '40',
  cursor,
  page,
  flagCodes = [],
  fieldsOnly,
  documentIds,
  includeFlags,
  filtering = [],
  ordering = [],
  teamIds = [],
}: GetDocumentsOptions) {
  const searchParams = new URLSearchParams({
    limit,
    workflow_states__isnull: 'false',
    paginator,
  } as Record<string, string>)

  if (projectId) {
    searchParams.append('project_id', projectId)
  }

  const fieldsToInclude = []
  if (includeDocumentRows) {
    fieldsToInclude.push('document_rows')
    searchParams.append('document_rows__fields__include', 'document_row_values')
    searchParams.append(
      'document_rows__document_row_values__fields__include',
      'project_content_category_item',
    )
  }
  if (includeFlags) {
    fieldsToInclude.push('document_flags')
    searchParams.append('document_flags__fields__include', 'flag_type')
  }
  if (includeWorkflowStates) {
    fieldsToInclude.push('workflow_states_ids')
  }
  if (fieldsToInclude.length > 0) {
    searchParams.append('fields__include', fieldsToInclude.join(','))
  }
  if (workflowStateIds.length > 0) {
    searchParams.append(
      'document_workflow_states__workflow_state_id__in',
      workflowStateIds.join(','),
    )
  }
  if (orgIds && orgIds.length > 0) {
    searchParams.append('owner_org_id__in', orgIds.join(','))
  }
  if (searchValue) {
    searchParams.append('search', searchValue)
    searchParams.append('ordering', 'search__rank')
  }
  if (cursor) {
    searchParams.append('cursor', cursor)
  }
  if (page) {
    searchParams.append('page', page)
  }
  if (flagCodes.length > 0) {
    searchParams.append('any_flag__flag_type__code__in', flagCodes.join())
  }
  if (teamIds.length > 0) {
    searchParams.append('team_id__in', teamIds.join(','))
  }
  if (fieldsOnly) {
    searchParams.append('fields__only', fieldsOnly)
  }
  if (documentIds) {
    searchParams.append('id__in', documentIds.join(','))
  }
  if (filtering.length > 0) {
    filtering.forEach(({ filterId, value }) => {
      const metadataColumn = filterId.match(/.*ColId/)
      let filter = filterId
      if (metadataColumn) {
        const endpointId = getMetadataServiceId('document', metadataColumn[0])
        // filterIds include filter mode suffixes, so we append them after getting the endpointId
        filter = `${endpointId}${filterId.substring(metadataColumn[0].length)}`
      }
      searchParams.append(filter, value)
    })
  }
  if (ordering.length > 0) {
    const updatedOrdering = ordering.map((colId) => {
      const isMetadataColumn = colId.endsWith('ColId')
      if (isMetadataColumn) {
        const metadataColId = colId[0] === '-' ? colId.substring(1) : colId
        const endpointId = getMetadataServiceId('document', metadataColId)
        return colId[0] === '-' ? `-${endpointId}` : endpointId
      }
      return colId
    })
    searchParams.append('ordering', updatedOrdering.join(','))
  }
  return axios.get<PaginatedResponse<Document>>(
    `/v2/pd/documents?${searchParams.toString()}`,
  )
}

export function getDocumentOriginalFileData({
  documentId,
}: {
  documentId: string
}) {
  return axios.get<Pick<Document, 'file_url_get' | 'file_ext' | 'name'>>(
    `/v2/pd/documents/${documentId}?fields__only=file_url_get,file_ext,name`,
  )
}
