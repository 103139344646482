import {
  Button,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import { useForm } from 'react-hook-form'
import { FormCheckbox, FormTextField, PixyDocsForm } from '@/components/forms'
import { sortBySortOrder } from '@/utils/field-utils'
import generateUuid from '@/utils/generate-uuid'
import { WorkflowState } from '@/types/workflows'
import { useEffect, useMemo } from 'react'
import FormRadioGroup from '@/components/forms/FormRadioGroup'
import {
  useCreateWorkflowStateEvent,
  useUpdateWorkflowStateEvent,
} from '@/service-library/hooks/workflow-state-events'
import { QueryKey } from '@tanstack/react-query'

export type FormValues = {
  name: string
  move_to_next_doc_in_validation: WorkflowStateEvent['move_to_next_doc_in_validation']
  when_document_has_errors: WorkflowStateEvent['when_document_has_errors']
} & Record<string, string>

type AddEditWorkflowStateEventDialogProps = {
  overlay: OverlayState
  workflowState: WorkflowState
  workflowStateEvent?: WorkflowStateEvent
  listQueryKey: QueryKey
  onSave?: () => void
}

export default function AddEditWorkflowStateEventDialog({
  overlay,
  workflowState,
  workflowStateEvent,
  listQueryKey,
  onSave,
}: AddEditWorkflowStateEventDialogProps) {
  const { createWorkflowStateEvent } = useCreateWorkflowStateEvent({
    listQueryKey,
  })
  const { updateWorkflowStateEvent } = useUpdateWorkflowStateEvent({
    listQueryKey,
  })

  const savedActions = useMemo(() => {
    return sortBySortOrder([...(workflowStateEvent?.actions || [])])
  }, [workflowStateEvent?.actions])

  const defaultValues = useMemo(() => {
    return {
      name: workflowStateEvent?.name || '',
      move_to_next_doc_in_validation: workflowStateEvent
        ? workflowStateEvent?.move_to_next_doc_in_validation
        : true,
      when_document_has_errors:
        workflowStateEvent?.when_document_has_errors || 'trigger_with_warning',
    } as FormValues
  }, [workflowStateEvent])

  const methods = useForm({
    defaultValues,
  })
  const { formState } = methods

  useEffect(() => {
    methods.reset(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We want it to reset to the defaultValues, but not when they change
  }, [methods, workflowStateEvent, savedActions])

  function onSubmit(values: FormValues) {
    const eventId = workflowStateEvent?.id || generateUuid()

    if (!workflowStateEvent) {
      createWorkflowStateEvent({
        id: eventId,
        workflow_state_id: workflowState.id,
        name: values.name,
        actions: [],
        sort_order: 100000,
        code: 'manual',
        move_to_next_doc_in_validation: values.move_to_next_doc_in_validation,
        when_document_has_errors: values.when_document_has_errors,
      })
    } else {
      updateWorkflowStateEvent({
        ...workflowStateEvent,
        name: values.name,
        move_to_next_doc_in_validation: values.move_to_next_doc_in_validation,
        when_document_has_errors: values.when_document_has_errors,
      })
    }
    overlay.close()
    methods.reset()
    onSave?.()
  }

  const isSystemManaged =
    workflowStateEvent?.code === 'on_enter' ||
    workflowStateEvent?.code === 'on_exit'

  return (
    <Dialog
      {...overlay}
      title={workflowStateEvent ? 'Edit Event' : 'Add Event'}
      maxWidth="xs"
    >
      <DialogContent>
        <PixyDocsForm
          id="state-event-form"
          methods={methods}
          onSubmit={onSubmit}
        >
          <Stack spacing={2}>
            <FormTextField
              label="Name"
              required
              autoFocus
              disabled={
                workflowStateEvent
                  ? workflowStateEvent.code === 'on_enter' ||
                    workflowStateEvent.code === 'on_exit'
                  : false
              }
              {...methods.register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />

            {!isSystemManaged && (
              <>
                <FormCheckbox
                  sx={{ ml: '-12px' }}
                  name="move_to_next_doc_in_validation"
                  label={
                    <Stack>
                      <Typography>
                        Move to Next Document in Validation Page
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        When on the validation page, automatically go to the
                        next document in the list when this event is triggered.
                      </Typography>
                    </Stack>
                  }
                />

                <FormRadioGroup
                  name="when_document_has_errors"
                  label="If there are error flags when event is triggered"
                >
                  <FormControlLabel
                    value="do_not_trigger"
                    control={<Radio size="small" />}
                    label="Don't allow to continue."
                  />
                  <FormControlLabel
                    value="trigger_with_warning"
                    control={<Radio size="small" />}
                    label="Allow to continue with warning."
                  />
                  <FormControlLabel
                    value="trigger"
                    control={<Radio size="small" />}
                    label="Allow to continue without warning."
                  />
                </FormRadioGroup>
              </>
            )}
          </Stack>
        </PixyDocsForm>
      </DialogContent>

      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          form="state-event-form"
          type="submit"
          disabled={!formState.isValid}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
