import { ProjectDocumentRule, ProjectDocumentRuleType } from '@/types/rules'
import { OverlayState } from '@/hooks/useOverlay'
import { useGetProjectDocumentRuleTypes } from '@/service-library/hooks/project-document-rule-types'
import generateUuid from '@/utils/generate-uuid'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import AddRuleDialog, { FormValues } from '@/components/rules/AddRuleDialog'

type AddDocumentRuleDialogProps = {
  overlay: OverlayState
  onSubmit: (rule: ProjectDocumentRule) => void
}

export default function AddDocumentRuleDialog({
  overlay,
  onSubmit,
}: AddDocumentRuleDialogProps) {
  const { project } = useProjectContext()
  const { projectDocumentRuleTypes, isLoading } =
    useGetProjectDocumentRuleTypes({
      filters: { limit: '1000' },
      refetchOnWindowFocus: false,
      enabled: overlay.isOpen,
    })

  const handleCreateDocumentRule = ({
    type,
    workflow_states_ids,
    ...rest
  }: FormValues<ProjectDocumentRuleType>) => {
    const cleanedParams: ProjectDocumentRule['params'] = rest || {}
    delete cleanedParams.dependent_fields
    delete cleanedParams.is_superuser_rule

    onSubmit({
      project_id: project.id,
      id: generateUuid(),
      sort_order: 1000001,
      type_id: type.id,
      type,
      params: cleanedParams,
      workflow_states_ids,
      is_enabled: true,
    })
  }

  return (
    <AddRuleDialog
      isLoading={isLoading}
      overlay={overlay}
      ruleTypes={projectDocumentRuleTypes}
      onSubmit={handleCreateDocumentRule}
    />
  )
}
