import { Link } from 'react-router-dom'
import { Help } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import IssueDialog from './IssueDialog'

export default function HelpMenu() {
  const overlay = useOverlay()
  const issueOverlay = useOverlay()

  return (
    <div>
      <IconButton onClick={overlay.toggle} size="small">
        <Help />
      </IconButton>
      <Menu
        id="help-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
      >
        <MenuItem component={Link} to="agreements" onClick={overlay.close}>
          Agreements
        </MenuItem>
        <MenuItem
          component={Link}
          to="open-source-licenses"
          onClick={overlay.close}
        >
          Open Source Licenses
        </MenuItem>
        <MenuItem component={Link} to="terms-of-use" onClick={overlay.close}>
          Terms of Use
        </MenuItem>
        <MenuItem
          onClick={() => {
            issueOverlay.open()
            overlay.close()
          }}
        >
          Report an Issue
        </MenuItem>
      </Menu>
      <IssueDialog overlay={issueOverlay} />
    </div>
  )
}
