import { IconButton } from '@mui/material'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

type NavigationRailItemProps = {
  to: string
  children: ReactNode
  selected: boolean
}

export default function NavigationRailItem({
  to,
  children,
  selected,
}: NavigationRailItemProps) {
  return (
    <IconButton
      component={Link}
      size="small"
      to={to}
      color={selected ? 'primary' : undefined}
    >
      {children}
    </IconButton>
  )
}
