import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetDocument } from '@/service-library/hooks/documents'
import DocumentImagePageProvider from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import DocumentProvider from '@/components/image-zoom-pan/providers/DocumentProvider'
import DocumentChipsProvider from './providers/DocumentChipsProvider'
import DocumentRowValuesProvider from './providers/DocumentRowValuesProvider'
import FieldErrorProvider from './providers/FieldErrorProvider'
import SelectedFieldProvider from './providers/SelectedFieldProvider'
import ValidationDrawer from './ValidationDrawer'
import useOverlay from '@/hooks/useOverlay'
import { Typography, CircularProgress } from '@mui/material'
import SelectedWorkflowProvider from '@/components/workflows/SelectedWorkflowProvider'
import SelectedWorkflowStatesProvider from '@/components/workflows/SelectedWorkflowStatesProvider'
import DisabledDocumentsProvider from './providers/DisabledDocumentsProvider'
import DocumentListProvider, {
  useDocumentListContext,
} from './DocumentListProvider'
import Validation from './Validation'
import ValidationOptionsProvider from '../image-zoom-pan/providers/ValidationOptionsProvider'
import useGetValidationDocument from '@/services/hooks/useGetValidationDocument'
import { useNotifications } from '../notifications/NotificationProvider'
import { useQueryClient } from '@tanstack/react-query'
import queryKeys from '@/service-library/query-keys'

type ValidationPageDisplayProps = {
  documentQuery: ReturnType<typeof useGetDocument>
}

function ValidationPageDisplay({ documentQuery }: ValidationPageDisplayProps) {
  const { documentId } = useParams()

  const { isInitialLoading: isLoadingList } = useDocumentListContext()

  const validationDrawer = useOverlay({
    initiallyOpen: true,
    localStorageKey: 'validation-drawer-is-open',
  })

  const isLoading = isLoadingList || (documentId && documentQuery.isLoading)

  return (
    <>
      <ValidationDrawer
        overlay={validationDrawer}
        documentId={documentId as string}
        fullDocument={documentQuery.document}
      />

      {isLoading && (
        <Typography align="center" p={8}>
          <CircularProgress />
        </Typography>
      )}

      {!isLoading &&
        (!documentId ? (
          <Typography align="center" p={8} color="text.secondary">
            <i>No Document Selected</i>
          </Typography>
        ) : (
          <Validation
            validationDrawer={validationDrawer}
            documentQuery={documentQuery}
          />
        ))}
    </>
  )
}

export default function ValidationPage() {
  const { documentId, projectId } = useParams()
  const navigate = useNavigate()
  const query = useGetValidationDocument({
    id: documentId || '',
  })

  const queryClient = useQueryClient()
  useNotifications({
    keys: [
      'pdWorkflow/events.event_complete',
      'pdWorkflow/events.event_failed',
    ],
    callback: ({ updated_entity_ids }) => {
      if (documentId && updated_entity_ids.includes(documentId)) {
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documents.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRows.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentRowValues.all },
          { cancelRefetch: false },
        )
        queryClient.invalidateQueries(
          { queryKey: queryKeys.documentChips.all },
          { cancelRefetch: false },
        )
      }
    },
  })

  // If the project ID on the document doesn't match the project ID in the url, update the url.
  useEffect(() => {
    const { project_id } = query.document ?? {}
    if (documentId && project_id && project_id !== projectId) {
      navigate(`/projects/${project_id}/validation/${documentId}`, {
        replace: true,
      })
    }
  }, [documentId, navigate, projectId, query.document])

  return (
    <DisabledDocumentsProvider>
      <SelectedWorkflowProvider>
        <SelectedWorkflowStatesProvider>
          <DocumentListProvider>
            <DocumentProvider query={query}>
              <DocumentRowValuesProvider projectId={projectId as string}>
                <FieldErrorProvider>
                  <DocumentChipsProvider>
                    <SelectedFieldProvider>
                      <ValidationOptionsProvider document={query.document}>
                        <DocumentImagePageProvider document={query.document}>
                          <ValidationPageDisplay documentQuery={query} />
                        </DocumentImagePageProvider>
                      </ValidationOptionsProvider>
                    </SelectedFieldProvider>
                  </DocumentChipsProvider>
                </FieldErrorProvider>
              </DocumentRowValuesProvider>
            </DocumentProvider>
          </DocumentListProvider>
        </SelectedWorkflowStatesProvider>
      </SelectedWorkflowProvider>
    </DisabledDocumentsProvider>
  )
}
