import { OverlayState } from '@/hooks/useOverlay'
import KeyboardShortcutsTable from './KeyboardShortcutsTable'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import { Button, Stack, Typography } from '@mui/material'

type KeyboardShortcutGroup = {
  title: string
  shortcuts: Record<string, string>
}

type KeyboardShortcutsDialogProps = {
  overlay: OverlayState
  keyboardShortcutGroups: KeyboardShortcutGroup[]
}

export default function KeyboardShortcutsDialog({
  overlay,
  keyboardShortcutGroups,
}: KeyboardShortcutsDialogProps) {
  return (
    <Dialog title="Keyboard Shortcuts" {...overlay} maxWidth="xs">
      <DialogContent>
        <Stack spacing={3}>
          {keyboardShortcutGroups.map(({ title, shortcuts }) => {
            return (
              <Stack spacing={1} key={title}>
                <Typography variant="body1" color="secondary">
                  {title}
                </Typography>
                <KeyboardShortcutsTable keyboardShortcutsMap={shortcuts} />
              </Stack>
            )
          })}
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button onClick={overlay.close} variant="text">
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
