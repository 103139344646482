import { ListItemButton, Typography, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'

type TopBarNavigationItemProps = {
  label: string
  to: string
  isSuperUserOnly?: boolean
  icon: ReactNode
  selected: boolean
}

export default function HorizontalTab({
  label,
  to,
  isSuperUserOnly,
  icon,
  selected,
}: TopBarNavigationItemProps) {
  let color = isSuperUserOnly ? SUPER_USER_ONLY_COLOR : 'text.secondary'
  if (selected && !isSuperUserOnly) color = 'primary.main'

  return (
    <ListItemButton
      component={Link}
      to={to}
      sx={{
        borderRadius: 2,
        py: 0.5,
        px: 1,
        color,
        textDecoration: 'none',
        '&:visited': {
          textDecoration: 'none',
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon}
        <Typography variant="body2">{label}</Typography>
      </Stack>
    </ListItemButton>
  )
}
