import { WorkflowState } from '@/types/workflows'
import SelectMenu from '../select-menu/SelectMenu'
import LeftBorderedBox from '@/components/validation/LeftBorderedBox'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { Typography } from '@mui/material'

type WorkflowStateSelectMenuProps = {
  selectedWorkflowState: WorkflowState
  workflowStates: WorkflowState[]
  onChange: (workflowState: WorkflowState) => void
  dense?: boolean
  hideLabel?: boolean
}

export default function WorkflowStateSelectMenu({
  selectedWorkflowState,
  workflowStates,
  onChange,
  dense,
  hideLabel,
}: WorkflowStateSelectMenuProps) {
  const getThemeColor = useGetThemeColor()
  const filteredWorkflowStates = workflowStates.filter(
    ({ code }) => code !== 'processing',
  )

  return (
    <SelectMenu<WorkflowState>
      title="Workflow State"
      selectedLabel={
        <Typography
          component="div"
          variant={dense ? 'caption' : 'body2'}
          color={getThemeColor(selectedWorkflowState?.color)}
          noWrap
        >
          <b>{selectedWorkflowState.name}</b>
        </Typography>
      }
      options={filteredWorkflowStates.map((workflowState) => ({
        label: (
          <LeftBorderedBox color={getThemeColor(workflowState.color)}>
            {workflowState.name}
          </LeftBorderedBox>
        ),
        key: workflowState.id,
        value: workflowState,
      }))}
      onChange={onChange}
      dense={dense}
      hideLabel={hideLabel}
    />
  )
}
