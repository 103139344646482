import { Stack, Button, CircularProgress, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ProjectGridField } from '@/types/fields'
import { ProjectGridFieldRule } from '@/types/rules'
import { DraggableList } from '@/components/lists/DraggableList'
import useOverlay from '@/hooks/useOverlay'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useProjectGridsContext } from '@/components/project-settings/ProjectGridsProvider'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { Dispatch, Fragment, SetStateAction } from 'react'
import {
  useCreateProjectGridFieldRule,
  useDeleteProjectGridFieldRule,
  useGetProjectGridFieldRules,
  useUpdateProjectGridFieldRules,
} from '@/service-library/hooks/project-grid-field-rules'
import { getBaseGrid } from '@/utils/field-utils'
import queryKeys from '@/service-library/query-keys'
import RuleCard from '@/components/rules/RuleCard'
import AddFieldRuleDialog from './AddFieldRuleDialog'

type FieldRulesProps = {
  field: ProjectGridField
  selectedRule: ProjectGridFieldRule | null
  setSelectedRule: Dispatch<SetStateAction<ProjectGridFieldRule | null>>
}

export default function FieldRules({
  field,
  selectedRule,
  setSelectedRule,
}: FieldRulesProps) {
  const featureFlags = useFeatureFlagContext()

  const {
    projectGridFieldRules = [],
    isLoading,
    queryKey,
  } = useGetProjectGridFieldRules({
    filters: {
      project_grid_field_id: field.id,
      fields__include: 'rule,workflow_states_ids,dependent_fields_ids',
      limit: '1000',
    },
    enabled: !!field.id,
  })

  const { createProjectGridFieldRule } = useCreateProjectGridFieldRule({
    sideEffectQueryKeys: [queryKeys.projectGridFields.lists()],
    listQueryKey: queryKey,
    onMutate: (newFieldRule) => {
      setSelectedRule(newFieldRule)
    },
    onSuccess: () => {
      showSuccessSnackbar('Field Rule Created')
    },
    onError: () => {
      showErrorSnackbar('Failed to create field rule. Please try again later.')
      setSelectedRule(null)
    },
  })

  const { deleteProjectGridFieldRule } = useDeleteProjectGridFieldRule({
    sideEffectQueryKeys: [queryKeys.projectGridFields.lists()],
    listQueryKey: queryKey,
    onMutate: () => {
      setSelectedRule(null)
    },
    onError: () => {
      showErrorSnackbar('Failed to delete field rule. Please try again later.')
    },
  })

  const { updateProjectGridFieldRules } = useUpdateProjectGridFieldRules({
    sideEffectQueryKeys: [queryKeys.projectGridFields.lists()],
    listQueryKey: queryKey,
    useSortOrder: true,
  })

  const reorderFieldRules = (reorderedFieldRules: ProjectGridFieldRule[]) => {
    const sortedFieldRules = reorderedFieldRules.map((item, index) => ({
      ...item,
      sort_order: index,
    }))
    updateProjectGridFieldRules(sortedFieldRules)
  }

  const { projectGrids = [] } = useProjectGridsContext()
  const baseGrid = getBaseGrid(projectGrids)

  const addRuleOverlay = useOverlay()

  return (
    <Stack p={!featureFlags.quality_control_ui ? 2 : 0} spacing={4}>
      {isLoading && <CircularProgress size={40} />}

      {!isLoading && (
        <>
          {/* MARK: Rules */}
          <Stack spacing={1}>
            {projectGridFieldRules.length > 0 && (
              <Box sx={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
                <DraggableList
                  data={projectGridFieldRules}
                  onChange={reorderFieldRules}
                >
                  {projectGridFieldRules.map((fieldRule) => (
                    <RuleCard
                      key={fieldRule.id}
                      onDelete={() => deleteProjectGridFieldRule(fieldRule.id)}
                      isSelected={selectedRule?.id === fieldRule.id}
                      onClick={() => {
                        setSelectedRule(fieldRule)
                      }}
                      name={fieldRule.rule.name}
                      description={fieldRule.rule.description || ''}
                      funcName={fieldRule.params.function_name}
                      enabled={fieldRule.is_enabled}
                      onEnableDisable={(checked) => {
                        updateProjectGridFieldRules([
                          {
                            ...fieldRule,
                            is_enabled: checked,
                          },
                        ])
                      }}
                    />
                  ))}
                </DraggableList>
              </Box>
            )}

            <div>
              <Button
                variant="text"
                startIcon={<AddIcon />}
                onClick={addRuleOverlay.open}
              >
                Add Rule
              </Button>
              <AddFieldRuleDialog
                overlay={addRuleOverlay}
                fieldId={field.id}
                isTableField={baseGrid?.id !== field.project_grid_id}
                onSubmit={createProjectGridFieldRule}
              />
            </div>
          </Stack>
        </>
      )}
    </Stack>
  )
}
