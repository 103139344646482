import { Checkbox, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { WorkflowState } from '@/types/workflows'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { useDocumentCount } from '@/components/document-count-provider/DocumentCountProvider'
import LeftBorderedBox from '../validation/LeftBorderedBox'

type StateFilterProps = {
  workflowStates: WorkflowState[]
  visibleStateIds: string[]
  toggleState: (stateCode: string, newValue: boolean) => void
  showCount?: boolean
  radioGroup?: boolean
  miniMode?: boolean
}

const StateFilter = ({
  workflowStates,
  visibleStateIds,
  toggleState,
  showCount,
  radioGroup = false,
  miniMode = false,
}: StateFilterProps) => {
  const theme = useTheme()
  const getThemeColor = useGetThemeColor()

  const { countByWorkflowStateId } = useDocumentCount()

  return (
    <Stack direction="column" spacing={0.25}>
      {!miniMode && <Typography variant="body1">States</Typography>}
      <Stack sx={{ transform: radioGroup ? undefined : 'translateX(-2px)' }}>
        {workflowStates.map((state) => {
          if (state.code === 'processing') return
          const isSelected = visibleStateIds.includes(state.id)
          const color = getThemeColor(state.color)
          const label = (
            <Typography variant="body2">
              {miniMode ? '' : state.name}
            </Typography>
          )
          return (
            <Tooltip
              key={state.id}
              title={state.name}
              placement="right"
              enterDelay={miniMode ? 0 : 5_000}
              disableHoverListener={!miniMode}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                role="button"
                onClick={() => {
                  toggleState(state.id, !isSelected)
                }}
                sx={{
                  minHeight: 28,
                  position: 'relative',
                  cursor: 'pointer',
                  borderRadius: 1,
                  px: !miniMode ? 0.5 : 0,
                  pl: 0,
                  background:
                    isSelected && radioGroup
                      ? theme.palette.action.selected
                      : undefined,
                  '&:hover': !miniMode
                    ? {
                        background: theme.palette.action.hover,
                        '& .description-button': {
                          display: 'inline-flex',
                        },
                      }
                    : {},
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  sx={{ flexGrow: 1, pl: radioGroup ? 1.5 : 0 }}
                >
                  {!radioGroup && (
                    <Checkbox
                      checked={isSelected}
                      onChange={(e, newValue: boolean) => {
                        toggleState(state.id, newValue)
                      }}
                      sx={{
                        px: 0,
                        py: '2px',
                        color,
                        '&.Mui-checked': {
                          color,
                        },
                      }}
                    />
                  )}

                  {radioGroup ? (
                    <LeftBorderedBox color={color}>
                      <Typography variant="body2" component="div">
                        {label}
                      </Typography>
                    </LeftBorderedBox>
                  ) : (
                    <Typography variant="body2" component="div">
                      {label}
                    </Typography>
                  )}
                </Stack>

                {!miniMode && showCount && (
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="body2"
                  >
                    {countByWorkflowStateId?.[state.id]?.total || '-'}
                  </Typography>
                )}
              </Stack>
            </Tooltip>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default StateFilter
