import { Skeleton, Stack, Typography } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { useGetDataListColumns } from '@/service-library/hooks/data-list-columns'
import { useGetDataListEntryCells } from '@/service-library/hooks/data-list-entry-cells'
import FieldInfoLabeledValue from './FieldInfoLabeledValue'

type ListEntryDataProps = {
  field: ProjectGridField
  entryId: string
}

export default function ListEntryData({ field, entryId }: ListEntryDataProps) {
  const { data_list_id: metadataListId } = field.metadata // We are keeping metadata for backwards compatibility

  const { data_list_id: paramsListId } = field.params || {}

  const { dataListColumns, isLoading: columnsIsLoading } =
    useGetDataListColumns({
      filters: {
        limit: '1000',
        data_list_id: paramsListId || metadataListId,
        is_system_managed: 'false',
      },
      enabled: !!paramsListId || !!metadataListId,
    })

  const { dataListEntryCells, isLoading: cellsIsLoading } =
    useGetDataListEntryCells({
      filters: {
        limit: dataListColumns.length.toString(),
        data_list_entry_id: entryId,
        data_list_column_id__in: dataListColumns
          .map((column) => column.id)
          .join(','),
        fields__include: 'data_list_entry_cell_values',
      },
      enabled: dataListColumns.length > 0,
    })

  const isLoading = columnsIsLoading || cellsIsLoading

  return (
    <Stack
      flexGrow={1}
      spacing={isLoading ? 0.75 : 1.5}
      py={1.5}
      px={1}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Typography component="h3" variant="subtitle1" sx={{ px: 0.5 }}>
        List Entry
      </Typography>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : !dataListEntryCells.length ? (
        <Typography color="error" variant="body2" sx={{ px: 0.5 }}>
          The item selected for this field either no longer exists or is from a
          different list. Please select a new one or re-run rules if necessary.
        </Typography>
      ) : (
        dataListColumns.map((column) => {
          return (
            <FieldInfoLabeledValue
              key={column.id}
              label={{ name: column.name }}
              value={
                dataListEntryCells?.find(
                  (cell) => cell.data_list_column_id === column.id,
                )?.data_list_entry_cell_values[0]?.value || null
              }
            />
          )
        })
      )}
    </Stack>
  )
}
