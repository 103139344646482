import { useGetWorkflowStateEvent } from '@/service-library/hooks/workflow-state-events'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Typography } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'

const conditionMap: Record<string, string> = {
  has_flags: 'has flags.',
  has_no_flags: 'has no flags.',
  has_flag_type: `has the following flag type:`,
}

type TriggerEventDisplayProps = {
  action: WorkflowStateAction
}

export default function TriggerEventDisplay({
  action,
}: TriggerEventDisplayProps) {
  const { workflowStateEvent } = useGetWorkflowStateEvent({
    id: action.params.event_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  if (!workflowStateEvent) return null

  const condition = action.params.condition

  // TODO: When we eventually switch this action to use actual flag types instead of relying on flag class,
  // make this show the actual flag.
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        Trigger the{' '}
        <PrimaryTextWrapper>{workflowStateEvent.name}</PrimaryTextWrapper> event
        {condition === 'none'
          ? '.'
          : ` if the document ${conditionMap[condition]}`}
      </Typography>
      {condition === 'has_flag_type' && (
        <Typography variant="body2">{action.params.flag_type_code}</Typography>
      )}
    </>
  )
}
