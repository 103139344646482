import { Box, SxProps, useTheme } from '@mui/material'

type LayeredLoadingWheelProps = {
  sx?: SxProps
}

export default function LayeredLoadingWheel({ sx }: LayeredLoadingWheelProps) {
  const theme = useTheme()
  const color = `${theme.palette.primary.main}99`
  const initialDuration = 8
  return (
    <Box
      sx={{
        '@keyframes spin': {
          from: {
            transform: 'rotate(0deg)',
          },
          to: {
            transform: 'rotate(360deg)',
          },
        },
        border: '3px solid transparent',
        borderTopColor: color,
        borderBottomColor: color,
        width: '300px',
        height: '300px',
        margin: '-170px 0 0 ',
        borderRadius: '50%',
        animation: `spin ${initialDuration + 1}s linear infinite`,
        ':before': {
          content: '""',
          position: 'absolute',
          top: '5px',
          left: '5px',
          right: '5px',
          bottom: '5px',
          border: '3px solid transparent',
          borderTopColor: color,
          borderBottomColor: color,
          borderRadius: '50%',
          animation: `spin ${initialDuration + 2}s ease-in-out infinite`,
        },
        ':after': {
          content: '""',
          position: 'absolute',
          top: '15px',
          left: '15px',
          right: '15px',
          bottom: '15px',
          border: '3px solid transparent',
          borderTopColor: color,
          borderBottomColor: color,
          borderRadius: '50%',
          animation: `spin ${initialDuration}s linear infinite`,
        },
        ...sx,
      }}
    />
  )
}
