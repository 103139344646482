import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import { GroupOption } from '@/types/metrics'
import { formatter, getTimeTicks } from '@/utils/chart-utils'
import LineChart, { LineChartProps } from './LineChart'

type TimeLineChartProps = {
  from: Date
  to: Date
  groupedBy: GroupOption
} & LineChartProps

export default function TimeLineChart({
  from,
  to,
  groupedBy,
  data,
  margin,
  ...props
}: TimeLineChartProps) {
  const includeYear = useMemo(
    () => from.getFullYear() !== to.getFullYear(),
    [from, to],
  )
  const timeScaleTicks = useMemo(
    () => getTimeTicks(from, to, groupedBy, includeYear, 8),
    [from, groupedBy, includeYear, to],
  )

  const ticksWithLabels = useMemo(() => {
    const tempTicksWithLabels: string[] = []
    data.forEach((group) => {
      if (group.data) {
        group.data.forEach((value) => {
          const oldDate = value.x as string
          const formattedValue = formatter(oldDate, groupedBy, includeYear)
          if (timeScaleTicks.includes(formattedValue)) {
            tempTicksWithLabels.push(oldDate)
          }
        })
      }
    })
    return tempTicksWithLabels
  }, [data, timeScaleTicks, groupedBy, includeYear])

  return (
    <LineChart
      data={data}
      xFormat={(value) => formatter(value as Date, groupedBy, includeYear)}
      axisBottom={{
        format: (value) => {
          const formattedValue = formatter(value, groupedBy, includeYear)
          return timeScaleTicks.includes(formattedValue) ? formattedValue : ''
        },
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 90,
        tickValues: ticksWithLabels,
      }}
      enablePoints={false}
      margin={{ bottom: groupedBy === 'hour' ? 110 : 80, ...margin }}
      gridXValues={ticksWithLabels}
      tooltipContent={(point, stackedPoints) => (
        <Stack direction="row">
          {stackedPoints.map((_stackedPoint, index) => (
            <Stack key={index} p={1} maxWidth={170}>
              <Typography component="p" noWrap variant="caption">
                <b>Date:</b> {point.data.xFormatted}
              </Typography>
              <Typography component="p" noWrap variant="caption">
                <b>{props.yLegend}:</b> {point.data.yFormatted}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {...props}
    />
  )
}
