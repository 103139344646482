import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Stack, Alert } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import NotFound from '@/pages/NotFound'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import Drawer from '@/components/drawer/Drawer'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import SettingsNavigation from '@/components/settings-navigation/SettingsNavigation'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'

const qualityRoutes: RouteDefinition[] = [
  {
    to: 'document-rules',
    label: 'Document Rules',
  },
  {
    to: 'field-rules',
    label: 'Field Rules',
  },
]

type RouteDefinition = {
  to: string
  label: string
  isSuperUserOnly?: boolean
}

export default function ProjectSettingsLayout() {
  const featureFlags = useFeatureFlagContext()
  const { hasEditingPermission } = usePermission()
  const { project } = useProjectContext()
  const [demoMode] = useDemoModeContext()
  const [errors, setErrors] = useState<string[] | null>(null)
  const drawerOverlay = useOverlay({ initiallyOpen: true })
  const isSuperUser = useIsSuperUser()

  const routes: RouteDefinition[] = [
    {
      to: 'general',
      label: 'General',
    },
    { to: 'workflow-states', label: 'Workflow States' },
    {
      to: 'sources',
      label: 'Sources',
    },
    {
      to: 'fields',
      label: 'Fields',
    },
    {
      to: 'integrations',
      label: 'Integrations',
    },
    !demoMode && isSuperUser
      ? {
          to: 'advanced-settings',
          label: 'Advanced Settings',
          isSuperUserOnly: true,
        }
      : null,
  ].filter(Boolean) as RouteDefinition[]

  const canEditProject = hasEditingPermission('edit_projects', project.org_id)

  if (!canEditProject) return <NotFound />

  return (
    <>
      <Breadcrumb label="Settings" url={`/projects/${project.id}/settings`} />
      <Drawer overlay={drawerOverlay} size="sm" transparent>
        <SettingsNavigation label="Project Settings" routes={routes} />
        {featureFlags.quality_control_ui && (
          <SettingsNavigation label="Quality Control" routes={qualityRoutes} />
        )}
      </Drawer>

      <Stack direction="column" p={2} pt={3} width="100%" flexGrow={1}>
        {errors && <Alert severity="error">{errors}</Alert>}
        <Outlet
          context={{
            setWizardErrors: setErrors,
            isSettings: true,
          }}
        />
      </Stack>
    </>
  )
}
