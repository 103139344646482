import { createPortal } from 'react-dom'
import { Divider, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useFieldPortalContext } from '../FieldPortalProvider'

type ListLookupAddMappingProps = {
  originalValue: string
  manualValue?: string
  onAddClicked: () => void
  isAddingMapping: boolean
}

export default function ListLookupAddMapping({
  originalValue,
  manualValue,
  onAddClicked,
  isAddingMapping,
}: ListLookupAddMappingProps) {
  const [fieldPortalNode] = useFieldPortalContext()
  return fieldPortalNode
    ? createPortal(
        <>
          <Divider />
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            spacing={{ xs: 0.5, lg: 1 }}
            sx={{ p: 1, pl: 2 }}
            alignItems={{ lg: 'center', xs: 'flex-end' }}
          >
            <Typography variant="caption" sx={{ flexGrow: 1 }}>
              Do you want this field to always recognize{' '}
              <Typography variant="caption" color="primary">
                {originalValue}
              </Typography>{' '}
              as{' '}
              <Typography variant="caption" color="primary">
                {manualValue}
              </Typography>
              ?
            </Typography>

            <LoadingButton
              onClick={() => onAddClicked()}
              variant="text"
              size="small"
              sx={{ flexShrink: 0, px: 0.5 }}
              tabIndex={-1}
              loading={isAddingMapping}
            >
              Yes
            </LoadingButton>
          </Stack>
        </>,
        fieldPortalNode,
      )
    : null
}
