import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import { AxiosResponse } from 'axios'
import PaginatedResponse from '@/types/paginated-response'
import { RequestOptions, UpdateDetailRequestOptions } from '../request-types'
import { DetailQueryKeyOption, ListQueryKeyOption } from '../query-types'

export type UseUpdateDetailOptions<T> = UseMutationOptions<
  T,
  unknown,
  T,
  unknown
> &
  RequestOptions &
  DetailQueryKeyOption &
  ListQueryKeyOption

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: UpdateDetailRequestOptions<T>,
  ) => Promise<AxiosResponse<T>>
}

/**
 * This hook is used to update an existing item and, optionally, update a related list query with the new item.
 */
export default function useUpdateDetail<T extends { id: string }>({
  serviceFn,
  sideEffectQueryKeys,
  detailQueryKey,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  onSettled,
  ...options
}: UseUpdateDetailOptions<T> & ServiceFn<T>) {
  const queryClient = useQueryClient()

  return useMutation({
    sideEffectQueryKeys,
    mutationFn: (item) => {
      return serviceFn({ item, filters, axiosOptions }).then(({ data }) => data)
    },
    onMutate: async (item) => {
      if (detailQueryKey) {
        queryClient.setQueryData(detailQueryKey, item)
      }

      let listData: InfiniteData<PaginatedResponse<T>> | undefined = undefined

      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        listData = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                results: page.results.map((storedItem) => {
                  if (storedItem.id === item.id) {
                    return item
                  }
                  return storedItem
                }),
              }
            })
            return {
              ...old,
              pages,
            }
          },
        )
      }

      // Run user supplied onMutate function if available
      onMutate?.(item)

      // Return the previous data in case we need to reset it in onError
      return {
        previous: {
          detail: detailQueryKey
            ? queryClient.getQueryData(detailQueryKey)
            : undefined,
          list: listData,
        },
      }
    },
    onError: (error, item, context) => {
      // Run user supplied onError function if available
      onError?.(error, item, context)

      if (!context?.previous) return

      // Reset
      if (detailQueryKey)
        queryClient.setQueryData(detailQueryKey, context?.previous.detail)
      if (listQueryKey)
        queryClient.setQueryData(listQueryKey, context?.previous.list)
    },
    onSettled: (...args) => {
      onSettled?.(...args)

      if (detailQueryKey)
        queryClient.invalidateQueries({ queryKey: detailQueryKey })
      if (listQueryKey)
        queryClient.invalidateQueries({ queryKey: listQueryKey })
    },
    ...options,
  })
}
