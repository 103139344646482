import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react'
import { ProjectGridField } from '@/types/fields'

type FieldROIProviderValue = {
  field: ProjectGridField
  setEnableSave: Dispatch<SetStateAction<boolean>>
  newROIRef: MutableRefObject<{
    region_of_interest_left: number
    region_of_interest_top: number
    region_of_interest_right: number
    region_of_interest_bottom: number
    region_of_interest_page: number
  } | null>
}

const FieldROIContext = createContext<FieldROIProviderValue>(
  {} as FieldROIProviderValue,
)

export const useFieldROIContext = () => useContext(FieldROIContext)

type FieldROIProviderProps = {
  children: ReactNode
} & FieldROIProviderValue

export default function FieldROIProvider({
  children,
  ...rest
}: FieldROIProviderProps) {
  return (
    <FieldROIContext.Provider value={{ ...rest }}>
      {children}
    </FieldROIContext.Provider>
  )
}
