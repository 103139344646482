import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { FlagType } from '@/types/flags'

const service = buildService<FlagType>({
  pathCategory: 'flag_types',
})

export function useGetFlagType({
  id,
  ...options
}: UseGetDetailOptions<FlagType>) {
  const query = useGetDetail<FlagType>({
    id,
    queryKey: queryKeys.flagTypes.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    flagType: query.data,
    ...query,
  }
}

export function useGetFlagTypes({
  filters,
  ...options
}: UseGetListOptions<FlagType> = {}) {
  const query = useGetList<FlagType>({
    filters,
    queryKey: queryKeys.flagTypes.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    flagTypes: query.allData,
    ...query,
  }
}
