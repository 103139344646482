import { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import {
  useGetDocument,
  useGetDocuments,
} from '@/service-library/hooks/documents'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'

type UseROIDocumentProps = {
  workflowStateId: string
  enabled?: boolean
}

export default function useROIDocument({
  workflowStateId,
  enabled,
}: UseROIDocumentProps) {
  const { project } = useProjectContext()

  const [selectedIndex, setSelectedIndex] = useState(0)

  const { documents, isLoading } = useGetDocuments({
    filters: {
      project_id: project.id,
      limit: '100',
      document_workflow_states__workflow_state_id: workflowStateId,
      ordering: '-pre_process_completed_at',
      fields__only: 'id',
    },
    enabled,
  })

  const query = useGetDocument({
    id: documents[selectedIndex]?.id,
    filters: {
      fields__include: 'document_pages',
      document_pages__fields__include: 'image_urls',
    },
    enabled,
  })

  const { document } = query

  query.document = document
    ? {
        ...document,
        document_pages:
          document.document_pages?.sort(
            (a, b) => a.page_number - b.page_number,
          ) || [],
      }
    : undefined

  const hasNextDocument = selectedIndex + 1 < documents.length
  const hasPreviousDocument = selectedIndex - 1 >= 0

  const goToNextDocument = () => {
    setSelectedIndex((prev) => prev + 1)
  }

  const goToPreviousDocument = () => {
    setSelectedIndex((prev) => prev - 1)
  }

  useHotkeys(
    'alt+1',
    () => {
      if (hasPreviousDocument) goToPreviousDocument()
    },
    { preventDefault: true },
    [goToPreviousDocument],
  )

  useHotkeys(
    'alt+2',
    () => {
      if (hasNextDocument) goToNextDocument()
    },
    { preventDefault: true },
    [goToNextDocument],
  )

  return {
    query,
    documentIsAvailable: isLoading ? true : !!documents[0]?.id,
    documentsCount: documents.length,
    hasNextDocument,
    hasPreviousDocument,
    selectedIndex,
    goToNextDocument,
    goToPreviousDocument,
  }
}
