import { ReactNode } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Stack, IconButton } from '@mui/material'
import { altHotKeyStr } from '@/utils/get-os'
import KeyboardShortcutsTooltip from '@/components/keyboard-shortcuts-tooltip/KeyboardShortcutsTooltip'
import PageCount from '@/components/image-zoom-pan/PageCount'

type DocumentsControlsProps = {
  documentsCount: number
  hasNextDocument: boolean
  hasPreviousDocument: boolean
  selectedIndex: number
  goToNextDocument: () => void
  goToPreviousDocument: () => void
  /** Any additional controls can be passed in as children. */
  children?: ReactNode
  childrenPosition?: 'start' | 'end'
}

export default function DocumentsControls({
  documentsCount,
  hasNextDocument,
  hasPreviousDocument,
  selectedIndex,
  goToNextDocument,
  goToPreviousDocument,
  children,
  childrenPosition = 'start',
}: DocumentsControlsProps) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {!!children && childrenPosition === 'start' && children}
      <KeyboardShortcutsTooltip
        title="Previous Document"
        enterDelay={1000}
        disableInteractive
        keyboardShortcuts={[`${altHotKeyStr} + 1`]}
        arrow
      >
        <IconButton
          size="small"
          disabled={!hasPreviousDocument}
          onClick={goToPreviousDocument}
        >
          <KeyboardArrowUp fontSize="inherit" />
        </IconButton>
      </KeyboardShortcutsTooltip>

      <PageCount page={selectedIndex + 1} total={documentsCount} />

      <KeyboardShortcutsTooltip
        title="Next Document"
        enterDelay={1000}
        disableInteractive
        keyboardShortcuts={[`${altHotKeyStr} + 2`]}
        arrow
      >
        <IconButton
          size="small"
          disabled={!hasNextDocument}
          onClick={goToNextDocument}
        >
          <KeyboardArrowDown fontSize="inherit" />
        </IconButton>
      </KeyboardShortcutsTooltip>
      {!!children && childrenPosition === 'end' && children}
    </Stack>
  )
}
