import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import useMutation, { UseMutationOptions } from './useMutation'
import { AxiosResponse } from 'axios'
import PaginatedResponse from '@/types/paginated-response'
import { BulkResponse } from '@/types/bulk-response'
import { sortBySortOrder } from '@/utils/field-utils'
import { notNull } from '@/utils/typescript-utils'
import useBatchedRequest from './useBatchedRequest'
import { ListQueryKeyOption } from '../query-types'
import { RequestOptions, UpdateListRequestOptions } from '../request-types'

export type UseUpdateListOptions<T> = UseMutationOptions<
  T[],
  unknown,
  T[],
  unknown
> &
  RequestOptions &
  ListQueryKeyOption & {
    useSortOrder?: boolean
  }

type ServiceFn<T> = {
  /** This is the function that makes the request. This should come from the respective service. */
  serviceFn: (
    options: UpdateListRequestOptions<T>,
  ) => Promise<AxiosResponse<BulkResponse<T>>>
}

/**
 * This hook is used to update an existing item and, optionally, update a related list query with the new item.
 */
export default function useUpdateList<T extends { id: string }>({
  serviceFn,
  sideEffectQueryKeys,
  useSortOrder,
  listQueryKey,
  filters,
  axiosOptions,
  onMutate,
  onError,
  ...options
}: UseUpdateListOptions<T> & ServiceFn<T>) {
  const queryClient = useQueryClient()

  const batchedRequest = useBatchedRequest<T, AxiosResponse<BulkResponse<T>>>({
    queryKey: listQueryKey,
    requestFn: (items) => {
      return serviceFn({ items, filters, axiosOptions })
    },
  })

  return useMutation({
    sideEffectQueryKeys,
    mutationFn: (items) =>
      batchedRequest(items).then((data) => {
        return data.data.map(({ result }) => result).filter(notNull)
      }),
    onMutate: async (items) => {
      let previous: InfiniteData<PaginatedResponse<T>> | undefined = undefined

      if (listQueryKey) {
        await queryClient.cancelQueries({ queryKey: listQueryKey })

        previous = queryClient.getQueryData(listQueryKey)

        // Update the list data in cache if it exists
        queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(
          listQueryKey,
          (
            old = {
              pages: [],
              pageParams: [],
            },
          ) => {
            let newItems = [...items]

            // Replace existing items
            const pages = old?.pages.map((page) => {
              return {
                ...page,
                // This is very inefficient. Is there a way we can know which page it is in beforehand?
                // This doesn't work for creating items.
                results: page.results.map((storedItem) => {
                  const matchingItem = items.find(
                    (item) => item.id === storedItem.id,
                  )
                  if (matchingItem) {
                    newItems = newItems.filter(
                      (item) => item.id !== matchingItem.id,
                    )
                  }
                  return matchingItem || storedItem
                }),
              }
            })

            // We always put the new items in a new fake page so it will know to refetch the new page
            // if the items are actually in a new page. If they aren't actually in a new page, it will
            // still refetch appropriately. Trust us. We definitely know what we're doing.
            if (newItems.length > 0) {
              pages.push({
                results: newItems,
                next: null,
                previous: null,
                count: newItems.length,
              })
            }
            const newInfiniteResponse = {
              ...old,
              pages,
            }
            if (newItems.length > 0 || !useSortOrder) return newInfiniteResponse

            const newPages: PaginatedResponse<T>[] = []

            if (newInfiniteResponse.pages.length === 1) {
              newPages.push({
                next: null,
                previous: null,
                results: sortBySortOrder(newInfiniteResponse.pages[0].results),
              })
            } else {
              const allData = sortBySortOrder(
                newInfiniteResponse.pages?.reduce<T[]>(
                  (acc, page) => [...acc, ...page.results],
                  [],
                ),
              )

              const limit = newInfiniteResponse.pages[0].results.length
              for (let i = 0; i < allData.length; i += limit) {
                newPages.push({
                  next: null,
                  previous: null,
                  results: allData.slice(i, i + limit),
                })
              }
            }

            return {
              ...old,
              pages: newPages,
            }
          },
        )
      }
      // Run user supplied onMutate function if available
      onMutate?.(items)

      // Return the previous data in case we need to reset it in onError
      return { previous }
    },
    onError: (error, items, context) => {
      // Run user supplied onError function if available
      onError?.(error, items, context)

      if (!listQueryKey || !context?.previous) return
      // Reset
      queryClient.setQueryData(listQueryKey, context?.previous)
    },
    ...options,
  })
}
