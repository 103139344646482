import { Typography } from '@mui/material'

type PageCountProps = {
  page: number
  total: number
}

export default function PageCount({ page, total }: PageCountProps) {
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      sx={{ lineHeight: 'unset' }}
    >
      {page} of {total}
    </Typography>
  )
}
