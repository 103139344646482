import { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Document } from '@/types/documents'
import { DocumentFlag, RowValueFlag } from '@/types/flags'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import useOverlay from '@/hooks/useOverlay'
import {
  useTriggerWorkflowStateEvent,
  useWorkflowStateEventStatuses,
} from '@/service-library/hooks/events'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import { getFlagsByType, getFlagsCountFromType } from '@/utils/flags-utils'
import useFlagPriorityColor from '@/components/flags/useFlagPriorityColor'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import DocumentsWithErrorsDialog from './DocumentsWithErrorsDialog'
import { useDocumentListContext } from './DocumentListProvider'
import EventButton from './EventButton'

type ValidationEventButtonProps = {
  document: Document
  workflowStateId: string
}

export default function ValidationEventButton({
  document,
  workflowStateId,
}: ValidationEventButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [triggeredEvent, setTriggeredEvent] = useState<WorkflowStateEvent>()

  const hasErrorsOverlay = useOverlay()

  const { goToNextDocument } = useDocumentListContext()
  const { rowValueFlags } = useDocumentRowValuesContext()
  const { triggerWorkflowStateEvent } = useTriggerWorkflowStateEvent()

  const { workflowStateEvents = [] } = useGetWorkflowStateEvents({
    filters: {
      workflow_state_id: workflowStateId,
    },
  })
  const visibleWorkflowStateEvents = workflowStateEvents.filter(
    ({ code }) => code !== 'on_enter' && code !== 'on_exit',
  )

  const [shouldPoll, setShouldPoll] = useState(false)
  const { eventStatuses = [], refetch: refetchEventStatuses } =
    useWorkflowStateEventStatuses({
      filters: {
        document_id: document.id,
      },
      enabled: !!visibleWorkflowStateEvents.length,
      refetchInterval: shouldPoll ? 2000 : false,
    })

  const statusesWeCareAbout = eventStatuses.filter(
    ({ status }) => status === 'waiting' || status === 'running',
  )

  useEffect(() => {
    setShouldPoll(statusesWeCareAbout.length > 0)
  }, [refetchEventStatuses, statusesWeCareAbout.length])

  const { document_flags = [] } = document

  const documentFlagsByType = getFlagsByType(document_flags)
  const rowFlagsByType = getFlagsByType(rowValueFlags)

  const { priorityCode } = useFlagPriorityColor({
    documentFlags: getFlagsCountFromType(documentFlagsByType),
    rowsFlags: getFlagsCountFromType(rowFlagsByType),
  })

  const documentErrorFlags = documentFlagsByType.error as DocumentFlag[]
  const rowValueErrorFlags = rowFlagsByType.error as RowValueFlag[]
  const documentWarningFlags = documentFlagsByType.warning as DocumentFlag[]
  const rowValueWarningFlags = rowFlagsByType.warning as RowValueFlag[]
  const hasProblemFlags = priorityCode === 'error' || priorityCode === 'warning'

  function triggerEvent(event: WorkflowStateEvent) {
    setIsLoading(true)
    triggerWorkflowStateEvent([
      {
        workflow_state_event_id: event.id,
        document_id: document.id,
      },
    ])

    if (
      event.move_to_next_doc_in_validation ||
      event.move_to_next_doc_in_validation === undefined
    ) {
      goToNextDocument()
    }
  }

  function handleTriggerEvent(event: WorkflowStateEvent) {
    if (
      hasProblemFlags &&
      event.when_document_has_errors === 'trigger_with_warning'
    ) {
      hasErrorsOverlay.open()
      setTriggeredEvent(event)
    } else {
      triggerEvent(event)
    }
  }

  // Reset loading whenever we switch documents
  useEffect(() => {
    setIsLoading(false)
  }, [document.id])

  useNotifications({
    keys: ['pdWorkflow/events.event_complete'],
    callback: ({ updated_entity_ids }) => {
      if (updated_entity_ids.includes(document.id)) {
        refetchEventStatuses()
        setIsLoading(false)
      }
    },
  })

  useHotkeys(
    'alt+d',
    () => {
      !isLoading &&
        !statusesWeCareAbout.length &&
        visibleWorkflowStateEvents[0] &&
        // hotkey triggers primary event
        handleTriggerEvent(visibleWorkflowStateEvents[0])
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [
      statusesWeCareAbout.length,
      hasProblemFlags,
      isLoading,
      goToNextDocument,
      visibleWorkflowStateEvents[0],
    ],
  )

  return visibleWorkflowStateEvents.length > 0 ? (
    <>
      <EventButton
        documentsHaveErrors={hasProblemFlags}
        documentsIds={[document.id]}
        workflowStateEvents={visibleWorkflowStateEvents}
        isLoading={isLoading || statusesWeCareAbout.length > 0}
        onTriggerEvent={handleTriggerEvent}
      />
      <DocumentsWithErrorsDialog
        overlay={hasErrorsOverlay}
        documentFlags={[...documentErrorFlags, ...documentWarningFlags]}
        rowValueFlags={[...rowValueErrorFlags, ...rowValueWarningFlags]}
        onContinue={() => {
          triggeredEvent && triggerEvent(triggeredEvent)
          setTriggeredEvent(undefined)
          hasErrorsOverlay.close()
        }}
      />
    </>
  ) : null
}
