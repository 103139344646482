import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type UseListSearchParamOptions = {
  param: string
  defaultValue: string[]
}

export default function useListSearchParam({
  param,
  defaultValue,
}: UseListSearchParamOptions) {
  const [searchParams, setSearchParams] = useSearchParams()
  const visibleStatesArray = searchParams.get(param)?.split(',') ?? defaultValue

  const currentValues = visibleStatesArray.reduce<Record<string, boolean>>(
    (acc, value) => {
      if (value !== 'none') {
        acc[value] = true
      }
      return acc
    },
    {},
  )

  const toggleValue = (value: string, isChecked: boolean) => {
    const allCurrentSearchParams = Object.fromEntries(searchParams.entries())
    const newStates = { ...currentValues }
    newStates[value] = isChecked
    const newValues: string[] = []
    Object.entries(newStates).forEach(([value, checked]) => {
      if (!checked) return
      newValues.push(value)
    })
    if (newValues.length > 0) {
      setSearchParams(
        {
          ...allCurrentSearchParams,
          [param]: newValues.join(','),
        },
        { replace: true },
      )
    } else {
      setSearchParams(
        { ...allCurrentSearchParams, [param]: 'none' },
        { replace: true },
      )
    }
  }

  const resetValue = useCallback(() => {
    setSearchParams(
      (prev) => {
        prev.delete(param)
        return prev
      },
      { replace: true },
    )
  }, [param, setSearchParams])

  return {
    values: currentValues,
    toggleValue,
    resetValue,
  }
}
