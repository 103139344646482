import { Fragment, ReactNode, useEffect, useState } from 'react'
import { Paper, Popper, Box } from '@mui/material'
import { useFieldPortalContext } from './FieldPortalProvider'

type FieldPopperWithPortalProps = {
  anchorEl: HTMLDivElement | null
  open: boolean
  children: ReactNode
  hasSolidBackground: boolean
  isInTable?: boolean
}

export default function FieldPopperWithPortal({
  anchorEl,
  open,
  children,
  hasSolidBackground,
  isInTable,
}: FieldPopperWithPortalProps) {
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const Wrapper = hasSolidBackground ? Paper : Fragment
  const wrapperProps = hasSolidBackground
    ? {
        onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.preventDefault(),
        sx: { pt: '6px' },
      }
    : {}

  const [, setFieldPortalNode] = useFieldPortalContext()

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const clientWidth = anchorEl?.clientWidth || 0
      const width = hasSolidBackground
        ? Math.max(clientWidth, 300)
        : clientWidth
      setMaxWidth(width)
    })
    if (open && anchorEl) observer.observe(anchorEl)
    return () => {
      observer.disconnect()
    }
  }, [anchorEl, hasSolidBackground, open])

  return (
    anchorEl && (
      <Popper
        id="field-with-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: hasSolidBackground || isInTable ? 5 : 6,
          maxWidth,
          py: 0.5,
        }}
      >
        <Wrapper {...wrapperProps}>
          {children}
          <Box id="field-portal" ref={setFieldPortalNode} pt="6px" />
        </Wrapper>
      </Popper>
    )
  )
}
