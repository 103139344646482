import { ReactNode, useRef, useEffect, useState } from 'react'
import { DialogContent as MUIDialogContent, Typography } from '@mui/material'

type DialogContentProps = {
  children: ReactNode
  disablePadding?: boolean
}

export default function DialogContent({
  children,
  disablePadding,
}: DialogContentProps) {
  const [showDividers, setShowDividers] = useState(false)
  const contentRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const shouldShow =
        contentRef.current &&
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      setShowDividers(!!shouldShow)
    })
    if (contentRef.current) observer.observe(contentRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <MUIDialogContent
      ref={contentRef}
      dividers={showDividers}
      sx={{ p: disablePadding ? 0 : undefined }}
    >
      <Typography component="div" sx={{ pt: 1 }}>
        {children}
      </Typography>
    </MUIDialogContent>
  )
}
