import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ProjectGrid } from '@/types/projects'
import { Workflow } from '@/types/workflows'
import useOverlay from '@/hooks/useOverlay'
import { useGetWorkflows } from '@/service-library/hooks/workflows'
import { useGetOrgProjectModel } from '@/service-library/hooks/project-models'
import {
  getBaseGrid,
  sortBySortOrder,
  getNonGridFields,
} from '@/utils/field-utils'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { DocumentRowsTable } from '@/components/document-table/DashboardTable'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ProjectDashboardFilterDrawer from '@/components/project-dashboard/ProjectDashboardFilterDrawer'
import ColumnOrderProvider from '@/components/workflows/ColumnOrderProvider'
import ColumnSortingProvider from '@/components/workflows/ColumnSortingProvider'
import ColumnVisibilityProvider from '@/components/workflows/ColumnVisibilityProvider'
import SelectedGridsProvider from '@/components/workflows/SelectedGridsProvider'
import SelectedWorkflowProvider from '@/components/workflows/SelectedWorkflowProvider'
import SelectedWorkflowStatesProvider, {
  useSelectedWorkflowStatesContext,
} from '@/components/workflows/SelectedWorkflowStatesProvider'
import { useSelectedOrgContext } from './SelectedOrgProvider'

type ModelDataDashboardProps = {
  workflow: Workflow
}
function ModelDataDashboard({ workflow }: ModelDataDashboardProps) {
  const {
    selectedWorkflowStates,
    workflowStates: allWorkflowStates,
    toggleWorkflowState,
  } = useSelectedWorkflowStatesContext()
  const [isFullscreen, setIsFullscreen] = useState(false)

  const drawerOverlay = useOverlay({
    initiallyOpen: true,
    localStorageKey: 'models-data-drawer-is-open',
  })

  return (
    <>
      <ProjectDashboardFilterDrawer
        overlay={drawerOverlay}
        workflowStates={allWorkflowStates}
        selectedWorkflowStates={selectedWorkflowStates}
        toggleWorkflowState={toggleWorkflowState}
        showAllFields
      />
      <Breadcrumb label="Data" url="." />
      <DocumentRowsTable
        workflow={workflow}
        state={selectedWorkflowStates[0]}
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}
      />
    </>
  )
}

export default function ModelDataView() {
  const { id } = useParams()
  const { project } = useProjectContext()

  const { selectedOrg } = useSelectedOrgContext()

  // the returned projectModel might be a child projectModel, so the id from params might
  // not be the same as the one for the projectModel
  const { projectModel } = useGetOrgProjectModel({
    id: id || '',
    orgId: selectedOrg.id,
    filters: {
      fields__only: 'id',
    },
    keepPreviousData: true,
  })

  const { workflows = [] } = useGetWorkflows({
    filters: {
      limit: '1',
      project_id: project.id,
      project_model_id: projectModel?.id || '',
    },
    enabled: !!projectModel,
  })

  const baseGridWithoutGridFields = useMemo(() => {
    const baseGrid = getBaseGrid(
      project.project_grids as ProjectGrid[],
    ) as ProjectGrid
    return {
      ...baseGrid,
      //TODO: Remove sorting for when the backend returns data sorted
      project_grid_fields: sortBySortOrder(
        getNonGridFields(baseGrid.project_grid_fields),
      ),
    }
  }, [project.project_grids])

  if (workflows.length === 0) return null

  return (
    <SelectedWorkflowProvider defaultWorkflowId={workflows[0].id}>
      <SelectedWorkflowStatesProvider list={false}>
        <ColumnOrderProvider>
          <ColumnSortingProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SelectedGridsProvider
                baseGridWithoutGridFields={baseGridWithoutGridFields}
                grids={project.project_grids as ProjectGrid[]}
              >
                <ColumnVisibilityProvider
                  baseGridNonGridFields={
                    baseGridWithoutGridFields.project_grid_fields
                  }
                >
                  {workflows[0] && (
                    <ModelDataDashboard workflow={workflows[0]} />
                  )}
                </ColumnVisibilityProvider>
              </SelectedGridsProvider>
            </LocalizationProvider>
          </ColumnSortingProvider>
        </ColumnOrderProvider>
      </SelectedWorkflowStatesProvider>
    </SelectedWorkflowProvider>
  )
}
