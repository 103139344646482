import { ReactNode } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Stack } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import NavigationRailPortal from '@/components/navigation-rail/NavigationRailPortal'
import DrawerPortal from '@/components/drawer/DrawerPortal'
import ErrorBoundary from '@/components/error-boundary/ErrorBoundary'
import ScrollContainer from '@/components/scroll-container/ScrollContainer'
import CommandPaletteDialog from './CommandPaletteDialog'

type AppLayoutProps = {
  header?: ReactNode
  children: ReactNode
}

export default function AppLayout({ header, children }: AppLayoutProps) {
  const commandPaletteOverlay = useOverlay()

  useHotkeys('mod+k', () => {
    commandPaletteOverlay.open()
  })
  return (
    <ErrorBoundary>
      <Stack sx={{ height: '100vh' }}>
        {header}

        <Stack direction="row" sx={{ flexGrow: 1, minHeight: 0 }}>
          <NavigationRailPortal />

          {/* Drawer Portal - Left side only */}
          <DrawerPortal />

          {/* Page Content */}
          <ErrorBoundary>
            <ScrollContainer>{children}</ScrollContainer>
          </ErrorBoundary>

          {/* Drawer Portal - right side */}
          <DrawerPortal anchor="right" />
        </Stack>
      </Stack>
      <CommandPaletteDialog overlay={commandPaletteOverlay} />
    </ErrorBoundary>
  )
}
