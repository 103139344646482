import { DocumentFlagListItem } from '@/components/flags/FlagListItem'
import { useGetFlagTypes } from '@/service-library/hooks/flag-types'
import { DocumentFlag } from '@/types/flags'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Stack, Typography } from '@mui/material'

type AddDocumentFlagDisplayProps = {
  action: WorkflowStateAction
}

export default function AddDocumentFlagDisplay({
  action,
}: AddDocumentFlagDisplayProps) {
  const { flagTypes, isLoading } = useGetFlagTypes()

  if (isLoading) return null

  const flag = {
    short_description: action.params.short_description,
    long_description: action.params.long_description,
    flag_type: {
      code: flagTypes.find(
        (flagType) => flagType.id === action.params.flag_level_id,
      )?.code,
    },
  }

  return (
    <Stack>
      <Typography variant="body2" color="text.secondary">
        Add this flag to the document:
      </Typography>
      <DocumentFlagListItem flag={flag as DocumentFlag} />
    </Stack>
  )
}
