import { ReactNode, createContext, useContext } from 'react'
import { useProjectContext } from '../project-dashboard/ProjectProvider'
import { Workflow, WorkflowState } from '@/types/workflows'
import { useGetWorkflows } from '@/service-library/hooks/workflows'

type WorkflowContextValue = ReturnType<typeof useGetWorkflows> & {
  workflows: Workflow[]
  productionDataWorkflow: Workflow | undefined
  labelTrainingWorkflow: Workflow | undefined
  getWorkflowByCode: (code: string) => Workflow | undefined
  getWorkflowStateByCode: (
    workflowCode: string,
    stateCode: string,
  ) => WorkflowState | undefined
  getAllWorkflowStates: () => WorkflowState[]
}

const WorkflowContext = createContext<WorkflowContextValue>({
  workflows: [],
  isLoading: false,
  productionDataWorkflow: undefined,
  labelTrainingWorkflow: undefined,
  getWorkflowByCode: () => undefined,
  getWorkflowStateByCode: () => undefined,
  getAllWorkflowStates: () => undefined,
} as unknown as WorkflowContextValue)

type WorkflowProviderProps = {
  children: ReactNode
}

export const useWorkflowContext = () => useContext(WorkflowContext)

export default function WorkflowProvider({ children }: WorkflowProviderProps) {
  const { project } = useProjectContext()
  const query = useGetWorkflows({
    filters: {
      limit: '1000',
      project_id: project.id,
      fields__include: 'workflow_states',
      workflow_states__fields__include:
        'workflow_id,excluded_project_grid_fields_ids',
    },
  })

  const { workflows } = query

  // Note: When there are multiple workflows with the same code (e.g. 'categorization') this will return the first one
  function getWorkflowByCode(code: string) {
    return workflows.find((workflow) => workflow.code === code)
  }

  function getWorkflowStateByCode(workflowCode: string, stateCode: string) {
    const workflow = getWorkflowByCode(workflowCode)
    return workflow?.workflow_states?.find((state) => state.code === stateCode)
  }

  function getAllWorkflowStates() {
    return workflows.flatMap((workflow) => workflow.workflow_states || [])
  }

  return (
    <WorkflowContext.Provider
      value={{
        ...query,
        getWorkflowByCode,
        getWorkflowStateByCode,
        getAllWorkflowStates,
        productionDataWorkflow: getWorkflowByCode('basic'),
        labelTrainingWorkflow: getWorkflowByCode('labeling'),
      }}
    >
      {children}
    </WorkflowContext.Provider>
  )
}
