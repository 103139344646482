import { Alert } from '@mui/material'
import { useParams, Outlet } from 'react-router-dom'
import PageTitle from '@/components/PageTitle'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import DocumentCountProvider from '@/components/document-count-provider/DocumentCountProvider'
import DocumentFiltersProvider from '@/components/document-filters/DocumentFiltersProvider'
import OrganizationsProvider from '@/components/organizations/OrganizationsProvider'
import ProjectNavigation from '@/components/project-navigation/ProjectNavigation'
import FileUploadProvider from '@/components/upload-files/FileUploadProvider'
import WorkflowProvider from '@/components/workflow-provider/WorkflowProvider'
import ViewsProvider from '@/components/workflows/ViewsProvider'
import ProjectProvider from './ProjectProvider'
import { useGetProject } from '@/service-library/hooks/projects'
import { useEffect } from 'react'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

export default function ProjectLayout() {
  const { projectId } = useParams()
  const { project, error, queryKey } = useGetProject({
    id: projectId as string,
    filters: {
      fields__include: 'project_category,project_grids',
      project_grids__fields__include:
        'project_grid_fields,sub_project_grid_fields',
      project_grids__project_grid_fields__fields__include:
        'project_grid_field_type,project_grid_id,included_grid_fields,included_grid_fields_ids,project_grid_field_rules,name,has_category_model,project_model_id',
      project_grids__project_grid_fields__project_grid_field_rules__fields__include:
        'rule',
    },
  })

  const { rootOrganization, setRootOrganizationById } = useRootOrganization()

  useEffect(() => {
    if (project && project.org_id !== rootOrganization?.id) {
      // Update root org to match the project org so we're not looking at a project from a different root org
      setRootOrganizationById(project.org_id)
    }
  }, [project, rootOrganization?.id, setRootOrganizationById])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 0,
      }}
    >
      {project && (
        <>
          <Breadcrumb label={project.name} url={`/projects/${project.id}`} />
          <PageTitle>{project.name}</PageTitle>
        </>
      )}
      {!!error && <Alert severity="error">{(error as Error).message}</Alert>}
      {project && (
        <ProjectProvider project={project} queryKey={queryKey}>
          <OrganizationsProvider orgId={project.org_id}>
            <WorkflowProvider>
              <DocumentCountProvider>
                <ViewsProvider>
                  <DocumentFiltersProvider>
                    <FileUploadProvider project={project}>
                      <ProjectNavigation />
                      <Outlet />
                    </FileUploadProvider>
                  </DocumentFiltersProvider>
                </ViewsProvider>
              </DocumentCountProvider>
            </WorkflowProvider>
          </OrganizationsProvider>
        </ProjectProvider>
      )}
    </div>
  )
}
