import { Fragment } from 'react'
import {
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material'
import { useGetDocAuditEntries } from '@/service-library/hooks/doc-audit-entries'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import AuditEntry from './AuditEntry'

type DocumentAuditEntriesProps = {
  documentId: string
  enabled: boolean
}

export default function DocumentAuditEntries({
  documentId,
  enabled,
}: DocumentAuditEntriesProps) {
  const { docAuditEntries, isLoading } = useGetDocAuditEntries({
    filters: {
      doc_id: documentId,
      fields__include: 'user',
      user__fields__only: 'id,name',
      limit: '1000',
    },
    enabled,
  })

  return (
    <Stack spacing={0.5}>
      <Typography color="text.secondary" variant="h6" component="h3">
        Audit Entries
      </Typography>
      <List disablePadding>
        {isLoading && (
          <>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="40%" />}
                secondary={<Skeleton animation="wave" />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="40%" />}
                secondary={<Skeleton animation="wave" />}
              />
            </ListItem>
          </>
        )}
        {!isLoading &&
          (docAuditEntries.length ? (
            docAuditEntries.map(
              ({ id, name, created_at, user, description }) => {
                return (
                  <AuditEntry
                    key={id}
                    name={name}
                    description={description}
                    userName={user?.name}
                    created_at={prettifyDate(created_at)}
                  />
                )
              },
            )
          ) : (
            <Typography color="text.secondary" variant="body2">
              None
            </Typography>
          ))}
      </List>
    </Stack>
  )
}
