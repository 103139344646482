import { Delete, Description, Download, Refresh } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography, Button, Stack, Box } from '@mui/material'
import { useDeleteDocument } from '@/service-library/hooks/documents'
import { useReprocessDocuments } from '@/service-library/hooks/process-documents'
import queryKeys from '@/service-library/query-keys'
import { handleDownloadOriginalDocument } from '@/utils/download-file'
import {
  showErrorSnackbar,
  showInfoSnackbar,
  showSuccessSnackbar,
} from '@/utils/snackbars'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useDocumentListContext } from './DocumentListProvider'

export default function FailedDocumentInfo() {
  const { document, queryKey } = useDocumentContext()
  const { goToNextDocument } = useDocumentListContext()
  const { deleteDocument } = useDeleteDocument({
    sideEffectQueryKeys: [queryKeys.documents.lists()],
    onSuccess: () => {
      showSuccessSnackbar('Document Deleted')
    },
    onError: () => {
      showErrorSnackbar('Failed to delete document. Please try again later.')
    },
  })
  const { reprocessDocuments, isLoading: isReprocessing } =
    useReprocessDocuments({
      sideEffectQueryKeys: [
        queryKeys.documents.lists(),
        queryKeys.documentWorkflowStates.details(),
        queryKey,
      ],
      onSuccess: () => {
        showInfoSnackbar('Reprocessing Started')
      },
      onError: () => {
        showErrorSnackbar(
          'Failed to reprocess document. Please try again later.',
        )
      },
    })

  return document ? (
    <Box px={3}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Description />
        <Typography>{document.name}</Typography>
      </Stack>
      <Typography sx={{ py: 2 }}>
        This document failed during processing. You can delete or reprocess it.
        If it continues to fail, please contact support.
      </Typography>
      <Stack direction="row" spacing={2} flexWrap="wrap">
        <Button
          disabled={isReprocessing}
          variant="text"
          color="error"
          onClick={() => {
            deleteDocument(document.id)
            goToNextDocument()
          }}
          startIcon={<Delete />}
        >
          Delete
        </Button>
        <LoadingButton
          loading={isReprocessing}
          variant="text"
          onClick={() => {
            reprocessDocuments([
              {
                id: document.id,
                document_id: document.id,
              },
            ])
          }}
          startIcon={<Refresh />}
        >
          Reprocess
        </LoadingButton>

        <CopyIDButton disabled={isReprocessing} stringToCopy={document.id} />
        <Button
          color="neutral"
          disabled={isReprocessing}
          onClick={() => {
            handleDownloadOriginalDocument(document.id)
          }}
          startIcon={<Download />}
          variant="text"
        >
          Download
        </Button>
      </Stack>
    </Box>
  ) : null
}
