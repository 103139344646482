import { useCreateDataListWithDefaults } from '@/service-library/hooks/data-lists'
import { DataList } from '@/types/data-lists'
import { Organization } from '@/types/organizations'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import {
  Button,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'

type ListIsInheritingMessageProps = {
  parentOrg: Organization
  dataList: DataList
  selectedOrg: Organization | null
  setSelectedOrg: Dispatch<SetStateAction<Organization | null>>
  detailQueryKey: QueryKey
  isCustomList: boolean
}

export default function ListIsInheritingMessage({
  dataList,
  parentOrg,
  selectedOrg,
  setSelectedOrg,
  detailQueryKey,
  isCustomList,
}: ListIsInheritingMessageProps) {
  const { id: parentListId } = useParams<{ id: string }>()

  const { createDataList, isLoading } = useCreateDataListWithDefaults({
    sideEffectQueryKeys: [detailQueryKey],
    onError: () => {
      showErrorSnackbar('Unable to create list. Please try again later.')
    },
  })

  function handleCreateSeparateDataList() {
    createDataList({
      id: generateUuid(),
      name: `${dataList.name} (${selectedOrg?.name})`,
      parent_data_list_id: parentListId,
      org_id: selectedOrg?.id as string,
      data_list_type_id: dataList.data_list_type_id,
    })
  }

  return parentOrg ? (
    <Stack alignItems="center" sx={{ py: 4, flexGrow: 1 }} spacing={2}>
      <Typography>
        This organization is inheriting its list data from{' '}
        <Link
          onClick={() => {
            setSelectedOrg(parentOrg)
          }}
        >
          {parentOrg?.name}
        </Link>
        .
      </Typography>
      {isCustomList && (
        <Button
          variant="text"
          onClick={handleCreateSeparateDataList}
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
        >
          Create Separate Data for this Organization
        </Button>
      )}
    </Stack>
  ) : null
}
