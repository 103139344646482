import { Stack, Typography } from '@mui/material'
import { DocumentRowValue } from '@/types/documents'
import FieldInfoLabeledValue from './FieldInfoLabeledValue'

type AddressDataProps = {
  jsonValue: NonNullable<DocumentRowValue['final_value_json']>
}

// Formatted like this so that we can use FieldInfoLabeledValue
const addressParts = {
  street1: {
    name: 'Address Line 1',
  },
  street2: {
    name: 'Address Line 2',
  },
  city: {
    name: 'City',
  },
  county: {
    name: 'County',
  },
  state: {
    name: 'State',
  },
  zip: {
    name: 'Zip Code',
  },
  secondary_designator: {
    name: 'Secondary Designator',
  },
  secondary_number: {
    name: 'Secondary Number',
  },
}

export default function AddressData({ jsonValue }: AddressDataProps) {
  return (
    <Stack
      flexGrow={1}
      spacing={1.5}
      py={1.5}
      px={1}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Typography component="h3" variant="subtitle1" sx={{ px: 0.5 }}>
        Address Information
      </Typography>
      {Object.entries(addressParts).map(([key, label]) => {
        return (
          <FieldInfoLabeledValue
            key={key}
            label={label}
            value={jsonValue.us_address[key as keyof typeof addressParts]}
          />
        )
      })}
    </Stack>
  )
}
