import { useParams } from 'react-router-dom'
import { Button, Card, Stack, Typography } from '@mui/material'
import { Organization } from '@/types/organizations'
import { useGetOrgProjectModel } from '@/service-library/hooks/project-models'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { useSelectedOrgContext } from './SelectedOrgProvider'

type IsInheritingMessageProps = {
  parentOrg: Organization
}

export default function IsInheritingMessage({
  parentOrg,
}: IsInheritingMessageProps) {
  const { id } = useParams()
  const { rootOrganization } = useRootOrganization()
  const { setSelectedOrg } = useSelectedOrgContext()

  const { projectModel } = useGetOrgProjectModel({
    id: id || '',
    orgId: rootOrganization.id,
    filters: {
      fields__only: 'project_grid_fields',
    },
  })

  const field = projectModel?.project_grid_fields[0]
  const dataListId =
    field?.metadata?.data_list_id || field?.params?.data_list_id || ''

  return (
    <Stack sx={{ p: 4, width: '100%' }} alignItems="center">
      <Card sx={{ maxWidth: 500, p: 4 }} elevation={0}>
        <Stack alignItems="center" justifyContent="center" spacing={2}>
          <Typography textAlign="center">
            This organization is inheriting its model from its parent
            organization.
          </Typography>

          <Button
            variant="text"
            onClick={() => {
              setSelectedOrg(parentOrg)
            }}
          >
            View Parent Model
          </Button>

          <Typography textAlign="center">
            To create a model for this organization, this organization needs its
            own data for the list used by the model.
          </Typography>

          <Button
            variant="text"
            component="a"
            href={`/settings/lists/${dataListId}`}
            target="_blank"
          >
            View the List
          </Button>
        </Stack>
      </Card>
    </Stack>
  )
}
