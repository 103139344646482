import { useParams, Link } from 'react-router-dom'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { Alert, Stack, Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import DataListDetails from './DataListDetails'
import { Organization } from '@/types/organizations'
import { useState } from 'react'
import {
  useGetChildDataList,
  useGetDataList,
} from '@/service-library/hooks/data-lists'

export default function DataListPage() {
  const { id } = useParams<{ id: string }>()

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null)

  const { dataList: parentDataList, queryKey: rootDetailQueryKey } =
    useGetDataList({
      id: id as string,
      filters: {
        fields__include: 'description',
      },
    })

  const { dataList, isLoading, queryKey } = useGetChildDataList({
    orgId: selectedOrg?.id || '',
    id: id as string,
    enabled: !!selectedOrg && !!id,
  })

  return (
    <Stack sx={{ px: 1, height: '100%' }}>
      {parentDataList && (
        <Breadcrumb label={parentDataList.name} url={`/settings/lists/${id}`} />
      )}

      {!isLoading && !parentDataList && (
        <Alert severity="error">
          Unable to load this data list. You may not have access or this data
          list does not exist.
        </Alert>
      )}

      <div>
        <Button
          startIcon={<ArrowBack />}
          component={Link}
          to="../"
          variant="text"
          sx={{ ml: '-4px', mb: 1 }}
        >
          Back to Lists
        </Button>
      </div>
      <DataListDetails
        parentDataList={parentDataList}
        dataList={dataList}
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        detailQueryKey={queryKey}
        rootDetailQueryKey={rootDetailQueryKey}
      />
    </Stack>
  )
}
