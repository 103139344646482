import { createContext, ReactNode, useContext } from 'react'
import { Project } from '@/types/projects'
import { QueryKey } from '@tanstack/react-query'

type ProjectProviderValue = { project: Project; queryKey: QueryKey }

const ProjectContext = createContext<ProjectProviderValue>(
  {} as ProjectProviderValue,
)

export const useProjectContext = () => useContext(ProjectContext)

type ProjectProviderProps = {
  children: ReactNode
  project: Project
  queryKey: QueryKey
}

export default function ProjectProvider({
  children,
  project,
  queryKey,
}: ProjectProviderProps) {
  return (
    <ProjectContext.Provider value={{ project, queryKey }}>
      {children}
    </ProjectContext.Provider>
  )
}
