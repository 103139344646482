import { FlagType } from '@/types/flags'
import { useTheme } from '@mui/material'

type UseFlagColorMapReturnType = Record<
  FlagType['code'],
  { background: string; base: string }
>
export default function useFlagColorMap(): UseFlagColorMapReturnType {
  const theme = useTheme()
  return {
    error: { background: '#311b17', base: theme.palette.error.main },
    warning: {
      background: '#322617',
      base: theme.palette.warning.main,
    },
    info: { background: '#323232', base: theme.palette.text.primary },
  }
}
