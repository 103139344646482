import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChevronRight, ExpandMore } from '@mui/icons-material'
import { Stack, Box } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { createOrganizationMap, getNodesToExpand } from '@/utils/organization'
import OrganizationTreeItem from '@/components/organizations-tree/OrganizationTreeItem'
import { OrgTreeSkeleton } from '@/components/organizations-tree/OrganizationTreeSearchSkeleton'
import { useRootOrganization } from './RootOrganizationProvider'
import OrganizationPage from './OrganizationPage'

export default function OrganizationsDetails() {
  const { '*': id } = useParams()
  const navigate = useNavigate()

  const { rootOrganization } = useRootOrganization()
  const { organizations, isFetchingAll } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
  })

  const selectedOrg = organizations.find((org) => org.id === id)

  const organizationMap = useMemo(
    () => (isFetchingAll ? {} : createOrganizationMap(organizations)),
    [isFetchingAll, organizations],
  )
  const [expanded, setExpanded] = useState([rootOrganization.id])

  useEffect(() => {
    if (!id) navigate(rootOrganization.id)
  }, [id, navigate, rootOrganization.id])

  useEffect(() => {
    if (
      !isFetchingAll &&
      selectedOrg &&
      selectedOrg.id !== rootOrganization.id &&
      !expanded.includes(selectedOrg.parent_org_id || '')
    ) {
      setExpanded(getNodesToExpand(selectedOrg.id, organizationMap))
    }
  }, [
    expanded,
    id,
    isFetchingAll,
    organizationMap,
    rootOrganization.id,
    selectedOrg,
  ])

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        // Since every container will have its own scroll "space", we use this to ignore
        // the default padding that global settings have
        m: -2,
        mt: -3,
        height: 'calc(100vh - 50px)', // Subtracting 50px to account for the app top bar
      }}
    >
      <Box sx={{ width: 300, overflow: 'auto', p: 2, pt: 3 }}>
        {isFetchingAll ? (
          <OrgTreeSkeleton />
        ) : (
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            selected={id || rootOrganization.id}
            onNodeSelect={(_e, id) => {
              navigate(id)
            }}
            onNodeToggle={(_e, ids) => {
              setExpanded(ids)
            }}
          >
            <OrganizationTreeItem
              orgTree={organizationMap[rootOrganization.id]}
            />
          </TreeView>
        )}
      </Box>
      {id && (
        <OrganizationPage organization={selectedOrg} organizationId={id} />
      )}
    </Stack>
  )
}
