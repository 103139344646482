import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { MetricsProjectModelVersion } from '@/types/project-models'
import { OverlayState } from '@/hooks/useOverlay'
import { convertSecondsToHours } from '@/utils/getFormattedDateTimeString'
import GeneralInfoBox from '@/components/data-visualization/GeneralInfoBox'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import FieldAccuracyChart from './FieldAccuracyChart'
import CategoryModelItemCountChart from './CategoryModelItemCountChart'
import { Project } from '@/types/projects'

type ModelVersionsDialogProps = {
  project: Project
  isCategoryModel: boolean
  modelVersions: MetricsProjectModelVersion[]
  overlay: OverlayState
  projectId: string | undefined
  metricsUrl?: string
}

export default function ModelVersionsDialog({
  project,
  isCategoryModel,
  modelVersions,
  overlay,
  metricsUrl,
}: ModelVersionsDialogProps) {
  const [modelVersion, setModelVersion] = useState(modelVersions[0])

  const allVersionsAreZero = modelVersions.every(
    (version) => version.version === 0,
  )
  const sortedModelVersions = allVersionsAreZero
    ? modelVersions
    : modelVersions.toSorted(
        (versionA, versionB) => versionA.version - versionB.version,
      )

  useEffect(() => {
    setModelVersion(modelVersions[0])
  }, [modelVersions, overlay.isOpen])

  return (
    <Dialog
      title={`Model Version${metricsUrl ? '' : 's'}`}
      maxWidth="lg"
      {...overlay}
    >
      <DialogContent disablePadding>
        <Stack direction="row" sx={{ px: 2, height: 500, overflowX: 'hidden' }}>
          {!metricsUrl && (
            <>
              <List sx={{ overflow: 'auto', maxHeight: 440, width: 100 }}>
                {sortedModelVersions.map((version, index) => (
                  <ListItemButton
                    component="li"
                    key={version.id}
                    onClick={() => setModelVersion(version)}
                    selected={version.id === modelVersion.id}
                  >
                    <ListItemText
                      primary={`v${
                        allVersionsAreZero
                          ? `${index + 1}.0`
                          : Number.isInteger(version.version)
                          ? `${version.version}.0`
                          : version.version
                      }`}
                    />
                  </ListItemButton>
                ))}
              </List>
              <Divider flexItem orientation="vertical" variant="middle" />
            </>
          )}
          <Stack sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} mx={2} my={1}>
              <Grid xs={4}>
                <GeneralInfoBox
                  label="Accuracy"
                  value={`${Math.floor(+modelVersion.accuracy * 100)}%`}
                />
              </Grid>
              <Grid xs={4}>
                <GeneralInfoBox
                  label="Documents Used to Train"
                  value={modelVersion.document_count || 0}
                />
              </Grid>
              <Grid xs={4}>
                <GeneralInfoBox
                  label="Training Duration"
                  value={convertSecondsToHours(modelVersion.training_duration)}
                />
              </Grid>
            </Grid>
            <Box sx={{ pl: 2 }}>
              {isCategoryModel ? (
                <CategoryModelItemCountChart
                  modelVersion={modelVersion}
                  enabled={overlay.isOpen}
                  isForVersionMetricsPage
                />
              ) : (
                <FieldAccuracyChart
                  modelVersion={modelVersion}
                  enabled={overlay.isOpen}
                  project={project}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      {!!metricsUrl && (
        <DialogFooter>
          <Button component={Link} to={metricsUrl} variant="text">
            See More Metrics
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  )
}
