import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useDocumentListContext } from './DocumentListProvider'

export default function ValidationDrawerSortBy() {
  const { ordering, setOrdering } = useDocumentListContext()

  const handleChange = (checked: boolean, key: string) => {
    if (checked) {
      setOrdering((prev) => [...prev, key])
    } else {
      setOrdering((prev) => prev.filter((orderKey) => orderKey !== key))
    }
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body1">
        <b>Sort By</b>
      </Typography>
      <Stack spacing={0.5}>
        <FormControlLabel
          label="Processed Date"
          control={
            <Checkbox
              checked={ordering?.includes('-pre_process_completed_at')}
              color="neutral"
              onChange={(_e, checked) => {
                handleChange(checked, '-pre_process_completed_at')
              }}
              sx={{ padding: 0 }}
            />
          }
          componentsProps={{ typography: { variant: 'body2' } }}
          sx={{ mx: 0, columnGap: 0.5, width: 'fit-content' }}
        />
        <FormControlLabel
          label="Workflow State"
          control={
            <Checkbox
              checked={ordering?.includes('workflow_states__sort_order')}
              color="neutral"
              onChange={(_e, checked) => {
                handleChange(checked, 'workflow_states__sort_order')
              }}
              sx={{ padding: 0 }}
            />
          }
          componentsProps={{ typography: { variant: 'body2' } }}
          sx={{ mx: 0, columnGap: 0.5, width: 'fit-content' }}
        />
      </Stack>
    </Stack>
  )
}
