import { createContext, ReactNode, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Workflow } from '@/types/workflows'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import { useWorkflowContext } from '@/components/workflow-provider/WorkflowProvider'

type SelectedWorkflowContextValue = {
  workflows: Workflow[]
  selectedWorkflow: Workflow
  setSelectedWorkflow: (newWorkflowId: string) => void
  resetSelectedWorkflow: () => void
}

const SelectedWorkflowContext = createContext<SelectedWorkflowContextValue>(
  {} as SelectedWorkflowContextValue,
)

export const useSelectedWorkflowContext = () =>
  useContext(SelectedWorkflowContext)

type SelectedWorkflowProviderProps = {
  children: ReactNode
  defaultWorkflowId?: string
}

export default function SelectedWorkflowProvider({
  children,
  defaultWorkflowId,
}: SelectedWorkflowProviderProps) {
  const { workflows, productionDataWorkflow, isLoading } = useWorkflowContext()
  const { pathname } = useLocation()
  const workflowId = defaultWorkflowId || productionDataWorkflow?.id

  const {
    value: selectedWorkflowId,
    toggleValue: setSelectedWorkflowId,
    resetValue,
  } = useStatefulSearchParam({
    param: 'workflow',
    defaultValue: workflowId,
    fieldsToClearOnChange: ['workflow_states', 'workflow_state'],
  })

  useEffect(() => {
    if (
      (pathname.includes('data') || pathname.includes('documents')) &&
      selectedWorkflowId !== workflowId
    ) {
      resetValue()
    }
  })

  return isLoading ? null : (
    <SelectedWorkflowContext.Provider
      value={{
        workflows,
        selectedWorkflow: workflows.find(
          ({ id }) => id === selectedWorkflowId,
        ) as Workflow,
        setSelectedWorkflow: setSelectedWorkflowId,
        resetSelectedWorkflow: resetValue,
      }}
    >
      {children}
    </SelectedWorkflowContext.Provider>
  )
}
