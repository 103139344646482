import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { User } from '@/types/users'
import {
  createDetail,
  getDetail,
  updateDetail,
  updateDetailPut,
} from '../request-wrappers'
import useGet, { UseGetOptions } from '../core-hooks/useGet'

const service = buildService<User>({
  pathCategory: 'users',
})

export function useGetUser({ id, ...options }: UseGetDetailOptions<User>) {
  const query = useGetDetail<User>({
    id,
    queryKey: queryKeys.users.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    user: query.data,
    ...query,
  }
}

export function useCreateUser(options: UseCreateDetailOptions<User> = {}) {
  const mutation = useCreateDetail<User>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateUser(options: UseUpdateDetailOptions<User> = {}) {
  const mutation = useUpdateDetail<User>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteUser({ ...options }: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<User>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteUser: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetUsers({
  filters,
  ...options
}: UseGetListOptions<User> = {}) {
  const query = useGetList<User>({
    filters,
    queryKey: queryKeys.users.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    users: query.allData,
    ...query,
  }
}

export function useUpdateUsers(options: UseUpdateListOptions<User> = {}) {
  const mutation = useUpdateList<User>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateUsers: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteUsers({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<User>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteUsers: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Get Current User
 * Get the current user.
 */
export function useGetCurrentUser({ ...options }: UseGetOptions<User>) {
  const query = useGet<User>({
    queryKey: queryKeys.dataLists.detail({
      id: 'current',
      filters: options.filters,
    }),
    serviceFn: (serviceFnOptions) => {
      return getDetail<User>({
        url: '/v2/pd/users/current',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    user: query.data,
    ...query,
  }
}

/**
 * MARK: Action Hook: Register User
 * Used to register a new user in aws and in the database.
 */
export function useRegisterUser(
  options: UseCreateDetailOptions<Partial<User>> = {},
) {
  const mutation = useCreateDetail<Partial<User>>({
    serviceFn: (serviceFnOptions) => {
      return createDetail<Partial<User>, User>({
        url: '/v2/pd/users/register',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    registerUser: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Activate/Deactivate User
 * Used to activate or deactivate a user.
 */
export function useActivateDeactivateUser(
  options: UseUpdateDetailOptions<User> = {},
) {
  const mutation = useUpdateDetail<User>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      let url = `/v2/pd/users/${item.id}/`
      url += !item.is_active ? 'deactivate' : 'activate'
      return updateDetailPut<User>({
        url,
        item,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    activateDeactivateUser: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Update Current User
 * Used to update the current user (self) email in aws and in the database.
 */
export function useUpdateCurrentUser(
  options: UseUpdateDetailOptions<User> = {},
) {
  const mutation = useUpdateDetail<User>({
    serviceFn: (serviceFnOptions) => {
      return updateDetail<User>({
        url: '/v2/pd/users/update-self',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    updateCurrentUser: mutation.mutateAsync,
    ...mutation,
  }
}
