import { useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { Box, Typography, useTheme } from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ProjectGrid } from '@/types/projects'
import { WorkflowState } from '@/types/workflows'
import { getBaseGrid } from '@/utils/field-utils'
import getColumnFilterModeOptions from '@/utils/getColumnFilterModeOptions'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import CustomFilterComponent from '@/components/workflows/CustomFilterComponent'
import DocumentRowIndicators from '@/components/workflows/DocumentRowIndicators'
import { useGetDocumentWorkflowStates } from '@/service-library/hooks/document-workflow-states'

type UseDocumentColumnsOptions = {
  workflowState: WorkflowState
  showRowTypes?: boolean
}

export default function useDocumentColumns({
  workflowState,
  showRowTypes,
}: UseDocumentColumnsOptions) {
  const theme = useTheme()

  const { documentWorkflowStates = [] } = useGetDocumentWorkflowStates({
    filters: {
      limit: '1000',
      workflow_state_id: workflowState.id,
      status__in: 'error,exporting',
    },
    enabled: workflowState.code === 'ready_for_export',
  })

  const { project } = useProjectContext()
  const baseGrid = getBaseGrid(project.project_grids as ProjectGrid[])

  const { organizations } = useOrganizationsContext()

  const organizationsMap: Record<string, string> = organizations.reduce(
    (acc, organization) => ({ ...acc, [organization.id]: organization.name }),
    {},
  )

  const columns = useMemo<MRT_ColumnDef<Required<DocumentRow>>[]>(() => {
    return [
      {
        id: 'indicators',
        Cell: ({ row }) => {
          const documentWorkflowState = documentWorkflowStates.find(
            ({ document_id }) => row.original.document.id === document_id,
          )
          return (
            <DocumentRowIndicators
              workflowState={workflowState}
              documentWorkflowState={documentWorkflowState}
              document={row.original.document}
              showPrimaryIndicators={
                row.original.project_grid_id === baseGrid?.id
              }
            />
          )
        },
        header: '',
        enableColumnOrdering: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableResizing: false,
        enableSorting: false,
        minSize: 0,
        size: 40,
        muiTableHeadCellProps: {
          sx: {
            borderBottom: 'none',
            boxShadow: 'none',
            justifyContent: 'center',
            flex: '0 0 auto',
            padding: 0,
          },
        },
        muiTableBodyCellProps: {
          sx: {
            boxShadow: 'none',
            borderBottom: 'none',
            justifyContent: 'center',
            flex: '0 0 auto',
            padding: 0,
          },
        },
      },
      {
        id: 'fileColId',
        accessorFn: (row) => row.document.name,
        columnFilterModeOptions: getColumnFilterModeOptions('fileColId'),
        header: 'Document',
      },
      {
        id: 'organizationColId',
        accessorFn: (row) => organizationsMap[row.document.owner_org_id],
        columnFilterModeOptions:
          getColumnFilterModeOptions('organizationColId'),
        header: 'Organization',
      },
      {
        id: 'submitterColId',
        accessorFn: (row) => row.document.created_by_user?.name,
        columnFilterModeOptions: getColumnFilterModeOptions('submitterColId'),
        header: 'Submitter',
      },
      {
        id: 'pageCountColId',
        accessorFn: (row) => row.document.page_count,
        columnFilterModeOptions: getColumnFilterModeOptions('pageCountColId'),
        header: 'Page Count',
      },
      {
        id: 'uploadColId',
        accessorFn: (row) => prettifyDate(row.document.created_at as string),
        columnFilterModeOptions: getColumnFilterModeOptions('uploadColId'),
        Filter: (props) => (
          <CustomFilterComponent fieldTypeCode="datetime" {...props} />
        ),
        header: 'Upload Date',
      },
      {
        id: 'processedColId',
        accessorFn: (row) =>
          prettifyDate(row.document.pre_process_completed_at),
        columnFilterModeOptions: getColumnFilterModeOptions('processedColId'),
        Filter: (props) => (
          <CustomFilterComponent fieldTypeCode="datetime" {...props} />
        ),
        header: 'Processed Date',
      },
    ]
  }, [baseGrid?.id, documentWorkflowStates, organizationsMap, workflowState])

  if (showRowTypes) {
    return [
      ...columns.slice(0, 1),
      {
        id: 'rowOrBaseId',
        enableColumnOrdering: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableColumnFilterModes: false,
        enableSorting: false,
        Cell: ({ row }) => {
          const projectGrid = project.project_grids?.find(
            ({ id }) => id === row.original.project_grid_id,
          )
          const { sub_project_grid_fields = [] } = projectGrid || {}
          const label =
            sub_project_grid_fields.length > 0
              ? sub_project_grid_fields[0].name
              : 'Document'
          return (
            <Box
              sx={{
                borderRadius: 1,
                border: `solid 1px ${theme.palette.divider}`,
                px: 0.5,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {label}
              </Typography>
            </Box>
          )
        },
        header: 'Row Type',
      } as MRT_ColumnDef<Required<DocumentRow>>,
      ...columns.slice(1),
    ]
  }

  return columns
}
