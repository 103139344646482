import { useRef, useEffect } from 'react'
import Drawer from '@/components/drawer/Drawer'
import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import { FilterList, Menu, MenuOpen } from '@mui/icons-material'
import {
  List,
  ListItem,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Divider,
  Stack,
  Skeleton,
} from '@mui/material'
import { Document } from '@/types/documents'
import { showErrorSnackbar } from '@/utils/snackbars'
import ValidationDrawerFilterPopover from './ValidationDrawerFilterPopover'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import ValidationDrawerItem from './ValidationDrawerItem'
import SelectMenu from '@/components/select-menu/SelectMenu'
import { Workflow } from '@/types/workflows'
import { useDocumentListContext } from './DocumentListProvider'
import ValidationMiniNavControls from './ValidationMiniNavControls'
import PageCount from '../image-zoom-pan/PageCount'

type ValidationDrawerProps = {
  overlay: OverlayState
  documentId: string
  fullDocument?: Document
}

export default function ValidationDrawer({
  overlay,
  documentId,
  fullDocument,
}: ValidationDrawerProps) {
  const {
    documents = [],
    isInitialLoading,
    isSetList,
    fetchPreviousPage,
    fetchNextPage,
    hasPreviousPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
    isFetchingPreviousPage,
    selectedIndex,
    documentsCount,
  } = useDocumentListContext()

  const isInList = documents.some((document) => document.id === documentId)

  const filterOverlay = useOverlay()
  const filterAnchorEl = useRef<HTMLButtonElement>(null)

  const { selectedWorkflow, workflows, setSelectedWorkflow } =
    useSelectedWorkflowContext()

  // Dictates if newly added items should animate in
  const shouldAnimateRef = useRef(false)
  useEffect(() => {
    // We use a ref here because we don't want changing shouldAnimate to trigger a re-render
    shouldAnimateRef.current = !isLoading
  }, [isLoading])

  const scrollerRef = useRef<HTMLDivElement>()
  // If the user scrolls to the top or bottom of the list, fetch more documents if there are more pages
  useEffect(() => {
    const scroller = scrollerRef.current
    // We don't try to fetch for more when it's a fixed list
    if (isSetList || !scroller) return

    function handleScroll() {
      if (!scroller) return
      const { scrollHeight, scrollTop, clientHeight } = scroller
      const isBottom = scrollHeight - scrollTop - clientHeight < 5
      const isTop = scrollTop === 0

      if (isBottom && hasNextPage) {
        fetchNextPage()
      } else if (isTop && hasPreviousPage) {
        fetchPreviousPage()
      }
    }

    scroller.addEventListener('scroll', handleScroll)
    return () => {
      scroller.removeEventListener('scroll', handleScroll)
    }
  }, [
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isSetList,
  ])

  // If the list is shorter than the viewport, fetch more until we fill the viewport height or run out of pages
  useEffect(() => {
    // We don't try to fetch for more when it's a fixed list
    if (isSetList || !scrollerRef.current) return
    const isShorterThanViewport =
      scrollerRef.current.scrollHeight < window.document.body.clientHeight
    if (hasNextPage && isShorterThanViewport) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isSetList])

  useEffect(() => {
    if (isError) {
      showErrorSnackbar('There was an error. Please contact support.')
    }
  }, [isError])

  return (
    <Drawer
      overlay={overlay}
      size="sm"
      title="Documents"
      showToggleButton
      showMiniDrawer
      toggleOpenIcon={<Menu />}
      toggleCloseIcon={<MenuOpen />}
      miniDrawerChildren={
        <Stack>
          <Box sx={{ flexGrow: 1 }} />
          <ValidationMiniNavControls />
        </Stack>
      }
    >
      <Stack sx={{ height: '100%' }}>
        {selectedWorkflow && (
          <>
            <Divider sx={{ mt: 1 }} />
            <ValidationDrawerFilterPopover
              overlay={filterOverlay}
              anchorEl={filterAnchorEl.current}
            />

            <Stack direction="row" alignItems="center">
              <SelectMenu<Workflow>
                title="Workflow"
                selectedLabel={selectedWorkflow.name}
                onChange={(newWorkflow) => setSelectedWorkflow(newWorkflow.id)}
                options={workflows.map((workflow) => ({
                  key: workflow.id,
                  label: workflow.name,
                  value: workflow,
                }))}
              />

              <IconButton
                size="small"
                sx={{ mx: 1 }}
                onClick={filterOverlay.toggle}
                ref={filterAnchorEl}
              >
                <FilterList />
              </IconButton>
            </Stack>
          </>
        )}

        <Divider />

        <Box
          sx={{ overflowY: 'auto', width: '100%', pt: 0.5, pb: 2, flexGrow: 1 }}
          ref={scrollerRef}
        >
          <List disablePadding>
            {fullDocument && !isInList && (
              <>
                <ValidationDrawerItem
                  document={fullDocument}
                  currentDocumentId={documentId}
                  isHiddenItem
                />
                <Divider sx={{ my: 0.5 }} />
              </>
            )}

            {isInitialLoading && (
              <Typography align="center" p={8}>
                <CircularProgress />
              </Typography>
            )}

            {isFetchingPreviousPage && (
              <>
                <Skeleton width="80%" sx={{ mx: 2, mt: 0.5, mb: 0.25 }} />
                <Skeleton height={16} width="60%" sx={{ mx: 2, my: 0.5 }} />
              </>
            )}

            {!isInitialLoading && documents.length === 0 && (
              <ListItem>
                <Typography variant="caption" color="text.secondary">
                  <i>No Documents</i>
                </Typography>
              </ListItem>
            )}

            {documents.length > 0 &&
              documents.map((document) => (
                <ValidationDrawerItem
                  key={document.id}
                  document={document}
                  currentDocumentId={documentId}
                  shouldAnimate={shouldAnimateRef.current}
                />
              ))}

            {isFetchingNextPage && (
              <>
                <Skeleton width="80%" sx={{ mx: 2, mt: 0.5, mb: 0.25 }} />
                <Skeleton height={16} width="60%" sx={{ mx: 2, my: 0.5 }} />
              </>
            )}
          </List>
        </Box>

        <Divider />

        <Stack sx={{ p: 1, alignItems: 'center' }}>
          <PageCount page={selectedIndex + 1} total={documentsCount} />
        </Stack>
      </Stack>
    </Drawer>
  )
}
