import { createContext, ReactNode, useContext, useEffect } from 'react'
import { WorkflowState } from '@/types/workflows'
import useListSearchParam from '@/hooks/useListSearchParam'
import useStatefulSearchParam from '@/hooks/useStatefulSearchParam'
import { useSelectedWorkflowContext } from './SelectedWorkflowProvider'
import { sortBySortOrder } from '@/utils/field-utils'

type SelectedWorkflowStatesContextValue = {
  workflowStates: WorkflowState[]
  selectedWorkflowStates: WorkflowState[]
  toggleWorkflowState: (workflowStateId: string, checked: boolean) => void
  resetWorkflowStates: () => void
}

const SelectedWorkflowStatesContext =
  createContext<SelectedWorkflowStatesContextValue>(
    {} as SelectedWorkflowStatesContextValue,
  )

export const useSelectedWorkflowStatesContext = () =>
  useContext(SelectedWorkflowStatesContext)

type SelectedWorkflowStatesProviderProps = {
  children: ReactNode
  list?: boolean
}

type SelectedWorkflowStatesChildProviderProps = {
  children: ReactNode
  workflowStates: WorkflowState[]
}

function SelectedListWorkflowStatesProvider({
  children,
  workflowStates,
}: SelectedWorkflowStatesChildProviderProps) {
  const {
    values,
    toggleValue: toggleWorkflowStateId,
    resetValue,
  } = useListSearchParam({
    param: 'workflow_states',
    defaultValue: workflowStates.map(({ id }) => id) ?? [],
  })

  const selectedWorkflowStateIds = Object.entries(values)
    .filter(([, value]) => value)
    .map(([key]) => key)

  return (
    <SelectedWorkflowStatesContext.Provider
      value={{
        workflowStates,
        selectedWorkflowStates: workflowStates.filter((state) =>
          selectedWorkflowStateIds.includes(state.id),
        ),
        toggleWorkflowState: toggleWorkflowStateId,
        resetWorkflowStates: resetValue,
      }}
    >
      {children}
    </SelectedWorkflowStatesContext.Provider>
  )
}

function SelectedWorkflowStateProvider({
  children,
  workflowStates,
}: SelectedWorkflowStatesChildProviderProps) {
  const defaultState = workflowStates.find(
    ({ code }) => code !== 'processing', // We skip processing
  ) as WorkflowState
  const {
    value: workflowStateIdInUrl,
    toggleValue: toggleWorkflowStateId,
    resetValue,
  } = useStatefulSearchParam({
    param: 'workflow_state',
    defaultValue: defaultState.id,
  })

  const selectedWorkflowState = workflowStates.find(
    ({ id }) => id === workflowStateIdInUrl,
  )

  useEffect(() => {
    if (!selectedWorkflowState) {
      resetValue()
    }
  }, [selectedWorkflowState, workflowStateIdInUrl, resetValue])

  return (
    <SelectedWorkflowStatesContext.Provider
      value={{
        workflowStates,
        selectedWorkflowStates: [selectedWorkflowState || defaultState],
        toggleWorkflowState: toggleWorkflowStateId,
        resetWorkflowStates: resetValue,
      }}
    >
      {children}
    </SelectedWorkflowStatesContext.Provider>
  )
}

export default function SelectedWorkflowStatesProvider({
  children,
  list = true,
}: SelectedWorkflowStatesProviderProps) {
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const workflowStates = sortBySortOrder(
    selectedWorkflow?.workflow_states ?? [],
  )

  return list ? (
    <SelectedListWorkflowStatesProvider workflowStates={workflowStates}>
      {children}
    </SelectedListWorkflowStatesProvider>
  ) : (
    <SelectedWorkflowStateProvider workflowStates={workflowStates}>
      {children}
    </SelectedWorkflowStateProvider>
  )
}
