import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react'
import { Box, Card, Stack, useTheme } from '@mui/material'
import { useDrag } from '@use-gesture/react'
import useLocalStorage from '@/hooks/useLocalStorage'
import ScrollContainer from '@/components/scroll-container/ScrollContainer'
import SizeProvider from '@/components/size-provider/SizeProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'

type ValidationFieldsPanelProps = {
  layout: 'right' | 'left' | 'top' | 'bottom'
  children: ReactNode
  setFieldsContainerEl: Dispatch<SetStateAction<HTMLDivElement | null>>
}

const positionMap = {
  right: {
    right: 0,
    top: 0,
  },
  left: {
    left: 0,
    top: 0,
  },
  top: {
    left: 0,
    top: 0,
  },
  bottom: {
    left: 0,
    bottom: 0,
  },
}

const flexDirectionMap: Record<
  string,
  'row-reverse' | 'row' | 'column-reverse' | 'column'
> = {
  left: 'row-reverse',
  right: 'row',
  top: 'column-reverse',
  bottom: 'column',
}

export default function ValidationFieldsPanel({
  layout,
  children,
  setFieldsContainerEl,
}: ValidationFieldsPanelProps) {
  const theme = useTheme()
  const { project } = useProjectContext()

  const fieldsPanelRef = useRef<HTMLDivElement>(null)
  const dragDividerRef = useRef<HTMLDivElement>(null)

  const [panelWidth, setPanelWidth] = useLocalStorage(
    `validation-panel-width-${project.id}`,
    '35%',
  )
  const [panelHeight, setPanelHeight] = useLocalStorage(
    `validation-panel-height-${project.id}`,
    '35%',
  )

  const isHorizontal = layout === 'left' || layout === 'right'

  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(({ down, delta: [ox, oy] }) => {
    const pane = fieldsPanelRef.current
    const dragDivider = dragDividerRef.current
    if (!pane || !dragDivider) return
    if (isHorizontal) {
      pane.style.width =
        layout === 'left'
          ? `${pane.clientWidth + ox}px`
          : `${pane.clientWidth - ox}px`
    } else if (!isHorizontal) {
      pane.style.height = `${
        layout === 'top' ? pane.clientHeight + oy : pane.clientHeight - oy
      }px`
    }

    if (!down) {
      // Update it in local storage so it's saved over reloads
      if (isHorizontal) {
        setPanelWidth(pane.style.width)
      } else {
        setPanelHeight(pane.style.height)
      }
    }
  })

  // Reset height or width so it doesn't carry over when we switch layouts
  useEffect(() => {
    if (!isHorizontal) {
      fieldsPanelRef.current?.style.removeProperty('width')
    } else {
      fieldsPanelRef.current?.style.removeProperty('height')
    }
  }, [isHorizontal])

  const position = positionMap[layout]

  return (
    <Card
      ref={fieldsPanelRef}
      square
      elevation={!isHorizontal ? 4 : 0}
      sx={{
        maxHeight: '100%',
        width: isHorizontal ? panelWidth : '100%',
        height: !isHorizontal ? panelHeight : '100%',
        background: !isHorizontal
          ? theme.palette.background.paper
          : 'transparent',
        position: 'absolute',
        minWidth: 300,
        minHeight: 100,
        pointerEvents: 'none',
        zIndex: 4,
        ...position,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          maxHeight: '100%',
        }}
      >
        <ScrollContainer
          componentProps={{
            id: 'validation-fields-scroll-container',
          }}
          sx={{
            direction: layout === 'left' ? 'rtl' : 'ltr',
            padding: isHorizontal ? 2 : 0,
            pt: 0,
            maxHeight: '100%',
            overflow: 'auto',
            pointerEvents: 'auto', // [sc-10864]
          }}
        >
          <SizeProvider>
            <Stack
              direction={flexDirectionMap[layout]}
              sx={{ alignContent: 'stretch', direction: 'ltr' }}
            >
              <Box
                ref={dragDividerRef}
                sx={{
                  width: isHorizontal ? 4 : '100%',
                  height: !isHorizontal ? 4 : undefined,
                  flexShrink: 0,
                  maxHeight: '100%',
                  transition: '0.1s',
                  cursor: isHorizontal ? 'ew-resize' : 'ns-resize',
                  borderRadius: 40,
                  '&:hover': {
                    background: theme.palette.primary.main,
                  },
                  marginTop: isHorizontal ? 2 : undefined,

                  // When in vertical layouts, we need to position the divider so it doesn't disappear when scrolling the fields
                  position: !isHorizontal ? 'absolute' : undefined,
                  top: layout === 'bottom' ? 0 : undefined,
                  bottom: layout === 'top' ? 0 : undefined,
                  zIndex: 2, // So it will appear above tables
                }}
                {...bind()}
              />
              <Stack
                spacing={2}
                ref={(el) => setFieldsContainerEl(el)}
                // Padding top has to be applied here so programmatic scrolling into view doesn't cut off the top
                // Width 100% needed here so it won't expand out of its parent container
                sx={{ flexGrow: 1, pt: 2, width: '100%' }}
              >
                {children}
              </Stack>
            </Stack>
          </SizeProvider>
        </ScrollContainer>
      </Box>
    </Card>
  )
}
