import React, {
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
} from 'react'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth, Hub } from 'aws-amplify'
import { setUserProperties, logEvent, setUserId } from '@firebase/analytics'
import { User } from '@/types/users'
import { analytics } from '@/feature_flags/firebase_config'
import { useGetCurrentUser } from '@/service-library/hooks/users'

async function refreshAndGetIdToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    return new Promise<string>((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: never, session: CognitoUserSession) => {
          if (err) {
            reject(err)
          } else {
            const idToken = session.getIdToken()
            resolve(idToken.getJwtToken())
          }
        },
      )
    })
  } catch (e) {
    console.error('Unable to refresh Token', e)
    throw e
  }
}

type AuthContextValue = {
  authenticated: boolean
  getToken: (onSuccess: (token: string) => void) => void
  getTokenPromise: () => Promise<string>
  getFreshIdToken: () => Promise<string>
  getIdToken: (onSuccess: (token: string) => void) => void
  user: Required<User> | undefined
  userQuery: ReturnType<typeof useGetCurrentUser>
  reloadUser: () => void
  isLoading: boolean
}

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue)

type AuthProviderProps = {
  children: ReactNode
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const [cognitoIsLoading, setCognitoIsLoading] = useState(true)

  const userQuery = useGetCurrentUser({
    enabled: !!cognitoUser?.getUsername(),
  })

  const { user, error, isLoading, refetch } = userQuery

  const getToken = useCallback((onSuccess: (token: string) => void) => {
    Auth.currentSession().then((data) => {
      onSuccess(data.getAccessToken().getJwtToken())
    })
  }, [])

  const getIdToken = useCallback((onSuccess: (token: string) => void) => {
    Auth.currentSession().then((data) => {
      onSuccess(data.getIdToken().getJwtToken())
    })
  }, [])

  const getTokenPromise = useCallback(() => {
    return Auth.currentSession().then((data) => {
      return data.getAccessToken().getJwtToken()
    })
  }, [])

  const reloadUser = useCallback(
    ({ cancelRefetch = true } = {}) => {
      if (cognitoUser !== null) {
        refetch({ cancelRefetch })
      }
    },
    [cognitoUser, refetch],
  )

  useEffect(() => {
    // @ts-expect-error -- Not worth typing
    if (error && error.response.status === 401) {
      refreshAndGetIdToken().catch(() => {
        console.error(error)
      })
    }
  }, [error])

  useEffect(() => {
    const updateUser = async ({
      payload,
    }: {
      payload?: {
        event: string
        data?: CognitoUserSession
        message?: string
      }
    } = {}) => {
      if (payload?.event === 'oAuthSignOut') return
      try {
        const user = payload?.data || (await Auth.currentAuthenticatedUser())
        const email = user.signInUserSession.idToken.payload.email
        // Setup google analytics user for feature flagging.
        // Without this, internal_user condition doesn't work.
        const analyticsResult = await analytics()
        if (analyticsResult) {
          setUserId(analyticsResult, user.username)
          setUserProperties(analyticsResult, {
            email,
            is_internal_user: email.endsWith('@zerapix.com'),
          })
          logEvent(analyticsResult, 'login', { method: 'Amplify' })
        }
        setCognitoUser(user)
      } catch {
        setCognitoUser(null)
      } finally {
        setCognitoIsLoading(false)
      }
    }

    Hub.listen('auth', updateUser)
    updateUser()
    return () => Hub.remove('auth', updateUser)
  }, [])

  useEffect(() => {
    reloadUser({ cancelRefetch: false })
  }, [reloadUser])

  const auth = useMemo(() => {
    return {
      authenticated: cognitoUser !== null && user !== null,
      getToken,
      getTokenPromise,
      getFreshIdToken: refreshAndGetIdToken,
      getIdToken,
      user: user as Required<User>,
      userQuery,
      reloadUser,
      isLoading: cognitoUser ? isLoading : cognitoIsLoading,
    }
  }, [
    cognitoUser,
    user,
    getToken,
    getTokenPromise,
    getIdToken,
    userQuery,
    reloadUser,
    isLoading,
    cognitoIsLoading,
  ])

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuthentication() {
  const context = React.useContext(AuthContext)
  // if (context === undefined) {
  //   throw new Error('useAuth must be used within an AuthProvider')
  // }

  return context || {}
}
