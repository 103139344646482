import { DocumentFlag, RowValueFlag } from '@/types/flags'

export const getFlagsCount = (flags: (RowValueFlag | DocumentFlag)[]) => {
  return {
    error: flags.filter(({ flag_type }) => flag_type.code === 'error').length,
    warning: flags.filter(({ flag_type }) => flag_type.code === 'warning')
      .length,
    info: flags.filter(({ flag_type }) => flag_type.code === 'info').length,
  }
}

export const getFlagsByType = (flags: (RowValueFlag | DocumentFlag)[]) => {
  return {
    error: flags.filter(({ flag_type }) => flag_type.code === 'error'),
    warning: flags.filter(({ flag_type }) => flag_type.code === 'warning'),
    info: flags.filter(({ flag_type }) => flag_type.code === 'info'),
  }
}

export const getFlagsCountFromType = (
  flagsByType: ReturnType<typeof getFlagsByType>,
) => {
  return {
    error: flagsByType.error.length,
    warning: flagsByType.warning.length,
    info: flagsByType.warning.length,
  }
}
