import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogFooter } from '@/components/dialog'
import DocumentImagePageProvider from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import DocumentProvider from '@/components/image-zoom-pan/providers/DocumentProvider'
import ImageZoomPanCenterProvider from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import DocumentsControls from '@/components/document-controls/DocumentsControls'
import ImageZoomPan from '@/components/image-zoom-pan/ImageZoomPan'
import NavigationPanel from '@/components/image-zoom-pan/NavigationPanel'
import ImageZoomPanControls from '@/components/image-zoom-pan/ImageZoomPanControls'
import FieldROIProvider from '@/components/project-wizard/FieldROIProvider'
import SizeProvider from '@/components/size-provider/SizeProvider'
import DocumentChipsProvider from '@/components/validation/providers/DocumentChipsProvider'
import { useWorkflowContext } from '@/components/workflow-provider/WorkflowProvider'
import useROIDocument from './useROIDocument'
import { useDocumentCount } from '../document-count-provider/DocumentCountProvider'
import { UseFormReturn } from 'react-hook-form'

type RegionOfInterestDialogProps = {
  overlay: OverlayState
  field: ProjectGridField
  methods: UseFormReturn<ProjectGridField & { csv_header_name: string }>
}

export default function RegionOfInterestDialog({
  overlay,
  field,
  methods,
}: RegionOfInterestDialogProps) {
  const [enableSave, setEnableSave] = useState(false)
  const [rotationDegree, setRotationDegree] = useState(0)

  const transformerRef = useRef(null)
  const newROIRef = useRef<{
    region_of_interest_left: number
    region_of_interest_top: number
    region_of_interest_right: number
    region_of_interest_bottom: number
    region_of_interest_page: number
  } | null>(null)

  const { productionDataWorkflow } = useWorkflowContext()

  const filteredWorkflowStates =
    productionDataWorkflow?.workflow_states?.filter(
      ({ code }) => code !== 'processing',
    )

  const { countByWorkflowStateId } = useDocumentCount()

  const defaultStateId = useMemo(() => {
    if (countByWorkflowStateId) {
      return (
        filteredWorkflowStates?.find(
          ({ id }) => countByWorkflowStateId[id]?.total,
        )?.id || ''
      )
    }
    return filteredWorkflowStates?.[0]?.id || ''
  }, [countByWorkflowStateId, filteredWorkflowStates])

  const [selectedWorkflowStateId, setSelectedWorkflowStateId] =
    useState(defaultStateId)

  const { query, documentIsAvailable, ...rest } = useROIDocument({
    enabled: overlay.isOpen,
    workflowStateId: selectedWorkflowStateId,
  })
  const { document } = query

  const rotateImage = () => {
    setRotationDegree((prev) => (prev + 90 === 360 ? 0 : prev + 90))
  }

  const onSave = () => {
    if (newROIRef.current) {
      methods.setValue(
        'region_of_interest_left',
        newROIRef.current.region_of_interest_left,
      )
      methods.setValue(
        'region_of_interest_top',
        newROIRef.current.region_of_interest_top,
      )
      methods.setValue(
        'region_of_interest_right',
        newROIRef.current.region_of_interest_right,
      )
      methods.setValue(
        'region_of_interest_bottom',
        newROIRef.current.region_of_interest_bottom,
      )
      methods.setValue(
        'region_of_interest_page',
        newROIRef.current.region_of_interest_page,
      )
    }
    overlay.close()
  }

  useEffect(() => {
    setRotationDegree(0)
    setEnableSave(false)
  }, [overlay.isOpen])

  useEffect(() => {
    setSelectedWorkflowStateId(defaultStateId)
  }, [defaultStateId])

  return (
    <Dialog
      title={`Region of Interest for ${field.name}`}
      {...overlay}
      maxWidth="xl"
    >
      <DialogContent>
        <TextField
          select
          value={selectedWorkflowStateId}
          disabled={!filteredWorkflowStates}
          label="Show Documents In"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setSelectedWorkflowStateId(e.target.value)
          }}
          sx={{ width: '250px' }}
        >
          {filteredWorkflowStates?.map((state) => {
            const count = countByWorkflowStateId?.[state.id]?.total || 0
            const countToDisplay = count > 100 ? '100+' : count
            return (
              <MenuItem key={state.id} value={state.id}>
                <Typography variant="body2">
                  {state.name}{' '}
                  <Typography
                    component="span"
                    color="textSecondary"
                    variant="body2"
                  >
                    ({countToDisplay})
                  </Typography>
                </Typography>
              </MenuItem>
            )
          })}
        </TextField>
        <Typography variant="body2" color="text.secondary" sx={{ py: 2 }}>
          Use shift + click to select the area this field typically appears in.
        </Typography>
      </DialogContent>

      <DocumentProvider
        query={{
          ...query,
          documentIsAvailable,
        }}
      >
        <DocumentChipsProvider>
          <DocumentImagePageProvider document={document} isRegionOfInterest>
            <ImageZoomPanCenterProvider>
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: '60vh' }}
              >
                <SizeProvider
                  sx={{
                    flexGrow: 1,
                    minHeight: 0,
                    position: 'relative',
                  }}
                >
                  <FieldROIProvider
                    field={field}
                    newROIRef={newROIRef}
                    setEnableSave={setEnableSave}
                  >
                    <Divider />
                    <ImageZoomPan
                      rotationDegree={rotationDegree}
                      transformerRef={transformerRef}
                      drawBoxes={false}
                      isRegionOfInterest
                    />
                    <Divider />
                  </FieldROIProvider>

                  <NavigationPanel
                    controls={[
                      <DocumentsControls key="document-controls" {...rest} />,
                      <ImageZoomPanControls
                        key="zoom-pan-controls"
                        hidePaginationControls={
                          !document?.document_pages.length ||
                          document?.document_pages.length <= 1
                        }
                        rotateImage={rotateImage}
                        transformerRef={transformerRef}
                        isRegionOfInterest
                      />,
                    ]}
                    layout="left"
                  />
                </SizeProvider>
              </Stack>
            </ImageZoomPanCenterProvider>
          </DocumentImagePageProvider>
        </DocumentChipsProvider>
      </DocumentProvider>
      <DialogFooter>
        <Button onClick={overlay.close} variant="text">
          Cancel
        </Button>
        <Button onClick={onSave} disabled={!enableSave}>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
