import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { MRT_Updater, MRT_VisibilityState } from 'material-react-table'
import { ProjectGridField } from '@/types/fields'
import { createDataLineageColumn } from '@/utils/field-utils'
import { useViewsContext } from './ViewsProvider'

type ColumnVisibilityContextValue = {
  columnVisibility: MRT_VisibilityState
  handleColumnVisibilityChange: (
    columnVisibilityUpdaterOrValue: MRT_Updater<MRT_VisibilityState>,
  ) => void // we use this function when we need to enable the user to save/update view
  resetColumnVisibility: () => void
  setColumnVisibility: Dispatch<SetStateAction<MRT_VisibilityState>>
}

const ColumnVisibilityContext = createContext<ColumnVisibilityContextValue>(
  {} as ColumnVisibilityContextValue,
)

export const useColumnVisibilityContext = () =>
  useContext(ColumnVisibilityContext)

type ColumnVisibilityProviderProps = {
  baseGridNonGridFields: ProjectGridField[]
  children: ReactNode
}

export default function ColumnVisibilityProvider({
  baseGridNonGridFields,
  children,
}: ColumnVisibilityProviderProps) {
  const { view } = useParams()
  const baseFieldsVisibility = useMemo(() => {
    const baseFields = baseGridNonGridFields.reduce((acc, { id }) => {
      const columns = createDataLineageColumn({ fieldId: id })
      return { ...acc, ...columns }
    }, {})
    const onDocumentsView = view === 'documents'
    return {
      ...baseFields,
      fileColId: true,
      organizationColId: true,
      submitterColId: onDocumentsView,
      pageCountColId: onDocumentsView,
      uploadColId: onDocumentsView,
      processedColId: onDocumentsView,
    }
  }, [baseGridNonGridFields, view])

  const { selectedView, handleChange } = useViewsContext()

  const visibility = useMemo(
    () =>
      Object.keys(selectedView.state.column_visibility || {}).length
        ? selectedView.state.column_visibility
        : baseFieldsVisibility,
    [baseFieldsVisibility, selectedView.state.column_visibility],
  )
  const [columnVisibility, setColumnVisibility] =
    useState<MRT_VisibilityState>(visibility)

  const handleColumnVisibilityChange = (
    columnVisibilityUpdaterOrValue: MRT_Updater<MRT_VisibilityState>,
  ) => {
    handleChange()
    if (columnVisibilityUpdaterOrValue instanceof Function) {
      setColumnVisibility((prev = {}) => columnVisibilityUpdaterOrValue(prev))
    } else {
      setColumnVisibility(columnVisibilityUpdaterOrValue)
    }
  }

  const resetColumnVisibility = () => {
    setColumnVisibility(visibility)
  }

  return (
    <ColumnVisibilityContext.Provider
      value={{
        columnVisibility,
        handleColumnVisibilityChange,
        resetColumnVisibility,
        setColumnVisibility,
      }}
    >
      {children}
    </ColumnVisibilityContext.Provider>
  )
}
