export default function getFormattedDateTimeString(
  value: string,
  type: 'date' | 'time' | 'datetime',
): string | undefined {
  if (type === 'date') {
    // Apparently the date constructor consider the date as UTC when it's passed with hyphens but not with slashes https://stackoverflow.com/a/31732581
    // Occasionally it's passed in as a date object that returns a string on get, so we need to check for that here as well (even though we assume it is a string in TS)
    const dateString = value.split('T')[0].replaceAll('-', '/')
    return new Date(dateString).toLocaleDateString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  } else if (type === 'datetime') {
    return new Date(value).toLocaleString('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  } else if (type === 'time') {
    let date = new Date(value)
    // [SC-6751] Added check in case date is already valid
    if (typeof value === 'string' && isNaN(date.getTime())) {
      // Need to format the time as a date string
      date = new Date(`${new Date().toLocaleDateString()} ${value}`)
    }
    return date.toLocaleTimeString('en-us', {
      timeStyle: 'short',
    })
  }
}

export const prettifyDate = (
  date: string,
  type: 'date' | 'time' | 'datetime' = 'datetime',
) => {
  if (type === 'date') {
    return new Date(date).toLocaleDateString(undefined, {
      dateStyle: 'medium',
    })
  }
  return new Date(date).toLocaleString(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
  })
}

export const convertSecondsToHours = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = duration % 60
  return `${hours}h ${minutes}m ${seconds}s`
}
