import { ReactNode, createContext, useContext, useMemo } from 'react'
import { WorkflowStateDocumentCount } from '@/types/document-workflow-states'
import { useGetWorkflowStateDocumentCounts } from '@/service-library/hooks/document-workflow-states'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useWorkflowContext } from '@/components/workflow-provider/WorkflowProvider'

const DocumentCountContext = createContext({
  count: 0,
  countByWorkflowStateId: {} as WorkflowStateDocumentCount,
  exportingCount: 0,
  processingCount: 0,
  refetch: () => {},
})

export const useDocumentCount = () => useContext(DocumentCountContext)

type DocumentCountProviderProps = {
  children: ReactNode
}

export default function DocumentCountProvider({
  children,
}: DocumentCountProviderProps) {
  const { project } = useProjectContext()
  const { getWorkflowStateByCode } = useWorkflowContext()

  const {
    documentCounts = {
      count: 0,
      count_by_workflow_state: {},
    },
    refetch,
  } = useGetWorkflowStateDocumentCounts({
    project_id: project.id,
    refetchInterval: 2000,
  })

  const { count_by_workflow_state = {} } = documentCounts

  const exportingCount = useMemo(() => {
    return Object.values(count_by_workflow_state).reduce((acc, val) => {
      return acc + (val.exporting || 0)
    }, 0)
  }, [count_by_workflow_state])

  const processingWorkflowState = getWorkflowStateByCode('basic', 'processing')

  const processingCount = useMemo(() => {
    const processingCount = processingWorkflowState
      ? count_by_workflow_state[processingWorkflowState?.id]
      : undefined

    return processingCount
      ? processingCount.total - (processingCount.error || 0)
      : 0
  }, [count_by_workflow_state, processingWorkflowState])

  return (
    <DocumentCountContext.Provider
      value={useMemo(
        () => ({
          count: documentCounts.count,
          refetch,
          exportingCount,
          processingCount,
          countByWorkflowStateId: count_by_workflow_state,
        }),
        [
          count_by_workflow_state,
          documentCounts.count,
          exportingCount,
          processingCount,
          refetch,
        ],
      )}
    >
      {children}
    </DocumentCountContext.Provider>
  )
}
