import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import PageTitle from '@/components/PageTitle'
import Drawer from '@/components/drawer/Drawer'
import useOverlay from '@/hooks/useOverlay'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import usePermission from '@/hooks/usePermission'
import SettingsNavigation from '@/components/settings-navigation/SettingsNavigation'

export default function GlobalSettingsPage() {
  const drawerOverlay = useOverlay({ initiallyOpen: true })
  const featureFlags = useFeatureFlagContext()
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()

  const canSeeCustomerInfo = hasEditingPermission(
    'edit_orgs',
    rootOrganization.id,
  )

  const routes = [
    {
      to: 'my-profile',
      label: 'My Profile',
    },
    {
      to: 'users',
      label: 'Users',
    },
    {
      to: 'organizations',
      label: 'Organizations',
    },
    {
      to: 'organization-types',
      label: 'Organization Types',
    },
    {
      to: 'lists',
      label: 'Lists',
    },
    {
      to: 'credentials',
      label: 'Credentials',
    },
  ]

  if (featureFlags.teams) {
    routes.splice(1, 0, {
      to: 'teams',
      label: 'Teams',
    })
  }

  if (featureFlags.customer_page_visible && canSeeCustomerInfo) {
    routes.splice(1, 0, {
      to: `customer/${rootOrganization.id}`,
      label: 'Subscription Details',
    })
  }

  if (featureFlags.org_custom_variables) {
    routes.push({
      to: 'custom-variables',
      label: 'Custom Variables',
    })
  }

  return (
    <>
      <PageTitle>Projects</PageTitle>
      <Breadcrumb label="Global Settings" url="/settings" />

      <Drawer overlay={drawerOverlay} size="sm" transparent>
        <SettingsNavigation label="Global Settings" routes={routes} />
      </Drawer>

      <Box sx={{ height: '100%', width: '100%', p: 2, pt: 3 }}>
        <Outlet />
      </Box>
    </>
  )
}
