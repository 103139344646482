import { FlagCount, FlagType } from '@/types/flags'
import useFlagColorMap from './useFlagColorMap'

export type FlagsCount = {
  documentFlags: FlagCount
  rowsFlags: FlagCount
}

export default function useFlagPriorityColor(flagsCount: FlagsCount) {
  const colorMap = useFlagColorMap()
  const { documentFlags, rowsFlags } = flagsCount

  let priorityCode: FlagType['code'] | undefined = undefined

  if (documentFlags.error || rowsFlags.error) {
    priorityCode = 'error'
  } else if (documentFlags.warning || rowsFlags.warning) {
    priorityCode = 'warning'
  }

  // We return colors for warnings and errors, but not info flags
  return {
    priorityCode,
    color: priorityCode ? colorMap[priorityCode] : undefined,
  }
}
