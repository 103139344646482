import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import PropertyField from '@/components/project-settings/params-schema-property-fields/PropertyField'
import { useWorkflowStatePageContext } from './WorkflowStatePage'

type TriggerEventFieldsProps = {
  action: WorkflowStateAction
}

const conditionsThatNeedFlagType = ['has_flag_type', 'does_not_have_flag_type']

export default function TriggerEventFields({
  action,
}: TriggerEventFieldsProps) {
  const workflowState = useWorkflowStatePageContext()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const actionType = action.action_type as Record<string, any>
  const condition = useFormContext().watch('condition')

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      {/* Event Picker */}
      <PropertyField
        propertyId="event_id"
        property={actionType.params_schema.properties.event_id}
        required
        variant="outlined"
        dependencyValues={{
          current_event_id: action.event_id,
          current_workflow_state_id: workflowState?.id || '',
        }}
      />

      {/* Condition Picker */}
      <PropertyField
        propertyId="condition"
        property={actionType.params_schema.properties.condition}
        required
        variant="outlined"
      />

      {/* Flag Type Code */}
      {/* TODO: This currently expects the flag_class. When we change to proper flag types, we'll switch
          to proper flag types with a select. */}
      {conditionsThatNeedFlagType.includes(condition) && (
        <PropertyField
          propertyId="flag_type_code"
          property={actionType.params_schema.properties.flag_type_code}
          required
          variant="outlined"
        />
      )}
    </Stack>
  )
}
