import { MetadataId } from '@/hooks/useColumnFilters'
import { FieldTypeName } from '@/types/fields'
import { MRT_FilterOption } from 'material-react-table'

const filterModeOptionMapping = {
  betweenInclusive: 'range',
  contains: 'icontains',
  empty: 'isnull',
  endsWith: 'iendswith',
  equals: 'iexact',
  greaterThan: 'gt',
  greaterThanOrEqualTo: 'gte',
  lessThan: 'lt',
  lessThanOrEqualTo: 'lte',
  notEmpty: 'isnull',
  startsWith: 'istartswith',
} as Record<MRT_FilterOption, string>

export const getEndpointFilterMode = (
  filterMode: MRT_FilterOption,
  fieldType: FieldTypeName | MetadataId,
) => {
  switch (fieldType) {
    case 'pageCountColId':
    case 'uploadColId':
    case 'processedColId':
    case 'currency':
    case 'int':
    case 'decimal':
    case 'date':
    case 'time':
    case 'datetime':
    case 'checkbox': {
      if (filterMode === 'equals') {
        return fieldType === 'pageCountColId' ? '' : '__exact'
      }
      return `__${filterModeOptionMapping[filterMode]}`
    }
    default:
      return `__${filterModeOptionMapping[filterMode]}`
  }
}

export default function getColumnFilterModeOptions(
  columnType: FieldTypeName | MetadataId,
) {
  switch (columnType) {
    case 'fileColId':
    case 'organizationColId':
    case 'submitterColId':
      return ['contains', 'equals', 'startsWith', 'endsWith']
    case 'address':
    case 'category':
    case 'cc_num':
    case 'phone':
    case 'picker':
    case 'text':
      return [
        'contains',
        'equals',
        'startsWith',
        'endsWith',
        'empty',
        'notEmpty',
      ]
    case 'currency':
    case 'int':
    case 'decimal':
      return [
        'equals',
        'empty',
        'notEmpty',
        'greaterThanOrEqualTo',
        'greaterThan',
        'lessThanOrEqualTo',
        'lessThan',
        'betweenInclusive',
      ]
    case 'pageCountColId':
    case 'uploadColId':
    case 'processedColId':
    case 'date':
    case 'time':
    case 'datetime':
      return [
        'equals',
        'greaterThanOrEqualTo',
        'greaterThan',
        'lessThanOrEqualTo',
        'lessThan',
        'betweenInclusive',
      ]
    case 'checkbox':
      return ['equals']
    default:
      return [
        'equals',
        'contains',
        'startsWith',
        'endsWith',
        'empty',
        'notEmpty',
      ]
  }
}
