import { UseGetDetailOptions } from '@/service-library/core-hooks/useGetDetail'
import { useGetDocument } from '@/service-library/hooks/documents'
import { Document } from '@/types/documents'

export default function useGetValidationDocument(
  options: UseGetDetailOptions<Document>,
) {
  const query = useGetDocument({
    ...options,
    filters: {
      fields__include:
        'created_at,created_by_user,document_rows,document_pages,document_flags,languages_ids,workflow_states_ids',
      document_rows__fields__include: 'document_row_values',
      document_pages__fields__include: 'image_urls',
      document_rows__document_row_values__fields__include:
        'document_row_id,row_value_flags',
      document_rows__document_row_values__row_value_flags__fields__include:
        'flag_type',
      document_flags__fields__include: 'flag_type',
      ...options.filters,
    },
  })

  if (query.data && query.document) {
    query.data.document_pages =
      query.data?.document_pages?.sort(
        (a, b) => a.page_number - b.page_number,
      ) || []
    query.document.document_pages =
      query.document?.document_pages?.sort(
        (a, b) => a.page_number - b.page_number,
      ) || []
  }

  return query
}
