import { useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { MRT_ColumnDef } from 'material-react-table'
import {
  Typography,
  IconButton,
  Tooltip,
  Link,
  useTheme,
  ThemeProvider,
  createTheme,
  Stack,
} from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ProjectGridField as TSField } from '@/types/fields'
import useFixedTableHeader from '@/hooks/useFixedTableHeader'
import useLocalStorage from '@/hooks/useLocalStorage'
import {
  useCreateUpdateDocumentRowsWithCleanup,
  useDeleteDocumentRowWithCleanup,
  useDeleteDocumentRowsWithCleanup,
  useGetDocumentRows,
  useInsertDocumentRow,
} from '@/service-library/hooks/document-rows'
import queryKeys from '@/service-library/query-keys'
import { getAggregateValue, numberFieldTypes } from '@/utils/field-utils'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import { useScrollContainer } from '@/components/scroll-container/ScrollContainer'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useFieldErrorContext } from './providers/FieldErrorProvider'
import Field from './Field'
import FieldTableMenu from './FieldTableMenu'
import FieldTableRowActionsMenu from './FieldTableRowActionsMenu'
import AggregateCell from './AggregateCell'

type FieldTableProps = {
  tableField: TSField
}

function FieldTableContent({ tableField }: FieldTableProps) {
  const { documentId } = useParams()
  const theme = useTheme()

  // We only use this variable when we are deleting all rows, so we can show the accurate # of rows when showing the loading state
  const [rowsGettingDeleted, setRowsGettingDeleted] = useState(0)

  const [columnSizing, setColumnSizing] = useLocalStorage(
    `columns-sizing-${tableField.id}`,
    {},
  )

  const { isDisabled, queryKey: documentQueryKey } = useDocumentContext()
  const { getFieldError } = useFieldErrorContext()
  const { refetchDocumentChips } = useDocumentChipsContext()
  const { queryKey: documentRowValuesQueryKey } = useDocumentRowValuesContext()

  const {
    documentRows = [],
    queryKey,
    isLoading,
  } = useGetDocumentRows({
    filters: {
      limit: '1000',
      project_grid_id: tableField.sub_project_grid_id as string,
      document_id: documentId as string,
    },
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { createOrUpdateDocumentRows } = useCreateUpdateDocumentRowsWithCleanup(
    {
      workflowId: selectedWorkflow.id,
      listQueryKey: queryKey,
      sideEffectQueryKeys: [documentQueryKey, documentRowValuesQueryKey],
      onError: () => {
        showErrorSnackbar('Unable to add row. Please try again later.')
      },
    },
  )

  const { insertDocumentRow } = useInsertDocumentRow({
    workflowId: selectedWorkflow.id,
    sideEffectQueryKeys: [documentQueryKey, documentRowValuesQueryKey],
    listQueryKey: queryKey,
    onError: () => {
      showErrorSnackbar('Unable to add row. Please try again later.')
    },
  })

  const { deleteDocumentRow } = useDeleteDocumentRowWithCleanup({
    workflowId: selectedWorkflow.id,
    listQueryKey: queryKey,
    sideEffectQueryKeys: [
      queryKeys.documents.all,
      queryKeys.documentRows.lists(),
      queryKeys.documentRowValues.lists(),
    ],
    onSuccess: () => {
      refetchDocumentChips()
    },
    onError: () => {
      showErrorSnackbar('Unable to delete row. Please try again later.')
    },
  })

  const { deleteDocumentRows } = useDeleteDocumentRowsWithCleanup({
    workflowId: selectedWorkflow.id,
    listQueryKey: queryKey,
    sideEffectQueryKeys: [
      queryKeys.documents.all,
      queryKeys.documentRows.lists(),
      queryKeys.documentRowValues.lists(),
    ],
    onError: () => {
      showErrorSnackbar('Failed to delete rows. Please try again later.')
    },
    onSettled: () => {
      setRowsGettingDeleted(0)
      refetchDocumentChips()
    },
  })

  const cellToFocusRef = useRef<HTMLInputElement | HTMLDivElement | null>(null)
  const containerRef = useScrollContainer()
  const { tableContainerRef, tableHeadRef, tableHeadRowRef } =
    useFixedTableHeader({
      containerRef,
    })
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const timerRef = useRef<NodeJS.Timeout>()

  const { documentRowValues } = useDocumentRowValuesContext()

  const fieldsMap: Record<string, TSField> = useMemo(() => {
    const tableFields = tableField.fields || []
    return tableFields.reduce(
      (acc, field) => ({ ...acc, [field.id]: field }),
      {},
    )
  }, [tableField.fields])

  const aggregateValuesMap = useMemo(() => {
    const valuesMap: Record<string, string[]> =
      tableField.fields?.reduce(
        (acc, field) =>
          field.aggregate_function !== 'none' &&
          numberFieldTypes.includes(field.project_grid_field_type.code)
            ? { ...acc, [field.id]: [] }
            : acc,
        {},
      ) || {}
    documentRowValues.forEach(({ project_grid_field_id, final_value }) => {
      if (
        final_value &&
        project_grid_field_id in fieldsMap &&
        fieldsMap[project_grid_field_id].aggregate_function !== 'none'
      ) {
        valuesMap[project_grid_field_id].push(final_value)
      }
    })
    return valuesMap
  }, [documentRowValues, fieldsMap, tableField.fields])

  const columns = useMemo<MRT_ColumnDef<DocumentRow>[]>(() => {
    return Object.values(fieldsMap).map((field, index) => ({
      id: field.id,
      header: field.name,
      accessorKey: field.id as keyof DocumentRow,
      Footer: () =>
        field.aggregate_function !== 'none' &&
        aggregateValuesMap[field.id]?.length ? (
          <AggregateCell
            aggregateFunction={field.aggregate_function}
            value={getAggregateValue({
              values: aggregateValuesMap[field.id],
              aggregateFunction: field.aggregate_function,
            })}
            code={field.project_grid_field_type.code}
          />
        ) : null,
      Cell: ({ row, table }) => (
        <Field
          field={field}
          documentRow={row.original}
          isInTable
          fieldPosition={{
            column: index + 2,
            row: row.index + 1,
          }}
          tableGrid={{
            columns: table.options.columns.length,
            rows: table.options.data.length,
          }}
          tableBodyEl={tableBodyRef.current}
          insertDocumentRow={(position) => {
            return insertDocumentRow([
              {
                id: generateUuid(),
                row_number:
                  row.original.row_number + (position === 'before' ? 0 : 1),
                project_grid_id: tableField.sub_project_grid_id as string,
                document_id: documentId as string,
              },
            ])
          }}
          deleteDocumentRow={deleteDocumentRow}
        />
      ),
    }))
  }, [
    aggregateValuesMap,
    deleteDocumentRow,
    documentId,
    fieldsMap,
    insertDocumentRow,
    tableField.sub_project_grid_id,
  ])

  const addRow = ({
    rowId,
    position,
    shouldFocus = false,
  }: {
    rowId?: string
    position?: 'before' | 'after'
    shouldFocus?: boolean
  } = {}) => {
    if (!rowId || !position) {
      const lastItem = documentRows.slice(-1)[0]
      if (!tableField.sub_project_grid_id || !documentId) return
      createOrUpdateDocumentRows([
        {
          id: generateUuid(),
          row_number: lastItem?.row_number + 1 || 1,
          project_grid_id: tableField.sub_project_grid_id as string,
          document_id: documentId as string,
        },
      ])
    } else {
      const row = documentRows.find((row) => row.id === rowId)
      if (!row) return
      insertDocumentRow([
        {
          id: generateUuid(),
          row_number: row.row_number + (position === 'before' ? 0 : 1),
          project_grid_id: tableField.sub_project_grid_id as string,
          document_id: documentId as string,
        },
      ])
    }
    if (shouldFocus) {
      setTimeout(() => {
        if (!cellToFocusRef.current) {
          let targetRow = rowId
            ? tableBodyRef.current?.querySelector(`tr[id="${rowId}"]`)
            : tableBodyRef.current?.querySelector('tr:last-child')

          if (position === 'before')
            targetRow = targetRow?.previousElementSibling
          if (position === 'after') targetRow = targetRow?.nextElementSibling

          cellToFocusRef.current = targetRow?.querySelector(
            `td:nth-child(2) input`,
          ) as HTMLInputElement
          if ((cellToFocusRef.current as HTMLInputElement).disabled) {
            cellToFocusRef.current = targetRow?.querySelector(
              `td:nth-child(2) div.focus-container`,
            ) as HTMLDivElement
          }
        }
        cellToFocusRef.current?.focus({ preventScroll: true })
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          cellToFocusRef.current = null
        }, 2000)
      }, 100)
    }
  }

  const rows = [...documentRows].sort((a, b) => a.row_number - b.row_number)

  const table = useZerapixTable<DocumentRow>({
    title: tableField.name,
    columns,
    data: rows,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        muiTableBodyCellProps: {
          sx: {
            borderRight: `1px solid ${theme.palette.divider}`,
            justifyContent: 'center',
            p: 0,
            '& button': {},
          },
        },
        muiTableHeadCellProps: {
          sx: {
            '& button': {},
          },
        },
        muiTableFooterCellProps: {
          sx: {
            background:
              theme.palette.mode === 'dark'
                ? `${theme.palette.background.paper}10`
                : grey[100],
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
      },
    },
    enableColumnResizing: true,
    enableRowActions: true,
    enableRowVirtualization: rows.length > 60,
    enableSorting: false,
    enableToolbarInternalActions: true,
    getRowId: (row) => row.id,
    localization: {
      noRecordsToDisplay: (
        <Typography
          variant="caption"
          component="span"
          color="text.secondary"
          textAlign="center"
        >
          <i>
            <Link
              component="button"
              onClick={() => {
                addRow({ shouldFocus: true })
              }}
              underline="hover"
            >
              <i>Add a row</i>
            </Link>{' '}
            to begin entering data.
          </i>
        </Typography>
      ) as unknown as string,
    },
    muiTableBodyProps: () => ({
      ref: tableBodyRef,
    }),
    muiTableBodyCellProps: ({ cell, row }) => {
      const fieldError = getFieldError(cell.column.id, row.id)
      return {
        sx: {
          alignItems: 'flex-start',
          borderRight: `1px solid ${theme.palette.divider}`,
          borderBottom: fieldError
            ? `1px solid ${theme.palette.error.main} !important`
            : 'none',
          p: '2px 4px',
          '> div': {
            width: '100%',
          },
          '&:focus-within': {
            borderBottom: isDisabled
              ? 'none'
              : `1px solid ${theme.palette.primary.main}`,
          },
        },
      }
    },
    muiTableFooterCellProps: () => ({
      sx: {
        p: 0.5,
        background:
          theme.palette.mode === 'dark'
            ? `${theme.palette.background.paper}10`
            : grey[100],
        opacity: 100,
      },
    }),
    muiTableBodyRowProps: ({ row }) => ({
      id: row.id,
      sx: {
        minHeight: 32,
        height: 'fit-content',
      },
    }),
    muiTableContainerProps: () => ({
      ref: tableContainerRef,
    }),
    muiTableHeadProps: () => ({
      ref: tableHeadRef,
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    }),
    muiTableHeadRowProps: () => ({
      ref: tableHeadRowRef,
    }),
    muiTablePaperProps: () => ({
      elevation: 0,
    }),
    onColumnSizingChange: setColumnSizing,
    renderRowActions: ({ row }) => {
      return (
        <FieldTableRowActionsMenu
          documentRow={row.original}
          addRow={addRow}
          deleteDocumentRow={deleteDocumentRow}
        />
      )
    },
    renderToolbarInternalActions: () => (
      <Stack direction="row" alignItems="center">
        <Tooltip title="Add Row">
          <IconButton
            onClick={() => {
              addRow({ shouldFocus: true })
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault() // Prevent the onClick from firing
                addRow()
              }
            }}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <FieldTableMenu
          tableHasRows={!!documentRows.length}
          onDeleteAllRows={() => {
            deleteDocumentRows(documentRows.map(({ id }) => id))
            setRowsGettingDeleted(documentRows.length)
          }}
        />
      </Stack>
    ),
    rowVirtualizerOptions: { overscan: 20 },
    state: {
      columnSizing,
      showSkeletons: isLoading || !!rowsGettingDeleted,
      // This will only be used when there all rows are getting deleted to keep the # of rows
      pagination: {
        pageIndex: 0,
        pageSize: rowsGettingDeleted,
      },
    },
  })

  return <ZerapixTable<DocumentRow> table={table} />
}

export default function FieldTable(props: FieldTableProps) {
  const theme = useTheme()
  // This is the easiest way to get the table cards to reflect the same elevation without making
  // tons of the subcomponents have background "transparent"
  const tableTheme = useMemo(() => {
    return createTheme(theme, {
      palette: {
        mode: theme.palette.mode,
        background: {
          default: theme.palette.mode === 'dark' ? '#3a3a3a' : '#fff',
          paper: theme.palette.mode === 'dark' ? '#3a3a3a' : '#fff',
        },
      },
    })
  }, [theme])

  return (
    <ThemeProvider theme={tableTheme}>
      <FieldTableContent {...props} />
    </ThemeProvider>
  )
}
