import { useEffect, useMemo, useState } from 'react'
import {
  Card,
  Container,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatumValue } from '@nivo/line'
import { GroupOption } from '@/types/metrics'
import { Organization } from '@/types/organizations'
import { Project } from '@/types/projects'
import { ProjectModel } from '@/types/project-models'
import useMetricsSearchParams from '@/hooks/useMetricsSearchParams'
import useAllOrganizations from '@/services/hooks/useAllOrganizations'
import { useGetDocumentValidationMetrics } from '@/service-library/hooks/metric-logs'
import { useGetProjects } from '@/service-library/hooks/projects'
import { useGetProjectModels } from '@/service-library/hooks/project-models'
import { convertToDate } from '@/utils/date-metrics'
import ListAutocomplete, {
  BaseDataType,
} from '@/components/list-autocomplete/ListAutocomplete'
import PageTitle from '@/components/PageTitle'
import LineChart from '@/components/charts/LineChart'
import TimeLineChart from '@/components/charts/TimeLineChart'
import { OCR_MODEL_TYPE_ID } from '@/components/models-page/helpers'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import OrganizationPickerWithDialog from '@/components/organization-select/OrganizationPickerWithDialog'
import { allOrganizations } from './DocumentsProcessed'
import MetricsCommonFilters from './MetricsCommonFilters'
import GeneralInfoBox from './GeneralInfoBox'

const labelMap: Record<string, string> = {
  count: 'Documents Validated',
  average_duration: 'Average Duration per Document',
}

const placeholderData = {
  id: '',
  name: '',
}

export default function DocumentValidationMetrics() {
  const theme = useTheme()
  const { values, updateValues } = useMetricsSearchParams()

  const [showBy, setShowBy] = useState(values.show_by || 'time')
  const startDateState = useState(convertToDate(values.from || 'start'))
  const endDateState = useState(convertToDate(values.to || 'end', false))
  const groupedByState = useState<GroupOption>(
    (values.grouped_by as GroupOption) || 'day',
  )

  const [internalDocumentsValidationData, setInternalDocumentsValidationData] =
    useState<typeof documentsValidationData>([])

  const { rootOrganization } = useRootOrganization()
  const {
    organizations,
    isLoading: organizationsIsLoading,
    isFetchingAll,
  } = useAllOrganizations({
    filters: {
      self_and_descendants_for_id: rootOrganization.id,
    },
  })
  const defaultOrganization = useMemo(() => {
    if (organizations.length && values.org_id) {
      return (
        organizations.find(({ id }) => id === values.org_id) || allOrganizations
      )
    }
    return allOrganizations
  }, [organizations, values.org_id])

  const [selectedOrg, setSelectedOrg] =
    useState<Organization>(defaultOrganization)

  const { projects, isLoading: projectsIsLoading } = useGetProjects({
    refetchOnWindowFocus: false,
    filters: {
      org_id: rootOrganization.id,
      limit: '1000',
    },
  })

  const setUpProjects = projects.filter(
    (project) => project.setup_state === 'complete',
  )

  const defaultProject = useMemo(() => {
    if (setUpProjects.length && values.project_id) {
      return (
        setUpProjects.find(({ id }) => id === values.project_id) ||
        placeholderData
      )
    }
    return setUpProjects[0] || placeholderData
  }, [setUpProjects, values.project_id])

  const [selectedProject, setSelectedProject] = useState<
    Project | BaseDataType
  >(defaultProject)

  const { projectModels: trainingModels, isLoading: projectModelsIsLoading } =
    useGetProjectModels({
      filters: {
        limit: '1000',
        project_id: selectedProject?.id,
        'project_model_type_id!': OCR_MODEL_TYPE_ID,
        parent_model_id__isnull: 'true',
      },
      enabled: !!selectedProject?.id && showBy === 'model_version',
    })

  const defaultProjectModel = useMemo(() => {
    if (trainingModels.length && values.project_model_id) {
      return (
        trainingModels.find(({ id }) => id === values.project_model_id) ||
        placeholderData
      )
    }
    return trainingModels[0] || placeholderData
  }, [trainingModels, values.project_model_id])

  const [selectedProjectModel, setSelectedProjectModel] = useState<
    ProjectModel | BaseDataType
  >(defaultProjectModel)

  const { validationMetrics, isLoading } = useGetDocumentValidationMetrics({
    projectId: selectedProject?.id,
    groupedBy: showBy === 'model_version' ? 'model_version' : groupedByState[0],
    startDate: showBy === 'model_version' ? undefined : startDateState[0],
    endDate: showBy === 'model_version' ? undefined : endDateState[0],
    filters: {
      org_id:
        selectedOrg.id !== allOrganizations.id ? selectedOrg.id : undefined,
    },
    projectModelId: selectedProjectModel?.id,
  })

  const documentsValidationData = useMemo(
    () => validationMetrics?.results || [],
    [validationMetrics?.results],
  )

  const data = useMemo(() => {
    const allVersionsAreZero =
      showBy === 'model_version'
        ? internalDocumentsValidationData.every(
            ({ model_version }) => model_version === 0,
          )
        : false

    return [
      {
        id: 'documents',
        data: internalDocumentsValidationData.map(
          ({ date, model_version, average_duration }, index) => ({
            x:
              showBy === 'model_version'
                ? allVersionsAreZero
                  ? index + 1
                  : model_version
                : date,
            y: average_duration,
          }),
        ),
      },
    ]
  }, [internalDocumentsValidationData, showBy])

  const chartCommonProps = {
    label: (
      <Typography component="h2" variant="h5" sx={{ pl: 6.5 }}>
        Documents Validation
      </Typography>
    ),
    colors: theme.palette.primary.main,
    height: 450,
    margin: { right: 20 },
    yLegend: 'Average Duration',
    yFormat: (value: DatumValue) => {
      const average = Number.isInteger(value)
        ? value.toString()
        : (value as number).toFixed(2)
      return `${average}s`
    },
  }

  useEffect(() => {
    if (!organizationsIsLoading) {
      setSelectedOrg(defaultOrganization)
    }
  }, [defaultOrganization, organizationsIsLoading])

  useEffect(() => {
    if (!projectsIsLoading) {
      setSelectedProject(defaultProject)
    }
  }, [defaultProject, projectsIsLoading])

  useEffect(() => {
    if (!projectModelsIsLoading) {
      setSelectedProjectModel(defaultProjectModel)
    }
  }, [defaultProjectModel, projectModelsIsLoading])

  useEffect(() => {
    !isLoading &&
      documentsValidationData &&
      setInternalDocumentsValidationData(documentsValidationData)
  }, [documentsValidationData, isLoading])

  useEffect(() => {
    setShowBy(values.show_by || 'time')
  }, [values.show_by])

  return (
    <Container sx={{ py: 4 }}>
      <PageTitle>Metrics - Documents Validation</PageTitle>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={3} sx={{ ml: 12.5, mr: 9, my: 3 }}>
          <Card sx={{ width: '180px' }}>
            <OrganizationPickerWithDialog
              allNodeIsIncluded
              currentOrganization={selectedOrg}
              organizations={[allOrganizations, ...organizations]}
              rootTreeOrgId={rootOrganization.id}
              isFetching={isFetchingAll}
              onSave={(organization) => {
                setSelectedOrg(organization)
                updateValues({ org_id: organization.id })
              }}
            />
          </Card>
          <ListAutocomplete
            autoSelect={false}
            options={setUpProjects}
            selected={selectedProject}
            setSelected={(project) => {
              setSelectedProject(project)
              setSelectedProjectModel({ id: '', name: '' })
              updateValues({ project_id: project.id, project_model_id: '' })
            }}
            label="Project"
            fullWidth={false}
            sx={{ width: '180px' }}
          />
          <TextField
            label="Show By"
            InputLabelProps={{ shrink: true }}
            select
            value={showBy}
            onChange={(e) => {
              const mode = e.target.value
              setShowBy(mode)
              const keysToDelete: Record<string, string> =
                mode === 'time'
                  ? { project_model_id: '' }
                  : {
                      grouped_by: '',
                      from: '',
                      to: '',
                    }
              updateValues({ ...keysToDelete, show_by: mode })
            }}
            sx={{ width: 150 }}
          >
            <MenuItem value="time">Time</MenuItem>
            <MenuItem value="model_version">Model Version</MenuItem>
          </TextField>
          {showBy === 'time' ? (
            <MetricsCommonFilters
              startDateState={startDateState}
              endDateState={endDateState}
              groupedByState={groupedByState}
              updateValues={updateValues}
            />
          ) : (
            <ListAutocomplete
              autoSelect={false}
              options={trainingModels}
              selected={selectedProjectModel}
              setSelected={(projectModel) => {
                setSelectedProjectModel(projectModel)
                updateValues({ project_model_id: projectModel.id })
              }}
              label="Training Model"
              fullWidth={false}
              sx={{ width: '180px' }}
            />
          )}
        </Stack>
      </LocalizationProvider>
      <Grid container spacing={2} ml={11.5} mr={9} my={2}>
        {!validationMetrics && isLoading && (
          <>
            <Grid xs={6}>
              <Skeleton height={100} />
            </Grid>
            <Grid xs={6}>
              <Skeleton height={100} />
            </Grid>
          </>
        )}

        {validationMetrics &&
          Object.entries(validationMetrics)
            .slice(0, 2)
            .map(([label, value]) => {
              let realValue: string | number = 0
              if (value && typeof value === 'number') {
                realValue = Number.isInteger(value) ? value : value.toFixed(3)
              }
              if (label === 'average_duration') realValue += 's'
              return (
                <Grid key={label} xs={6}>
                  <GeneralInfoBox label={labelMap[label]} value={realValue} />
                </Grid>
              )
            })}

        <Grid xs={12}>
          <Card elevation={0} sx={{ borderRadius: 2, px: 2, py: 3 }}>
            {showBy === 'time' ? (
              <TimeLineChart
                data={data}
                from={startDateState[0]}
                to={endDateState[0]}
                groupedBy={groupedByState[0]}
                axisLeft={{
                  format: (value) => `${value}s`,
                  legendOffset: -48,
                }}
                {...chartCommonProps}
              />
            ) : (
              <LineChart
                data={data}
                enablePoints={false}
                xFormat={(x) => `v${Number.isInteger(x) ? `${x}.0` : x}`}
                axisLeft={{
                  legendOffset: -48,
                }}
                tooltipContent={(point, stackedPoints) => {
                  return (
                    <Stack direction="row">
                      {stackedPoints.map((_stackedPoint, index) => (
                        <Stack key={index} p={1} maxWidth={170}>
                          <Typography component="p" noWrap variant="caption">
                            <b>Version:</b> {point.data.xFormatted}
                          </Typography>
                          <Typography component="p" noWrap variant="caption">
                            <b>Average Duration:</b> {point.data.yFormatted}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  )
                }}
                {...chartCommonProps}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
