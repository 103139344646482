import { ReactNode } from 'react'
import { useNavigationRailPortalContext } from './NavigationRailPortalProvider'
import { createPortal } from 'react-dom'
import { Box, Paper, Stack, useTheme } from '@mui/material'

type NavigationRailProps = {
  sidePanelJunk?: ReactNode
  navigationItems: ReactNode
}

export default function NavigationRail({
  sidePanelJunk,
  navigationItems,
}: NavigationRailProps) {
  const theme = useTheme()
  const { portalNode } = useNavigationRailPortalContext()
  return portalNode
    ? createPortal(
        <Paper
          sx={{
            height: '100%',
            overflow: 'hidden',
            borderRight:
              theme.palette.mode === 'light'
                ? `solid 1px ${theme.palette.divider}`
                : undefined,
          }}
          square
        >
          <Stack
            direction="row"
            sx={{
              height: '100%',
              overflowY: 'auto',
            }}
          >
            {sidePanelJunk && <Box sx={{ p: 1 }}>{sidePanelJunk}</Box>}
            <Stack spacing={0.5} alignItems="center" sx={{ py: 1, width: 48 }}>
              {navigationItems}
            </Stack>
          </Stack>
        </Paper>,
        portalNode,
      )
    : null
}
