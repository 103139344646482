import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ArrowDropDown, Clear } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Divider,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { DocumentRowValue } from '@/types/documents'
import { ProjectGridFieldRule } from '@/types/rules'
import useOverlay from '@/hooks/useOverlay'
import { useRunRules } from '@/service-library/hooks/documents'
import queryKeys from '@/service-library/query-keys'
import { useDocumentContext } from '@/components/image-zoom-pan/providers/DocumentProvider'
import { AutocompleteOption } from './AutocompleteField'
import AddListItemDialog from './AddListItemDialog'
import ListLookupAddMapping from './ListLookupAddMapping'
import debounce from 'lodash.debounce'
import { useGetChildDataList } from '@/service-library/hooks/data-lists'
import {
  useCreateDataListEntryCell,
  useGetDataListEntryCells,
} from '@/service-library/hooks/data-list-entry-cells'
import { Filters } from '@/service-library/request-types'
import { useCreateDataListEntryCellValue } from '@/service-library/hooks/data-list-entry-cell-values'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

type ListLookupFieldProps = {
  onChange: (newValue: string | null, refId: string | null) => void
  fieldRule: ProjectGridFieldRule
  value: AutocompleteOption | null
  isShowingChips?: boolean
  documentRowValue?: DocumentRowValue
  setHasSolidBackground: Dispatch<SetStateAction<boolean>>
  setDisableArrowKeys: Dispatch<SetStateAction<boolean>>
  isInTable?: boolean
} & Omit<TextFieldProps, 'onChange' | 'value'>

export default function ListLookupField({
  fieldRule,
  isShowingChips,
  value,
  documentRowValue,
  onChange,
  setHasSolidBackground,
  setDisableArrowKeys,
  label,
  isInTable,
  ...props
}: ListLookupFieldProps) {
  const addListItemOverlay = useOverlay()
  const [addItemValue, setAddItemValue] = useState('')
  const { document } = useDocumentContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { runRules } = useRunRules({
    workflowId: selectedWorkflow.id,
    sideEffectQueryKeys: [
      queryKeys.documents.details(),
      queryKeys.documentRowValues.lists(),
    ],
  })

  const [isAddingMapping, setIsAddingMapping] = useState(false)

  const {
    data_list_id,
    lookup_data_list_column_id,
    mapping_data_list_column_id,
  } = fieldRule.params

  const oldValueLabelRef = useRef(value?.label)
  const [inputValue, setInputValue] = useState(value?.label || '')
  const [searchValue, setSearchValue] = useState(value?.label || '')
  const [open, setOpen] = useState(false)

  // Fetch the dataList to guarantee we're working with the right list (might be a child list)
  // The data_list_id on the fieldRule params is the parent list, but we may need to use the child list
  const { dataList } = useGetChildDataList({
    id: data_list_id,
    orgId: document?.owner_org_id as string,
    enabled: !!document,
  })

  const useFilter = searchValue !== value?.label

  const filters: Filters = {
    limit: '100',
    data_list_entry__data_list_id: dataList?.id || '',
    data_list_column_id: lookup_data_list_column_id,
    fields__include: 'data_list_entry_cell_values',
  }
  if (useFilter) {
    filters.value__icontains = searchValue
  }

  // Get the entries for the lookup column
  const {
    dataListEntryCells = [],
    fetchNextPage,
    hasNextPage,
  } = useGetDataListEntryCells({
    filters,
    keepPreviousData: true,
    enabled: !!dataList && !!lookup_data_list_column_id,
  })

  // Get the entries for the mapping column
  const {
    dataListEntryCells: mappingListEntryCells = [],
    queryKey,
    fetchNextPage: fetchNextMappingPage,
    hasNextPage: hasNextMappingPage,
  } = useGetDataListEntryCells({
    filters: {
      limit: '100',
      data_list_id: dataList?.id || '',
      data_list_column_id: mapping_data_list_column_id,
      fields__include: 'data_list_entry_cell_values',
    },
    enabled: !!dataList && !!mapping_data_list_column_id,
  })

  const valueCell = dataListEntryCells.find(
    ({ data_list_entry_cell_values }) => {
      return data_list_entry_cell_values.some(({ id }) => id === value?.id)
    },
  )
  const mappingCell = mappingListEntryCells.find(({ data_list_entry_id }) => {
    return data_list_entry_id === valueCell?.data_list_entry_id
  })

  const { createDataListEntryCell } = useCreateDataListEntryCell({
    listQueryKey: queryKey,
    onSuccess: () => {
      document?.id && runRules({ id: document.id })
      showSuccessSnackbar('Added Mapping')
    },
    onError: () => {
      showErrorSnackbar('Failed to add mapping.')
    },
  })

  const { createDataListEntryCellValue } = useCreateDataListEntryCellValue({
    sideEffectQueryKeys: [queryKey],
    onSuccess: () => {
      document?.id && runRules({ id: document.id })
      showSuccessSnackbar('Added Mapping')
    },
    onError: () => {
      showErrorSnackbar('Failed to add mapping.')
    },
  })

  const { original_value, manual_value } =
    documentRowValue || ({} as DocumentRowValue)

  const handleAddMapping = async () => {
    if (!valueCell) return
    setIsAddingMapping(true)
    if (mappingCell) {
      createDataListEntryCellValue({
        id: generateUuid(),
        value: original_value as string,
        data_list_entry_cell_id: mappingCell.id,
        sort_order: 0,
      })
    } else {
      const newCellId = generateUuid()
      createDataListEntryCell({
        id: newCellId,
        data_list_column_id: mapping_data_list_column_id,
        data_list_entry_id: valueCell.data_list_entry_id,
        data_list_entry_cell_values: [
          {
            id: generateUuid(),
            value: original_value as string,
            data_list_entry_cell_id: newCellId,
            sort_order: 0,
          },
        ],
        sort_order: 0,
      })
    }
    setIsAddingMapping(false)
  }

  const mappingDoesntExistYet = mappingCell
    ? mappingCell.data_list_entry_cell_values.every(
        ({ value }) => value !== original_value,
      )
    : true

  const showMappingButton =
    !!original_value &&
    mappingDoesntExistYet &&
    valueCell &&
    manual_value &&
    isShowingChips &&
    mapping_data_list_column_id &&
    // Convert manual_value to string for cases where it is a number
    `${manual_value}`.toLowerCase() !== original_value.toLowerCase()

  const handleOpen = (e: React.SyntheticEvent<Element, Event>) => {
    if (
      e.type !== 'mousedown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowDown' &&
      (e as unknown as KeyboardEvent).key !== 'ArrowUp'
    ) {
      setOpen(true)
      setDisableArrowKeys(true)
    }
  }

  useEffect(() => {
    setHasSolidBackground(showMappingButton)
  }, [setHasSolidBackground, showMappingButton])

  const options = useMemo(() => {
    const tempOptions: { id: string; label: string }[] = []

    dataListEntryCells.forEach(({ data_list_entry_cell_values }) => {
      if (!data_list_entry_cell_values.length) return
      const cellValue = data_list_entry_cell_values[0]
      // We filter out the saved cell value when there
      // is not a filter since we will add it to the beginning
      if (useFilter || cellValue.id !== value?.id) {
        tempOptions.push({
          id: cellValue.id,
          label: cellValue.value,
        })
      }
    })
    return useFilter || !value || value.id === 'add-new-list-item'
      ? tempOptions
      : [value, ...tempOptions]
  }, [dataListEntryCells, useFilter, value])

  const addNewOption =
    inputValue && !options.find(({ label }) => label === inputValue)

  const popperScrollRef = useRef(0)
  const onScroll: React.UIEventHandler<HTMLUListElement> = (event) => {
    const target = event.target as HTMLDivElement
    const bottom =
      Math.abs(target.clientHeight - (target.scrollHeight - target.scrollTop)) <
      5
    if (bottom) {
      if (hasNextPage) fetchNextPage()
      if (hasNextMappingPage) fetchNextMappingPage()
    }
    popperScrollRef.current = target.scrollTop
  }

  const debouncedUpdateValue = useMemo(
    () => debounce((newValue: string) => setSearchValue(newValue), 200),
    [],
  )
  // Debounce updating the search value
  useEffect(() => {
    if (inputValue !== searchValue) {
      debouncedUpdateValue(inputValue)
    }
  }, [debouncedUpdateValue, inputValue, searchValue])

  useEffect(() => {
    if (oldValueLabelRef.current !== value?.label) {
      setInputValue(value?.label || '')
      oldValueLabelRef.current = value?.label
    }
  }, [inputValue, value])

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Autocomplete<AutocompleteOption, false, true | false>
        disabled={props.disabled}
        value={value?.id === 'add-new-list-item' ? null : value}
        clearIcon={<Clear fontSize="inherit" />}
        popupIcon={<ArrowDropDown fontSize="inherit" />}
        options={options}
        onChange={(_event, newValue) => {
          onChange(newValue?.label || null, newValue?.id || null)
        }}
        onBlur={() => {
          if (!inputValue && value?.label) setInputValue(value?.label)
        }}
        open={open}
        onOpen={handleOpen}
        onClose={() => {
          setOpen(false)
          setDisableArrowKeys(false)
          popperScrollRef.current = 0
        }}
        inputValue={inputValue}
        onInputChange={(_e, newInputValue, reason) => {
          if (
            reason === 'reset' &&
            !newInputValue &&
            value?.id === 'add-new-list-item'
          ) {
            setInputValue(value?.label)
          } else {
            setInputValue(newInputValue)
          }
        }}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        filterOptions={(x) => x}
        renderInput={(params) => {
          return (
            <TextField
              {...props}
              {...params}
              label={label}
              inputProps={{
                ...params.inputProps,
                ...props.inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                ...props.InputProps,
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                ...props.InputLabelProps,
              }}
            />
          )
        }}
        // This is so we can choose the key, otherwise it throws a duplicate key error when we have duplicates
        renderOption={(props, option) => {
          return (
            <MenuItem {...props} key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          )
        }}
        PaperComponent={({ children, ...props }) => {
          return (
            <Paper
              {...props}
              onMouseDown={(e) => {
                if ((e.target as HTMLElement).tagName === 'BUTTON')
                  // This makes it so the button onClick can be triggered.
                  e.preventDefault()
              }}
            >
              {children}
              {addNewOption && (
                <>
                  <Divider />
                  <Button
                    variant="text"
                    onClick={() => {
                      setAddItemValue(inputValue)
                      addListItemOverlay.open()
                    }}
                    sx={{
                      mx: 1.5,
                      my: 1,
                      textTransform: 'capitalize',
                    }}
                  >{`Add "${inputValue}"`}</Button>
                </>
              )}
            </Paper>
          )
        }}
        ListboxComponent={forwardRef<
          HTMLDivElement,
          React.HTMLAttributes<HTMLElement>
        >((props, ref) => {
          // This is awful, but MUI autocomplete completely re-renders the list every time the data changes,
          // so it automatically scrolls to the top. This resets the scroll position each time. For some reason,
          // it needs the set timeout.
          const listRef = useRef<HTMLUListElement>(null)
          useEffect(() => {
            if (popperScrollRef.current) {
              setTimeout(() => {
                listRef.current?.scrollTo({
                  left: 0,
                  top: popperScrollRef.current,
                  behavior: 'instant',
                })
              }, 0)
            }
          }, [])
          return (
            <div ref={ref}>
              <ul {...props} ref={listRef} onScroll={onScroll} />
            </div>
          )
        })}
        ListboxProps={{
          sx: {
            maxHeight: 'min(40vh, 500px)', // 40vh matches the default max height of the autocomplete, so list resizes when window resizes
          },
        }}
        slotProps={{
          clearIndicator: {
            size: isInTable ? 'small' : undefined,
          },
          popupIndicator: {
            size: isInTable ? 'small' : undefined,
          },
          popper: {
            sx: {
              minWidth: 'fit-content',
              width: 'max-content',
            },
          },
        }}
      />

      {showMappingButton ? (
        <ListLookupAddMapping
          originalValue={original_value}
          manualValue={options.find(({ id }) => id === value?.id)?.label}
          onAddClicked={handleAddMapping}
          isAddingMapping={isAddingMapping}
        />
      ) : null}

      {dataList?.id && (
        <AddListItemDialog
          overlay={addListItemOverlay}
          dataListId={dataList.id}
          parentDataListId={data_list_id}
          lookupDataListColumnId={lookup_data_list_column_id}
          fieldValue={addItemValue}
          onAddItem={({ data_list_entry_cells }) => {
            // Find the cell for the column used by the list lookup, then get it's first value and set it as our current value
            const cell = data_list_entry_cells.find(
              ({ data_list_column_id }) =>
                data_list_column_id === lookup_data_list_column_id,
            )
            if (!cell) return // we should never get here, but for typescript's sake we have it
            const cell_value = cell.data_list_entry_cell_values[0]
            if (!cell_value) return
            onChange(cell_value.value, cell_value.id)
          }}
        />
      )}
    </Stack>
  )
}
