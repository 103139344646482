import { Stack } from '@mui/material'
import LabeledProgressIndicator from '../labeled-progress-indicator/LabeledProgressIndicator'
import ExportingIndicator from '../project-dashboard/ExportingIndicator'
import FailedDocumentsButton from '../project-dashboard/FailedDocumentsButton'
import FailedExportsButton from '../project-dashboard/FailedExportsButton'
import ProcessingIndicator from '../project-dashboard/ProcessingIndicator'
import { useFileUploadContext } from '../upload-files/FileUploadProvider'
import { useWorkflowContext } from '../workflow-provider/WorkflowProvider'

export default function ProjectIndicators() {
  const { getWorkflowStateByCode } = useWorkflowContext()

  const readyForExportWorkflowState = getWorkflowStateByCode(
    'basic',
    'ready_for_export',
  )

  const { numberToUpload } = useFileUploadContext()

  return (
    <Stack direction="row" spacing={2} id="project-indicators-container">
      <FailedDocumentsButton />

      {readyForExportWorkflowState && (
        <FailedExportsButton workflowState={readyForExportWorkflowState} />
      )}

      {numberToUpload > 0 && (
        <LabeledProgressIndicator>
          {`${numberToUpload} file${
            numberToUpload > 1 ? 's' : ''
          } uploading...`}
        </LabeledProgressIndicator>
      )}

      <ExportingIndicator />

      <ProcessingIndicator />
    </Stack>
  )
}
