import { useParams, Link } from 'react-router-dom'
import {
  Button,
  Alert,
  Stack,
  CircularProgress,
  Container,
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import WorkflowStateDetails from './WorkflowStateDetails'
import { createContext, useContext } from 'react'
import { WorkflowState } from '@/types/workflows'
import { useGetWorkflowState } from '@/service-library/hooks/workflow-states'

export const WorkflowStateContext = createContext<WorkflowState | undefined>(
  undefined,
)

export const useWorkflowStatePageContext = () =>
  useContext(WorkflowStateContext)

export default function WorkflowStatePage() {
  const { id } = useParams()

  const { workflowState, isLoading, queryKey } = useGetWorkflowState({
    id: id as string,
    filters: {
      fields__include: 'excluded_project_grid_fields_ids',
    },
  })

  return (
    <WorkflowStateContext.Provider value={workflowState}>
      <Container maxWidth="md">
        <Breadcrumb label="Workflow States" url="../workflow-states" />
        {workflowState && (
          <Breadcrumb
            label={workflowState?.name || ''}
            url={`../workflow-states/${workflowState?.id}`}
          />
        )}

        {!isLoading && !workflowState && (
          <Alert severity="error">
            Unable to load this workflow state. You may not have access or this
            workflow state does not exist.
          </Alert>
        )}

        {isLoading ? (
          <Stack alignItems="center" sx={{ p: 4 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Button
              sx={{ ml: '-4px', my: 2 }}
              startIcon={<ArrowBack />}
              component={Link}
              to="../workflow-states"
              variant="text"
            >
              Back to Workflow States
            </Button>
            {workflowState && (
              <WorkflowStateDetails
                workflowState={workflowState}
                detailQueryKey={queryKey}
              />
            )}
          </>
        )}
      </Container>
    </WorkflowStateContext.Provider>
  )
}
