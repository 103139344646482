import { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ListItemButton, Tooltip, Typography } from '@mui/material'
import { animated, useSpring } from '@react-spring/web'
import { Document } from '@/types/documents'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import DocumentThumbnail from '@/components/document-thumbnails/DocumentThumbnail'
import LeftBorderedBox from './LeftBorderedBox'

type ValidationDrawerItemProps = {
  document: Document
  currentDocumentId: string
  isHiddenItem?: boolean
  shouldAnimate?: boolean
}

export default function ValidationDrawerItem({
  document,
  currentDocumentId,
  isHiddenItem,
  shouldAnimate,
}: ValidationDrawerItemProps) {
  const { search } = useLocation()
  const itemRef = useRef<HTMLAnchorElement>(null)
  const isSelectedDocument = currentDocumentId === document.id

  const animatedStyle = useSpring({
    from: {
      transform: 'translateX(-100%)',
      padding: '4px 16px',
      width: '100%',
    },
    to: { transform: 'translateX(0%)', padding: '4px 16px', width: '100%' },
    config: { tension: 300, friction: 25 },
    immediate: !shouldAnimate,
  })

  const getThemeColor = useGetThemeColor()

  const { selectedWorkflow } = useSelectedWorkflowContext()

  const workflowState = selectedWorkflow?.workflow_states?.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  useEffect(() => {
    if (isSelectedDocument) {
      itemRef.current?.scrollIntoView({
        block: 'nearest',
      })
    }
  }, [isSelectedDocument])

  return (
    <>
      <Tooltip
        enterDelay={500}
        placement="right"
        disableInteractive
        disableHoverListener={!document.image_urls?.thumbnail}
        title={
          document.image_urls ? (
            <DocumentThumbnail
              image_urls={document.image_urls}
              sx={{
                pt: 0.5,
              }}
            />
          ) : (
            ''
          )
        }
      >
        <ListItemButton
          dense
          key={document.id}
          data-document-id={document.id}
          selected={isSelectedDocument}
          component={Link}
          to={
            currentDocumentId
              ? `../${document.id}${search}`
              : `${document.id}${search}`
          }
          relative="path"
          ref={itemRef}
          sx={{ p: 0 }}
        >
          <animated.div style={animatedStyle}>
            <LeftBorderedBox color={getThemeColor(workflowState?.color)}>
              <Typography variant="body2" noWrap>
                {document.name}
              </Typography>
              {isHiddenItem && (
                <Typography variant="caption" noWrap color="text.secondary">
                  <i>Not included with current filters</i>
                </Typography>
              )}
            </LeftBorderedBox>
          </animated.div>
        </ListItemButton>
      </Tooltip>
    </>
  )
}
