import {
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  RefObject,
} from 'react'
import { DocumentChip } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { useDocumentContext } from '../image-zoom-pan/providers/DocumentProvider'
import {
  getIntersectingChips,
  Position,
  Size,
  convertMouseEventPoint,
  rotatePoint,
  getChipsInRangeSelection,
  getChipsOnRows,
} from '../image-zoom-pan/image-zoom-pan-helpers'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'
import { useCreateUpdateDocumentRowsWithCleanup } from '@/service-library/hooks/document-rows'
import generateUuid from '@/utils/generate-uuid'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'

type UseDocumentChipActionsOptions = {
  canvasSize: Size
  canvasRef: RefObject<HTMLCanvasElement>
  chipsOverlay: OverlayState
  currentPageChips: DocumentChip[]
  drawBoxes: boolean
  image: HTMLImageElement
  rotationDegree: number
  setAnchorPosition: Dispatch<
    SetStateAction<{
      left: number
      top: number
    }>
  >
}

export default function useDocumentChipActions({
  canvasSize,
  canvasRef,
  chipsOverlay,
  currentPageChips,
  drawBoxes,
  image,
  rotationDegree,
  setAnchorPosition,
}: UseDocumentChipActionsOptions) {
  const [clickedChipsIds, setClickedChipsIds] = useState<string[]>([])
  const { document } = useDocumentContext()

  const { isDisabled, queryKey } = useDocumentContext()
  const { selectedField, selectedFieldChips, isSelectedField } =
    useSelectedFieldContext()
  const { updateDocumentChipField, clearDocumentChipField } =
    useDocumentChipsContext()

  const popoverChips = useMemo(
    () => currentPageChips.filter(({ id }) => clickedChipsIds.includes(id)),
    [clickedChipsIds, currentPageChips],
  )

  const shouldNotAllowRangeSelection =
    selectedField &&
    (selectedField.field.project_grid_field_type.code === 'checkbox' ||
      selectedField.field.input_behavior === 'manual_only')

  function handleClickedPolygon(targetChip: DocumentChip) {
    // If it's already selected for the selected field, attach it.
    // If it's already attached to a field, but we don't have a selected field, unattach it.
    if (
      (!selectedField && targetChip.project_grid_field_id) ||
      isSelectedField(
        targetChip.project_grid_field_id,
        targetChip.document_row_id,
      )
    ) {
      clearDocumentChipField(targetChip)
    } else if (selectedField) {
      if (
        selectedField.field.project_grid_field_type.code === 'checkbox' &&
        selectedFieldChips.length
      ) {
        clearDocumentChipField(selectedFieldChips[0])
      }
      updateDocumentChipField(
        targetChip,
        selectedField.field.id,
        selectedField.document_row_id,
      )
    }
  }

  const onCanvasClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    if (
      (canvasSize.width === 0 && canvasSize.height === 0) ||
      image === null ||
      selectedField?.field.input_behavior === 'manual_only' ||
      !drawBoxes ||
      event.shiftKey ||
      isDisabled
    ) {
      return
    }
    const canvas = canvasRef.current

    if (canvas) {
      const clickedPoint = convertMouseEventPoint({ event, canvas })

      const point = rotatePoint({
        point: clickedPoint,
        rotationDegree,
        image,
      })

      // We need to handle for rotation, so let's rotate the POINT instead of anything else,
      // so our math can act as if the image is not rotated. We add the image height or width
      // or both depending on the rotation degree since we also translate the canvas when we rotate.

      const selectedFieldIsCheckbox =
        selectedField?.field.project_grid_field_type.code === 'checkbox'

      const clickedChips = getIntersectingChips({
        image,
        point,
        chips: currentPageChips,
        additionalCondition: selectedFieldIsCheckbox
          ? (chip) => chip.text === 'FalseBox' || chip.text === 'TrueBox'
          : (chip) => chip.text !== 'FalseBox' && chip.text !== 'TrueBox',
      })

      if (clickedChips.length > 1) {
        chipsOverlay.open()
        setAnchorPosition({ left: event.clientX, top: event.clientY })
        setClickedChipsIds(clickedChips.map(({ id }) => id))
      } else if (clickedChips.length === 1) {
        handleClickedPolygon(clickedChips[0])
      }
    }
  }

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { createOrUpdateDocumentRows } = useCreateUpdateDocumentRowsWithCleanup(
    {
      sideEffectQueryKeys: [
        queryKey,
        queryKeys.documentRows.lists(),
        queryKeys.documentRowValues.lists(),
      ],
      workflowId: selectedWorkflow.id,
      onError: () => {
        showErrorSnackbar('Unable to add row(s). Please try again later.')
      },
    },
  )

  const onRangeSelection = useCallback(
    async (
      rangeCoordinates: { origin: Position; end: Position },
      selectionMode: 'selecting' | 'deselecting' | 'multi-selecting',
    ) => {
      const { origin, end } = rangeCoordinates

      const chipsInSelection = getChipsInRangeSelection({
        image,
        selectionMode,
        chips: currentPageChips,
        polygon: [
          { x: origin.x, y: origin.y },
          { x: end.x, y: origin.y },
          { x: end.x, y: end.y },
          { x: origin.x, y: end.y },
        ],
        selectedField,
      })

      if (selectionMode === 'selecting' && selectedField) {
        // useDocumentChips will batch these calls.
        chipsInSelection.forEach((chip) => {
          updateDocumentChipField(
            chip,
            selectedField.field.id,
            selectedField.document_row_id,
          )
        })
      } else if (
        selectionMode === 'multi-selecting' &&
        selectedField &&
        canvasRef.current &&
        document
      ) {
        const tableDocumentRows =
          document?.document_rows.filter(
            ({ project_grid_id }) =>
              project_grid_id === selectedField.field.project_grid_id,
          ) || []
        const currentRowIndex = tableDocumentRows?.findIndex(
          ({ id }) => id === selectedField.document_row_id,
        )

        const selectedChipRows = getChipsOnRows(
          chipsInSelection,
          canvasRef.current,
        )

        const numberOfAvailableRowsToPopulate =
          tableDocumentRows.length - currentRowIndex

        const numberOfMissingRows =
          selectedChipRows.length - numberOfAvailableRowsToPopulate

        let docRows = tableDocumentRows

        // If we don't have enough rows available, create new ones first.
        if (numberOfMissingRows > 0) {
          showInfoSnackbar('Creating new rows to accommodate selection.')
          const sortedDocumentRows = document.document_rows.toSorted(
            (a, b) => a.row_number - b.row_number,
          )
          const newRows = Array.from({ length: numberOfMissingRows }).map(
            () => {
              return {
                id: generateUuid(),
                row_number:
                  (sortedDocumentRows.at(-1)?.row_number || 0) + 1 || 1,
                project_grid_id: selectedField.field.project_grid_id as string,
                document_id: document.id as string,
              }
            },
          )
          // Have to wait for this to finish so we don't try to assign chips to fields that don't exist yet.
          await createOrUpdateDocumentRows(newRows)
          docRows = [...docRows, ...newRows]
        }

        selectedChipRows.forEach((chips, index) => {
          const targetDocumentRow = docRows[currentRowIndex + index]
          if (targetDocumentRow) {
            chips.forEach((chip) => {
              updateDocumentChipField(
                chip,
                selectedField.field.id,
                targetDocumentRow.id,
              )
            })
          }
        })
      } else {
        // useDocumentChips will batch these calls.
        chipsInSelection.forEach((chip) => clearDocumentChipField(chip))
      }
    },
    [
      image,
      currentPageChips,
      selectedField,
      canvasRef,
      document,
      updateDocumentChipField,
      createOrUpdateDocumentRows,
      clearDocumentChipField,
    ],
  )

  return {
    popoverChips,
    shouldNotAllowRangeSelection,
    handleClickedPolygon,
    onCanvasClick,
    onRangeSelection,
  }
}
