import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { OverlayState } from '@/hooks/useOverlay'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { PixyDocsForm } from '@/components/forms'

type FieldInfo = {
  field: ProjectGridField
  fields?: ProjectGridField[]
}

type SetStateFieldsDialogProps = {
  overlay: OverlayState
  excludedFieldsIds: string[]
  fieldInfos: FieldInfo[]
  onSave: (excludedGridFieldsIds: string[]) => void
}

export default function SetStateFieldsDialog({
  overlay,
  excludedFieldsIds,
  fieldInfos,
  onSave,
}: SetStateFieldsDialogProps) {
  const methods = useForm({
    defaultValues: {
      excluded_project_grid_fields_ids: excludedFieldsIds,
    },
  })

  const {
    formState: { isDirty, isValid },
    reset,
    setValue,
    watch,
  } = methods

  const excludedGridFieldsIds = watch('excluded_project_grid_fields_ids')
  function handleSetFields(data: {
    excluded_project_grid_fields_ids: string[]
  }) {
    onSave(data.excluded_project_grid_fields_ids)
    overlay.close()
  }

  function selectField({
    checked,
    fieldId,
    childFields,
    parentFieldId,
  }: {
    checked: boolean
    fieldId: string
    childFields?: ProjectGridField[]
    parentFieldId?: string
  }) {
    const newExcludedFieldsIds = new Set(excludedGridFieldsIds)
    if (checked) {
      newExcludedFieldsIds.delete(fieldId)
      if (childFields) {
        childFields.forEach(({ id }) => newExcludedFieldsIds.delete(id))
      }
      if (parentFieldId && newExcludedFieldsIds.has(parentFieldId)) {
        newExcludedFieldsIds.delete(parentFieldId)
      }
    } else {
      newExcludedFieldsIds.add(fieldId)
      if (childFields) {
        childFields.forEach(({ id }) => newExcludedFieldsIds.add(id))
      }
    }
    setValue(
      'excluded_project_grid_fields_ids',
      Array.from(newExcludedFieldsIds),
      { shouldDirty: true },
    )
  }

  useEffect(() => {
    !!overlay.isOpen &&
      reset({ excluded_project_grid_fields_ids: excludedFieldsIds })
  }, [excludedFieldsIds, overlay.isOpen, reset])

  return (
    <Dialog title="Set Fields" {...overlay} maxWidth="xs">
      <DialogContent>
        <PixyDocsForm
          id="set-fields-form"
          methods={methods}
          onSubmit={handleSetFields}
        >
          <Stack>
            {fieldInfos.map((fieldInfo) => {
              const mainFieldId = fieldInfo.field.id
              const mainFieldIsSelected =
                !excludedGridFieldsIds.includes(mainFieldId)
              const childFields = fieldInfo.fields

              const allChildFieldsSelected = childFields?.every(
                ({ id }) => !excludedGridFieldsIds.includes(id),
              )

              const someChildFieldsSelected = childFields?.some(
                ({ id }) => !excludedGridFieldsIds.includes(id),
              )

              return !childFields ? (
                <FormControlLabel
                  key={mainFieldId}
                  label={fieldInfo.field.name}
                  control={
                    <Checkbox
                      checked={mainFieldIsSelected}
                      onChange={(_e, checked) => {
                        selectField({
                          checked,
                          fieldId: mainFieldId,
                        })
                      }}
                    />
                  }
                  sx={{
                    width: 'fit-content',
                  }}
                />
              ) : (
                <Stack key={mainFieldId}>
                  <FormControlLabel
                    label={fieldInfo.field.name}
                    control={
                      <Checkbox
                        checked={allChildFieldsSelected}
                        indeterminate={
                          !allChildFieldsSelected && someChildFieldsSelected
                        }
                        onChange={(_e, checked) => {
                          selectField({
                            checked,
                            fieldId: mainFieldId,
                            childFields,
                          })
                        }}
                      />
                    }
                    sx={{
                      width: 'fit-content',
                    }}
                  />

                  {childFields.map((subField) => {
                    return (
                      <FormControlLabel
                        key={subField.id}
                        label={subField.name}
                        control={
                          <Checkbox
                            checked={
                              !excludedGridFieldsIds.includes(subField.id)
                            }
                            onChange={(_e, checked) => {
                              selectField({
                                checked,
                                fieldId: subField.id,
                                parentFieldId: mainFieldId,
                              })
                            }}
                          />
                        }
                        sx={{
                          width: 'fit-content',
                          ml: 3,
                        }}
                      />
                    )
                  })}
                </Stack>
              )
            })}
          </Stack>
        </PixyDocsForm>
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          disabled={!isValid || !isDirty}
          type="submit"
          form="set-fields-form"
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
