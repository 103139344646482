import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import {
  DataObject,
  KeyboardArrowLeft,
  Settings,
  StackedBarChart,
} from '@mui/icons-material'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import { blue } from '@mui/material/colors'
import { Organization } from '@/types/organizations'
import { ProjectModel } from '@/types/project-models'
import { useGetOrgProjectModel } from '@/service-library/hooks/project-models'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import PageTitle from '@/components/PageTitle'
import NavigationRail from '@/components/navigation-rail/NavigationRail'
import NavigationRailItem from '@/components/navigation-rail/NavigationRailItem'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import PixelGridBackground from '@/components/pixel-grid-background/PixelGridBackground'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import { getTrainingVersion } from './helpers'
import IsInheritingMessage from './IsInheritingMessage'
import ModelTreeView from './ModelTreeView'
import SelectedOrgProvider from './SelectedOrgProvider'

export default function ModelsPage() {
  const { pathname } = useLocation()
  const { id } = useParams()

  const modelRoutes = [
    {
      label: 'Back to Models',
      to: '.',
      icon: <KeyboardArrowLeft />,
    },
    {
      label: 'Model Dashboard',
      to: `${id}/dashboard`,
      icon: <StackedBarChart />,
    },
    {
      label: 'Model Data',
      to: `${id}/data`,
      icon: <DataObject />,
    },
    {
      label: 'Model Settings',
      to: `${id}/settings`,
      icon: <Settings />,
    },
  ]

  const { rootOrganization } = useRootOrganization()
  const { organizations } = useOrganizationsContext()

  const [selectedOrg, setSelectedOrg] = useState<Organization>(rootOrganization)
  const [rootModel, setRootModel] = useState<ProjectModel>()

  const { projectModel, isLoading } = useGetOrgProjectModel({
    id: id || '',
    orgId: selectedOrg.id,
    filters: {
      fields__include:
        'project_model_versions,has_children,project_grid_fields',
      project_grid_fields__fields__only: 'id,metadata,params',
    },
    enabled: !!selectedOrg.id,
  })

  const trainingVersion = getTrainingVersion(
    projectModel?.project_model_versions || [],
  )

  const isInheritingFromParent =
    projectModel &&
    ((!projectModel.org_id && selectedOrg.id !== rootOrganization.id) ||
      (projectModel.org_id && projectModel.org_id !== selectedOrg.id))

  const mainModelId = useRef(id)

  // In some cases, the user can navigate to a different projectModel while the selectedOrg
  // is not the root organization. In this case, we want to reset the selectedOrg to the root organization.
  useEffect(() => {
    if (mainModelId.current !== id) {
      setRootModel(undefined)
      setSelectedOrg(rootOrganization)
      mainModelId.current = id
    }
  }, [id, rootOrganization])

  useEffect(() => {
    if (projectModel && !projectModel.parent_model_id) {
      setRootModel(projectModel)
    }
  }, [projectModel])

  return (
    <Box>
      <SelectedOrgProvider
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
        organizations={organizations}
      >
        <PageTitle>{projectModel?.name || 'Model'}</PageTitle>
        <Breadcrumb label="Models" url="." />
        {projectModel && (
          <Breadcrumb label={projectModel.name} url={projectModel.id} />
        )}

        {!pathname.includes('/models/') && <Outlet />}

        {pathname.includes('/models/') && (
          <>
            <NavigationRail
              sidePanelJunk={
                rootModel?.has_children ? (
                  <ModelTreeView
                    selectedOrg={selectedOrg}
                    setSelectedOrg={setSelectedOrg}
                  />
                ) : null
              }
              navigationItems={
                <>
                  {modelRoutes.map((route) => {
                    return (
                      <Tooltip
                        key={route.to}
                        title={route.label}
                        placement="right"
                      >
                        <Box>
                          <NavigationRailItem
                            to={route.to}
                            selected={pathname.includes(route.to)}
                          >
                            {route.icon}
                          </NavigationRailItem>
                        </Box>
                      </Tooltip>
                    )
                  })}

                  {!!trainingVersion && (
                    <Box sx={{ pt: 1 }}>
                      <Tooltip title="Training" placement="right">
                        <CircularProgress
                          size={20}
                          sx={{
                            color: blue['A700'],
                          }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </>
              }
            />
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            )}
            {!isLoading &&
              (isInheritingFromParent ? (
                <IsInheritingMessage
                  parentOrg={
                    organizations.find(
                      (org) => org.id === projectModel?.org_id,
                    ) || rootOrganization
                  }
                />
              ) : (
                <Outlet context={{ rootModel }} />
              ))}
          </>
        )}

        <PixelGridBackground animateBackground={!!trainingVersion} />
      </SelectedOrgProvider>
    </Box>
  )
}
