import { useState, useMemo } from 'react'
import { MRT_ColumnDef } from 'material-react-table'
import { Add, Delete, Check, AccountTree } from '@mui/icons-material'
import {
  CircularProgress,
  Stack,
  Alert,
  Button,
  Box,
  Container,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { Integration } from '@/types/integrations'
import { Organization } from '@/types/organizations'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { useOrganizationsContext } from '@/components/organizations/OrganizationsProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import useZerapixTable from '@/components/zerapix-table/useZerapixTable'
import NewIntegrationDialog from './NewIntegrationDialog'
import {
  useDeleteIntegration,
  useGetIntegrations,
} from '@/service-library/hooks/integrations'

type ProjectIntegrationsDisplayProps = {
  organizations: Organization[]
  isFetching: boolean
}

function ProjectIntegrationsDisplay({
  organizations,
  isFetching,
}: ProjectIntegrationsDisplayProps) {
  const { project } = useProjectContext()
  const [demoMode] = useDemoModeContext()
  const addIntegrationOverlay = useOverlay()
  const isSuperUser = useIsSuperUser()

  const columns: MRT_ColumnDef<Integration>[] = useMemo(() => {
    const columns: MRT_ColumnDef<Integration>[] = [
      {
        accessorKey: 'name',
        header: 'Integration',
      },
      {
        accessorKey: 'is_enabled',
        header: 'Enabled',
        Cell: ({ row }) => {
          return row.original.is_enabled ? <Check color="success" /> : null
        },
      },
      {
        accessorKey: 'org_id',
        header: 'Organization',
        Cell: ({ row }) => {
          const org = organizations.find(
            (org) => org.id === row.original.org_id,
          )
          return org?.name
        },
      },
    ]
    if (!demoMode && isSuperUser) {
      columns.push({
        accessorKey: 'project_id',
        header: '',
        maxSize: 60,
        Cell: ({ row }) => {
          return (
            <Tooltip title="Edit Mapping">
              <IconButton
                component="a"
                href={`/projects/${project.id}/integrations/${row.original.id}/mapping`}
                target="_blank"
              >
                <AccountTree />
              </IconButton>
            </Tooltip>
          )
        },
      })
    }
    return columns
  }, [demoMode, isSuperUser, organizations, project.id])

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>(organizations[0])

  const {
    integrations: allIntegrations = [],
    isLoading,
    queryKey,
  } = useGetIntegrations({
    filters: {
      project_id: project.id,
      limit: '100',
    },
    enabled: !!project.id,
  })

  const integrations = allIntegrations.filter(({ external_id }) => !external_id)

  const { deleteIntegration } = useDeleteIntegration({
    listQueryKey: queryKey,
  })

  const table = useZerapixTable<Integration>({
    title: 'Integrations',
    data: integrations,
    columns,
    initialState: {
      density: 'comfortable',
    },
    state: {
      showSkeletons: isLoading,
    },
    localization: {
      noRecordsToDisplay: 'No integrations to display',
    },
    enableRowActions: true,
    renderTableActions: () => {
      return (
        <Box>
          <Button
            variant="text"
            startIcon={<Add />}
            onClick={addIntegrationOverlay.open}
          >
            New Integration
          </Button>
          <NewIntegrationDialog
            overlay={addIntegrationOverlay}
            isFetchingOrgs={isFetching}
            listQueryKey={queryKey}
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
          />
        </Box>
      )
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => {
      return [
        <MenuItem
          key="delete"
          onClick={() => {
            deleteIntegration(row.original.id)
            closeMenu()
          }}
        >
          <ListItemIcon>
            <Delete color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="inherit" color="error">
              Delete
            </Typography>
          </ListItemText>
        </MenuItem>,
      ]
    },
  })

  return (
    <Container maxWidth="md">
      <ZerapixTable<Integration> table={table} />
    </Container>
  )
}

export default function ProjectIntegrations() {
  const { organizations, isFetchingAll, isLoading, error } =
    useOrganizationsContext()

  if (isLoading)
    return (
      <Stack sx={{ py: 8 }} alignItems="center">
        <CircularProgress />
      </Stack>
    )

  if (error)
    return (
      <Stack sx={{ py: 8 }} alignItems="center">
        <Alert severity="error" variant="outlined">
          There was a problem loading integrations. Please try again later.
        </Alert>
      </Stack>
    )

  return (
    <>
      <Breadcrumb label="Integrations" url="../integrations" />
      <ProjectIntegrationsDisplay
        organizations={organizations}
        isFetching={isFetchingAll}
      />
    </>
  )
}
