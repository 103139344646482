import { Fragment, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import {
  Button,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Skeleton,
  Tooltip,
} from '@mui/material'
import { Edit, Person, PersonOff } from '@mui/icons-material'
import { QueryKey } from '@tanstack/react-query'
import { User } from '@/types/users'
import useOverlay from '@/hooks/useOverlay'
import usePermission from '@/hooks/usePermission'
import { showErrorSnackbar } from '@/utils/snackbars'
import { useGetGroups } from '@/service-library/hooks/groups'
import { useGetTeams } from '@/service-library/hooks/teams'
import {
  useActivateDeactivateUser,
  useUpdateUser,
} from '@/service-library/hooks/users'
import { useAuthentication } from '@/components/auth/AuthProvider'
import CopyIDButton from '@/components/copy-id-button/CopyIDButton'
import LabeledData from '@/components/labeled-data/LabeledData'
import LargeHeading from '@/components/large-heading/LargeHeading'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import PermissionTooltip from '@/components/tooltip/PermissionTooltip'
import EditUserTeamsDialog from './EditUserTeamsDialog'

type UserDetailsProps = {
  user: User
  detailQueryKey: QueryKey
}

export default function UserDetails({
  user,
  detailQueryKey,
}: UserDetailsProps) {
  const { user: loggedInUser } = useAuthentication()
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()
  const { groups, isLoading } = useGetGroups({
    refetchOnWindowFocus: false,
  })
  const {
    teams,
    isLoading: teamsIsLoading,
    queryKey: teamsQueryKey,
  } = useGetTeams({
    filters: {
      id__in: user.teams_ids?.join(),
      // Adding these filters to filter out deprecated teams
      is_system_managed: 'false',
      owner_org_id: rootOrganization.id,
    },
  })
  const [internalGroupsIds, setInternalGroupsIds] = useState(
    user.pd_groups_ids as string[],
  )

  const editTeamsOverlay = useOverlay()

  const { updateUser } = useUpdateUser({
    detailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update user. Please try again later.')
      setInternalGroupsIds(user.pd_groups_ids as string[])
    },
  })

  const { activateDeactivateUser } = useActivateDeactivateUser({
    detailQueryKey,
    onError: () => {
      showErrorSnackbar('Unable to update user status. Please try again later.')
    },
  })

  const canEditUser = hasEditingPermission('edit_users', user.owner_org_id)

  const debouncedUpdateUser = useMemo(
    () => debounce(updateUser, 1000),
    [updateUser],
  )

  const handleActivateDeactivate = () => {
    const updatedUser = { ...user }
    updatedUser.is_active = !user.is_active
    activateDeactivateUser(updatedUser)
  }

  const handleUpdateGroup = (groupId: string, checked: boolean) => {
    let newGroupsIds = [...internalGroupsIds]
    if (checked) {
      newGroupsIds.push(groupId)
    } else {
      newGroupsIds = newGroupsIds.filter((id) => id !== groupId)
    }
    setInternalGroupsIds(newGroupsIds)

    if (JSON.stringify(user.pd_groups_ids) !== JSON.stringify(newGroupsIds)) {
      const updatedUser = {
        ...user,
        pd_groups_ids: newGroupsIds,
        pd_groups: groups.filter(({ id }) => newGroupsIds.includes(id)),
      }
      debouncedUpdateUser(updatedUser)
    }
  }

  const canAssignToTeams = hasEditingPermission('edit_teams')
  const TeamWrapper = teamsIsLoading ? Skeleton : Fragment
  if (!loggedInUser) return null

  return (
    <Stack spacing={2}>
      <div>
        <LargeHeading
          heading={user.name}
          subHeading={`User${loggedInUser.id === user.id ? ' (You)' : ''}`}
          actions={
            <>
              <PermissionTooltip canEdit={canEditUser}>
                <Button
                  color={user.is_active ? 'error' : 'secondary'}
                  disabled={!canEditUser}
                  onClick={handleActivateDeactivate}
                  startIcon={user.is_active ? <PersonOff /> : <Person />}
                  variant="text"
                >
                  {user.is_active ? 'Deactivate' : 'Activate'}
                </Button>
              </PermissionTooltip>
              <CopyIDButton stringToCopy={user.id} />
            </>
          }
        />
      </div>
      <Stack spacing={3}>
        <LabeledData label="Name" data={user.name} />
        <LabeledData label="Email" data={user.email} />
        <Stack spacing={1}>
          <Typography variant="h6" component="h2">
            Permission Groups
          </Typography>
          {isLoading ? (
            <Stack spacing={2} sx={{ maxWidth: '300px' }}>
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
              <Skeleton variant="rounded" height={30} />
            </Stack>
          ) : (
            <>
              {groups.map((group) => (
                <PermissionTooltip
                  canEdit={
                    user.id === loggedInUser.id ? false : group.can_assign_users
                  }
                  key={group.id}
                >
                  <FormControlLabel
                    label={
                      <Stack>
                        <Typography>{group.name}</Typography>
                        <Typography color="textSecondary" variant="caption">
                          {group.description}
                        </Typography>
                      </Stack>
                    }
                    control={
                      <Checkbox
                        checked={internalGroupsIds?.includes(group.id)}
                        disabled={
                          !group.can_assign_users || user.id === loggedInUser.id
                        }
                        onChange={(e, value) =>
                          handleUpdateGroup(group.id, value)
                        }
                        value={group.id}
                        sx={{ mt: -1 }}
                      />
                    }
                    sx={{
                      alignItems: 'flex-start',
                    }}
                  />
                </PermissionTooltip>
              ))}
              <Tooltip
                title="All users have these permissions."
                enterDelay={1000}
              >
                <div style={{ width: 'fit-content' }}>
                  <FormControlLabel
                    label={
                      <Stack>
                        <Typography>User</Typography>
                        <Typography color="textSecondary" variant="caption">
                          Basic user permissions.
                        </Typography>
                      </Stack>
                    }
                    control={
                      <Checkbox disabled checked={true} sx={{ mt: -1 }} />
                    }
                    sx={{
                      alignItems: 'flex-start',
                    }}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="h6" component="h2">
              Teams
            </Typography>
            <PermissionTooltip canEdit={canAssignToTeams}>
              <Button
                disabled={!canAssignToTeams}
                variant="text"
                startIcon={<Edit />}
                onClick={editTeamsOverlay.open}
              >
                Edit
              </Button>
            </PermissionTooltip>
          </Stack>
          {teams.map((team) => (
            <TeamWrapper key={team.id}>
              <Typography>{team.name}</Typography>
            </TeamWrapper>
          ))}
          <EditUserTeamsDialog
            overlay={editTeamsOverlay}
            user={user}
            detailQueryKey={detailQueryKey}
            teamsQueryKey={teamsQueryKey}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
