import { Typography } from '@mui/material'
import DeleteConfirmDialog from '@/components/delete-confirm-dialog/DeleteConfirmDialog'
import { OverlayState } from '@/hooks/useOverlay'
import { Project } from '@/types/projects'

type DeleteFieldDialogProps = {
  overlay: OverlayState
  name: Project['name']
  onDelete: () => void
}

export default function DeleteProjectDialog({
  name,
  onDelete,
  overlay,
}: DeleteFieldDialogProps) {
  return (
    <DeleteConfirmDialog
      overlay={overlay}
      title="Delete Project"
      onDelete={onDelete}
      textToMatch={name}
    >
      <Typography component="div">
        <Typography>
          You are about to delete a project. Deleting a project will also
          delete:
        </Typography>
        <ul>
          <li>All documents associated with the project</li>
          <li>
            All of the project&apos;s configuration (fields, sources, etc.)
          </li>
        </ul>
      </Typography>
    </DeleteConfirmDialog>
  )
}
