import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { Workflow } from '@/types/workflows'

const service = buildService<Workflow>({
  pathCategory: 'workflows',
})

export function useGetWorkflow({
  id,
  ...options
}: UseGetDetailOptions<Workflow>) {
  const query = useGetDetail<Workflow>({
    id,
    queryKey: queryKeys.workflows.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    workflow: query.data,
    ...query,
  }
}

export function useCreateWorkflow(
  options: UseCreateDetailOptions<Workflow> = {},
) {
  const mutation = useCreateDetail<Workflow>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createWorkflow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateWorkflow(
  options: UseUpdateDetailOptions<Workflow> = {},
) {
  const mutation = useUpdateDetail<Workflow>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateWorkflow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflow(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<Workflow>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteWorkflow: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetWorkflows({
  filters,
  ...options
}: UseGetListOptions<Workflow> = {}) {
  const query = useGetList<Workflow>({
    filters,
    queryKey: queryKeys.workflows.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    workflows: query.allData,
    ...query,
  }
}

export function useUpdateWorkflows(
  options: UseUpdateListOptions<Workflow> = {},
) {
  const mutation = useUpdateList<Workflow>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateWorkflows: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteWorkflows({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Workflow>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteWorkflows: mutation.mutateAsync,
    ...mutation,
  }
}
