import { useState } from 'react'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import useOverlay from '@/hooks/useOverlay'
import useDocumentSet from '@/services/hooks/useDocumentSet'
import { useTriggerWorkflowStateEvent } from '@/service-library/hooks/events'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import EventButton from '@/components/validation/EventButton'
import DocumentsWithErrorsDialog from '@/components/validation/DocumentsWithErrorsDialog'

type DashboardEventButtonProps = {
  documentIds: string[]
  workflowStateId: string
}

export default function DashboardEventButton({
  documentIds,
  workflowStateId,
}: DashboardEventButtonProps) {
  const [triggeredEvent, setTriggeredEvent] = useState<WorkflowStateEvent>()

  const hasErrorsOverlay = useOverlay()

  const { documents: documentsWithErrors } = useDocumentSet({
    documentIds,
    fieldsOnly: 'id,name',
    flagCodes: ['error', 'warning'],
    enabled: !!documentIds.length,
  })

  const { workflowStateEvents = [] } = useGetWorkflowStateEvents({
    filters: {
      workflow_state_id: workflowStateId,
    },
  })
  const visibleWorkflowStateEvents = workflowStateEvents.filter(
    ({ code }) => code !== 'on_enter' && code !== 'on_exit',
  )

  const { triggerWorkflowStateEvent } = useTriggerWorkflowStateEvent()

  const hasProblemFlags = !!documentsWithErrors?.length

  function triggerEvent(event: WorkflowStateEvent) {
    triggerWorkflowStateEvent(
      documentIds.map((id) => ({
        workflow_state_event_id: event.id,
        document_id: id,
      })),
    )
  }

  function handleTriggerEvent(event: WorkflowStateEvent) {
    if (
      hasProblemFlags &&
      event.when_document_has_errors === 'trigger_with_warning'
    ) {
      hasErrorsOverlay.open()
      setTriggeredEvent(event)
    } else {
      triggerEvent(event)
    }
  }

  return visibleWorkflowStateEvents.length > 0 ? (
    <>
      <EventButton
        inDashboard
        workflowStateEvents={visibleWorkflowStateEvents}
        disabled={!documentIds.length}
        documentsIds={documentIds}
        documentsHaveErrors={hasProblemFlags}
        onTriggerEvent={handleTriggerEvent}
      />
      <DocumentsWithErrorsDialog
        inDashboard
        overlay={hasErrorsOverlay}
        onContinue={() => {
          triggeredEvent && triggerEvent(triggeredEvent)
          setTriggeredEvent(undefined)
          hasErrorsOverlay.close()
        }}
      />
    </>
  ) : null
}
