import { ReactNode, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { LoadingButton } from '@mui/lab'
import {
  Close,
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
} from '@mui/icons-material'
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { DocumentChip } from '@/types/documents'
import { OverlayState } from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { modHotKeyStr } from '@/utils/get-os'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import Drawer from '@/components/drawer/Drawer'
import { useDocumentChipsContext } from './providers/DocumentChipsProvider'
import { SelectedField } from './providers/SelectedFieldProvider'
import useFieldValue, { DataLineageValues } from './useFieldValue'
import AddressData from './AddressData'
import FieldInfoLabeledValue from './FieldInfoLabeledValue'
import ListEntryData from './ListEntryData'

type FieldInfoDrawerProps = {
  overlay: OverlayState
  chips: DocumentChip[]
  selectedField: SelectedField | null
}

const dataLineage = {
  original_value: {
    name: 'Original Value',
    description:
      'This value comes from the combined values of any chips attached to the field.',
  },
  rule_value: {
    name: 'Rule Value',
    description:
      'This value is calculated by passing the original value through any rules applied to the field.',
  },
  manual_value: {
    name: 'Manual Value',
    description:
      'This value is only populated if you have manually changed the value.',
  },
  final_value: {
    name: 'Final Value',
    description:
      'This value is set based on the other three values. It will use the manual value first, but if it is not available, it will use the rule or original value. This is the value used when the document is exported.',
  },
  value: {
    name: 'Value',
  },
}

export default function FieldInfoDrawer({
  overlay,
  chips,
  selectedField,
}: FieldInfoDrawerProps) {
  const { document_row_id, field } = selectedField || {}
  const [isLoading, setIsLoading] = useState(false)
  const { value, refId, dataLineageValues, documentRowValue } = useFieldValue({
    fieldId: field?.id || '',
    documentRowId: document_row_id,
  })
  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()
  const { metadata } = documentRowValue || {}

  const jsonValue = documentRowValue?.final_value_json?.us_address
    ? documentRowValue.final_value_json
    : documentRowValue?.rule_value_json?.us_address
    ? documentRowValue.rule_value_json
    : null

  const dataValues =
    field?.project_grid_field_type.code === 'category'
      ? {
          value:
            field.category_items?.find(({ id }) => id === value)?.name || null,
        }
      : dataLineageValues

  const { clearDocumentChipField } = useDocumentChipsContext()

  const clearAllChips = async () => {
    setIsLoading(true)

    // useDocumentChips will batch these calls.
    chips.forEach((chip) =>
      clearDocumentChipField(chip).then(() => {
        setIsLoading(false)
      }),
    )
  }

  useHotkeys(
    'mod+delete',
    () => {
      clearAllChips()
    },
    {
      enabled:
        field?.project_grid_field_type.code !== 'category' &&
        field?.project_grid_field_type.code !== 'picker' &&
        field?.input_behavior !== 'manual_only' &&
        !!chips.length &&
        !isLoading,
      preventDefault: true,
      enableOnFormTags: true,
    },
    [
      chips,
      field?.project_grid_field_type.code,
      field?.input_behavior,
      isLoading,
    ],
  )

  return (
    <Drawer overlay={overlay} anchor="right" size="sm">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        minHeight={52}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Typography p={1} variant="h6" component="h2">
          <b>Field Details</b>
        </Typography>
        <IconButton
          onClick={() => overlay.close()}
          sx={{ height: 'fit-content' }}
        >
          <Close />
        </IconButton>
      </Stack>

      <Divider />
      {selectedField ? (
        <>
          <Stack
            flexGrow={1}
            spacing={1.5}
            py={1.5}
            px={1}
            onMouseDown={(e) => e.preventDefault()}
          >
            {field?.project_grid_field_type.code !== 'category' &&
              field?.project_grid_field_type.code !== 'picker' &&
              field?.input_behavior !== 'manual_only' && (
                <Tooltip
                  title={`${modHotKeyStr} + Del`}
                  enterDelay={1000}
                  arrow
                >
                  {/* Add span to prevent errors regarding tooltip in disabled buttons */}
                  <span>
                    <LoadingButton
                      disabled={!chips.length && !isLoading}
                      loading={isLoading}
                      loadingPosition="start"
                      onClick={clearAllChips}
                      size="small"
                      startIcon={<Close />}
                      sx={{ width: 'fit-content' }}
                      variant="text"
                    >
                      <span>Clear All Chips</span>
                    </LoadingButton>
                  </span>
                </Tooltip>
              )}
            {Object.entries(dataValues).map(([key, value]) => {
              if (
                (field?.input_behavior === 'manual_only' &&
                  (key === 'original_value' || key === 'rule_value')) ||
                (field?.input_behavior === 'manual_not_allowed' &&
                  key === 'manual_value')
              )
                return
              let fieldValue: ReactNode = value as string
              if (field?.project_grid_field_type.code === 'checkbox') {
                if (value === 'TrueBox' || value === 'True') {
                  fieldValue = (
                    <CheckBoxOutlined
                      fontSize="small"
                      sx={{ mt: '2px', ml: '-2px' }}
                    />
                  )
                } else if (value === 'FalseBox' || value === 'False') {
                  fieldValue = (
                    <CheckBoxOutlineBlankOutlined
                      fontSize="small"
                      sx={{ mt: '2px', ml: '-2px' }}
                    />
                  )
                }
              }
              return (
                <FieldInfoLabeledValue
                  key={key}
                  label={dataLineage[key as keyof DataLineageValues | 'value']}
                  value={fieldValue}
                >
                  {isSuperUser &&
                    !demoMode &&
                    metadata?.confidence &&
                    key === 'original_value' && (
                      <Typography
                        color={SUPER_USER_ONLY_COLOR}
                        variant="caption"
                      >
                        Predicted Confidence:{' '}
                        {Math.floor(metadata.confidence * 100)}%
                      </Typography>
                    )}
                  {metadata?.weighted_confidence &&
                    key === 'original_value' && (
                      <Typography color="text.secondary" variant="caption">
                        Confidence:{' '}
                        {Math.floor(metadata.weighted_confidence * 100)}%
                      </Typography>
                    )}
                </FieldInfoLabeledValue>
              )
            })}
          </Stack>
          {jsonValue && (
            <>
              <Divider variant="fullWidth" />
              <AddressData jsonValue={jsonValue} />
            </>
          )}
          {field?.project_grid_field_type.code === 'picker' && refId && (
            <>
              <Divider variant="fullWidth" />
              <ListEntryData field={field} entryId={refId} />
            </>
          )}
        </>
      ) : (
        <Typography sx={{ py: 1.5, px: 1 }}>No field selected.</Typography>
      )}
    </Drawer>
  )
}
