import { Link, LinkProps } from 'react-router-dom'
import { BarChart } from '@mui/icons-material'
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material'
import useOverlay from '@/hooks/useOverlay'

type MetricsMenuProps = {
  IconButtonProps?: IconButtonProps
  LinkProps?: Omit<LinkProps, 'to'>
}

export default function MetricsMenu({
  IconButtonProps,
  LinkProps,
}: MetricsMenuProps) {
  const overlay = useOverlay()

  return (
    <div>
      <Tooltip title="Metrics">
        <IconButton
          {...IconButtonProps}
          onClick={(e) => {
            IconButtonProps?.onClick?.(e)
            overlay.toggle(e)
          }}
          size="small"
        >
          <BarChart />
        </IconButton>
      </Tooltip>
      <Menu
        id="metrics-menu"
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
      >
        <MenuItem
          {...LinkProps}
          component={Link}
          to="/metrics/documents"
          onClick={(e) => {
            LinkProps?.onClick?.(e)
            overlay.close()
          }}
        >
          Documents
        </MenuItem>
        <MenuItem
          {...LinkProps}
          component={Link}
          to="/metrics/document-validation"
          onClick={(e) => {
            LinkProps?.onClick?.(e)
            overlay.close()
          }}
        >
          Document Validation
        </MenuItem>
        <MenuItem
          {...LinkProps}
          component={Link}
          to="/metrics/model-versions"
          onClick={(e) => {
            LinkProps?.onClick?.(e)
            overlay.close()
          }}
        >
          Model Versions
        </MenuItem>
      </Menu>
    </div>
  )
}
