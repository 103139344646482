import { useState } from 'react'
import { Card, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { ProjectDocumentRule } from '@/types/rules'
import { useUpdateProjectDocumentRule } from '@/service-library/hooks/project-document-rules'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import RuleSettings, { FormValues } from '@/components/rules/RuleSettings'
import DocumentRules from './DocumentRules'

export default function DocumentRulesPage() {
  const [selectedRule, setSelectedRule] = useState<ProjectDocumentRule | null>(
    null,
  )

  const { updateProjectDocumentRule, isLoading } = useUpdateProjectDocumentRule(
    {
      sideEffectQueryKeys: [queryKeys.projectDocumentRules.details()],
      onSuccess: () => {
        showSuccessSnackbar('Document Rule Updated')
      },
      onError: () => {
        showErrorSnackbar('Document Rule Update Failed')
      },
    },
  )

  const updateDocumentRule = async ({
    workflow_states_ids,
    ...rest
  }: FormValues) => {
    if (selectedRule) {
      const cleanedParams: ProjectDocumentRule['params'] = rest || {}
      delete cleanedParams.dependent_fields
      delete cleanedParams.is_superuser_rule

      return updateProjectDocumentRule({
        ...selectedRule,
        workflow_states_ids,
        params: rest,
      })
    }
    return
  }

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Typography variant="h5" color="textPrimary">
        Document Rules
      </Typography>
      <Card sx={{ p: 2, height: '100%', flexGrow: 1 }}>
        <Grid2 container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid2 xs={6}>
            <DocumentRules
              selectedRule={selectedRule}
              setSelectedRule={setSelectedRule}
            />
          </Grid2>
          {selectedRule && (
            <Grid2 xs={6}>
              <RuleSettings
                key={selectedRule.id} // Completely rerender each time we change the selected rule
                isLoading={isLoading}
                onSubmit={updateDocumentRule}
                rule={selectedRule}
              />
            </Grid2>
          )}
        </Grid2>
      </Card>
    </Stack>
  )
}
