import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { WarningOutlined } from '@mui/icons-material'
import { Stack, Button, Typography } from '@mui/material'
import { ProjectGridField } from '@/types/fields'
import { ProjectDocumentRule, ProjectGridFieldRule } from '@/types/rules'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { getDefaultValue, RuleProperty } from '@/utils/rules'
import { PixyDocsForm } from '@/components/forms'
import CommonRuleConfiguration from './CommonRuleConfiguration'
import RuleCodeEditor from './RuleCodeEditor'

export type FormValues = {
  workflow_states_ids: string[]
  dependent_fields: Pick<ProjectGridField, 'id'>[]
  is_superuser_rule: boolean
} & ProjectGridFieldRule['params'] // params type is the same for both document and rule types

export type RuleSettingsProps<
  T extends ProjectGridFieldRule | ProjectDocumentRule,
> = {
  isLoading: boolean
  rule: T
  onSubmit: (values: FormValues) => Promise<T | void>
  fields?: ProjectGridField[] // Only used for field rules
  fieldId?: string // Only used for field rules
}

// To make TS happy
function isProjectGridFieldRule(
  rule: ProjectGridFieldRule | ProjectDocumentRule,
): rule is ProjectGridFieldRule {
  return 'rule' in rule
}

function getPropertyFormValues({
  rule,
  fields,
}: {
  rule: ProjectGridFieldRule | ProjectDocumentRule
  fields?: ProjectGridField[]
}) {
  const { params, workflow_states_ids = [] } = rule
  const isFieldRule = isProjectGridFieldRule(rule)

  const type = isFieldRule ? rule.rule : rule.type
  const { params_schema } = type

  // Sort the properties by sort_order
  const properties = Object.entries(
    (params_schema.properties as RuleProperty) || [],
  ).sort((p1, p2) => p1[1].sort_order - p2[1].sort_order)

  // We use 'params' for the default values, but if any are missing we need to give
  // them a default of an empty string to prevent uncontrolled-to-controlled errors.
  const defaultValues = properties.reduce((acc, [propertyId, property]) => {
    if (property.hidden) return acc
    return {
      ...acc,
      [propertyId]: getDefaultValue(property) ?? '',
    }
  }, {})

  // In the case a schema has had a field removed, but it was previously put on the rule,
  // we need to remove it. If we include it in the defaultValues, then it gets sent to
  // the backend in the update call.
  const scrubbedParams = Object.fromEntries(
    Object.entries(params).filter(([paramId]) => {
      const propertyObj = params_schema.properties?.[paramId]
      return propertyObj && !propertyObj.hidden
    }),
  )

  return {
    defaultValues: {
      ...defaultValues,
      ...scrubbedParams,
      workflow_states_ids,
      dependent_fields: isFieldRule
        ? fields?.filter(({ id }) => rule.dependent_fields_ids?.includes(id)) ||
          []
        : [],
      is_superuser_rule: isFieldRule ? rule.is_superuser_rule : false,
    },
    properties,
  }
}

export default function RuleSettings<
  T extends ProjectGridFieldRule | ProjectDocumentRule,
>({ isLoading, rule, onSubmit, fields, fieldId }: RuleSettingsProps<T>) {
  const featureFlags = useFeatureFlagContext()

  const { defaultValues, properties } = useMemo(
    () => getPropertyFormValues({ rule, fields }),
    [rule, fields],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid, dirtyFields },
    watch,
  } = methods

  const pythonScript = watch('script')

  function handleSubmit(values: FormValues) {
    onSubmit(values).then(() => {
      // Clear dirty state, but keep current values
      methods.reset(methods.getValues(), { keepValues: true })
    })
  }

  const isFieldRule = isProjectGridFieldRule(rule)
  if (
    isFieldRule &&
    !featureFlags.rules_in_workflow_states &&
    properties.length === 0
  ) {
    return (
      <Typography variant="body2" color="text.secondary">
        This rule doesn&apos;t require configuration.
      </Typography>
    )
  }

  const type = isFieldRule ? rule.rule : rule.type
  const maxHeight = `calc(100vh - ${isFieldRule ? 340 : 300}px)`

  return (
    <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
      <Stack direction="row" spacing={1.5} sx={{ pl: 2 }}>
        <Typography variant="h6">{type.name}</Typography>
        {isProjectGridFieldRule(rule) && rule.rule.code === 'run_py' && (
          <RuleCodeEditor
            isOnlyEditableBySuperUser={rule.is_superuser_rule}
            script={pythonScript}
            onSave={(value) => {
              methods.setValue('script', value, {
                shouldDirty: true,
              })
            }}
            color={dirtyFields.script ? 'secondary' : 'primary'}
          />
        )}
      </Stack>
      <Stack spacing={2} sx={{ mt: 1 }}>
        <Stack spacing={2} sx={{ pl: 2, maxHeight, overflow: 'auto' }}>
          <CommonRuleConfiguration
            fieldId={fieldId}
            propertiesToRender={properties}
            ruleType={type}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="center"
        >
          {isDirty && (
            <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
              <WarningOutlined fontSize="small" color="secondary" />
              <Typography color="secondary" variant="body2">
                <i>Unsaved Changes</i>
              </Typography>
            </Stack>
          )}
          <Button
            variant="text"
            disabled={!isDirty}
            onClick={() => methods.reset(defaultValues)}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={!isDirty || !isValid}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </PixyDocsForm>
  )
}
